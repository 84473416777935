import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { TourPlannerConfiguration } from '../../dtos/tour-planner-configuration';
import { TourPlannerDailyPlan } from '../../dtos/tour-planner-daily-plan';

export const TOURPLANNER_SERVICE_IMPL = new InjectionToken<ITourPlannerService>(
    'tourPlannerServiceImpl'
);

export interface ITourPlannerService {
    getSavedDailyPlan(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<TourPlannerDailyPlan>;

    corrigateSavedDailyPlan(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<TourPlannerDailyPlan>;

    planDailyPlan(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<TourPlannerDailyPlan>;

    acceptSavedDailyPlan(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<any>;

    saveSupplierRegionTourPlannerParameters(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<any>;

    getSupplierRegionTourPlannerParameters(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<TourPlannerConfiguration>;
}
