import { Component, Inject, Input, OnInit } from '@angular/core';
import { DeliveryTaskState } from 'shared';
import { ITasksService, TASKS_SERVICE_IMPL } from 'shared';
import { RefundingTaskListItem } from 'shared';
import { IOrderReturnService, ORDER_RETURN_SERVICE_IMPL } from 'shared';
import { MyTasksService } from 'dm-src/app/modules/my-tasks/my-tasks.service';
import { FlashMessage } from 'dm-src/dtos/flash-message';
import { ColorClass } from 'shared';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-refunding-task-button',
    templateUrl: './refunding-task-button.component.html',
    styleUrls: ['./refunding-task-button.component.scss'],
})
export class RefundingTaskButtonComponent implements OnInit {
    @Input() task: RefundingTaskListItem;
    public buttonClasses = 'btn btn-sm ';
    public buttonText: string;
    public isWaitingForAccept: boolean;
    public isLoading = false;

    constructor(
        @Inject(TASKS_SERVICE_IMPL) private _tasksService: ITasksService,
        @Inject(ORDER_RETURN_SERVICE_IMPL)
        private _orderReturnService: IOrderReturnService,
        private _myTasksService: MyTasksService
    ) {
        this.buttonClasses = 'btn btn btn-block btn-info';
        this.isWaitingForAccept = false;
    }

    ngOnInit() {
        this.isWaitingForAccept =
            this.task.state === DeliveryTaskState[DeliveryTaskState.WaitingForAccept];
    }

    public startRefunding() {
        this.isLoading = true;
        this._orderReturnService
            .refundOrderItems(this.task.orderID, this.task.deliveryTaskID)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (returnResult) => {
                    if (returnResult.status === 200) {
                        this._myTasksService.removeTask(this.task.deliveryTaskID);

                        const message = new FlashMessage();

                        message.type = ColorClass.Success;
                        message.title = 'Success';
                        message.message = 'Refunding task finished successfully';

                        this._myTasksService.showFlashMessage(message);
                    }
                },
                (err) => {
                    const message = new FlashMessage();
                    message.type = ColorClass.Danger;
                    message.title = 'Error';
                    message.message = err.error.detail;
                    this._myTasksService.showFlashMessage(message);
                }
            );
    }

    public acceptAssignment() {
        this._tasksService
            .acceptCollectingTask(this.task.deliveryTaskID)
            .subscribe((response) => {
                if (response.status === 200) {
                    this.isWaitingForAccept = false;
                    this.task.state = DeliveryTaskState[DeliveryTaskState.ToDo];
                }
            });
    }
}
