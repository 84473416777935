import { User } from 'shared';
import { PaymentState } from 'shared';

export class Order {
    public orderID: number;
    public orderCode: string;
    public isSuperOrder: boolean;
    public orderState: string;
    public providerName: string;
    public user: User;
    public modified?: Date;
    public created?: Date;
    public totalPrice: number;
    public deliveryDate: Date;
    public deliveryTimeSlotEnd: number;
    public deliveryFee: number;
    public deliveryAddress: string;
    public deliveryTypeName: string;
    public superOrderCode: string;
    public comment: string;
    public ordererName: string;
    public billPhotoFilename: string;
    public paymentState: PaymentState;
    public estimatedDeliveryEnd?: Date;
    public estimatedPickingEnd?: Date;
    public estimatedPickingStart?: Date;
    public realDeliveryEnd?: Date;
    public realPickingEnd?: Date;
    public realPickingStart?: Date;
    public deliveryDay: string;
    public deliveryTimeSlot: string;
}
