import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAccountComponent } from './my-account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbAlertModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountBasicDataComponent } from './account-basic-data/account-basic-data.component';
import { AccountSupplierDataComponent } from './account-supplier-data/account-supplier-data.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { SupplierAddressApprovalModalComponent } from './account-supplier-data/supplier-address-approval-modal/supplier-address-approval-modal.component';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { BtnProgressModule, SharedModule } from 'shared';
import { SupplierRegionChooserModule } from 'dm-src/app/modules/common/supplier-region-chooser/supplier-region-chooser.module';
import { ModalModule } from 'shared';
import { ProviderShopChooserModule } from '../common/providershop-chooser/providershop-chooser.module';
import { PROVIDERSHOP_SERVICE_IMPL } from '../../../services/providershop/iprovidershop-service';
import { ProviderShopService } from '../../../services/providershop/providershop-service';

@NgModule({
    declarations: [
        MyAccountComponent,
        AccountBasicDataComponent,
        AccountSupplierDataComponent,
        SupplierAddressApprovalModalComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        NgxSelectModule,
        NgbAlertModule,
        AppSharedModule,
        BtnProgressModule,
        SupplierRegionChooserModule,
        ProviderShopChooserModule,
        ModalModule,
        NgbNavModule,
        SharedModule,
    ],
})
export class MyAccountModule {}
