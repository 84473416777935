<mat-form-field>
    <mat-select
        #columnSelect
        class="visible-column-dropdown"
        [formControl]="columnsControl"
        placeholder="{{ 'lists.visible-columns' | translate }}"
        [multiple]="true"
        (selectionChange)="setColumnVisibility()"
        [(ngModel)]="selectedColumns"
        #multiSelect
    >
        <mat-option [value]="0" (click)="toggleAllSelection(columnSelect)"
            >All</mat-option
        >
        <mat-option *ngFor="let column of availableColumns" [value]="column">
            {{ column.label }}
        </mat-option>
    </mat-select>
</mat-form-field>
