import { Component, Input, OnInit } from '@angular/core';
import { DeliveryTaskInfo } from 'shared';
import { DeliveryTaskType } from 'shared';
import { DeliveryTaskState } from 'shared';

@Component({
    selector: 'app-task-details-item',
    templateUrl: './task-details-item.component.html',
    styleUrls: ['./task-details-item.component.scss'],
})
export class TaskDetailsItemComponent implements OnInit {
    @Input() public taskInfo: DeliveryTaskInfo;

    constructor() {}

    ngOnInit(): void {}

    public getTaskLabel(): string {
        const taskState =
            this.taskInfo.taskState > 0
                ? DeliveryTaskState[this.taskInfo.taskState]
                : 'Not Assigned';
        return DeliveryTaskType[this.taskInfo.taskType] + ' ' + taskState;
    }

    public getItemClass(): string {
        let cls = 'card';
        switch (this.taskInfo.taskType) {
            case DeliveryTaskType.Collecting:
                cls += ' text-white bg-info';
                break;
            case DeliveryTaskType.Delivering:
                cls += ' bg-warning';
                break;
            case DeliveryTaskType.Invoicing:
                cls += ' bg-success';
                break;
        }
        return cls;
    }
}
