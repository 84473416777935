import { InjectionToken } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OfflineProduct } from '../../models/offline-product';
export const OFFLINEPRODUCT_SERVICE_IMPL = new InjectionToken<IOfflineProductService>(
    'offlineProductServiceImpl'
);

export interface IOfflineProductService {
    getByGTIN(
        providerID: number,
        gtins: string
    ): Observable<HttpResponse<OfflineProduct>>;
    getBySKU(providerID: number, SKU: string): Observable<HttpResponse<OfflineProduct[]>>;
}
