export enum EventType {
    // Fontos, hogy itt a sorrend megegyezzen a kliens oldali EventType enummal.

    OrderStateChanged,
    OrderItemStateChanged,
    SupplierRegionChanged,
    UserRolesChanged,
    AccountsChanged,
    ClientChanged,
    SupplierDataChanged,
    SupplierWorktimeChanged,
    SupplierResourceChanged,
}
