import { Component, Inject, Input } from '@angular/core';
import { NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessageService } from './flash-message.service';

@Component({
    selector: 'lib-flash-message',
    templateUrl: './flash-message.component.html',
    styleUrls: ['./flash-message.component.css'],
    providers: [NgbAlertConfig],
})
export class FlashMessageComponent {
    @Input() public id = 'main';

    constructor(
        public flashMessageService: FlashMessageService,
        public alertConfig: NgbAlertConfig
    ) {}
}
