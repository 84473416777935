import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../services/authentication/iauthentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this._authService.currentUser) {
            return true;
        }

        this._router.navigate(['/login'], {
            queryParams: { returnUrl: state.url },
        });
        return false;
    }
}
