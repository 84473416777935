import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlashMessageComponent } from './flash-message.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [FlashMessageComponent],
    imports: [CommonModule, NgbAlertModule],
    exports: [FlashMessageComponent],
})
export class FlashMessageModule {}
