export enum Application {
    /// <summary>
    /// Roksh
    /// </summary>
    Roksh = 0,
    /// <summary>
    /// Delivery-manager
    /// </summary>
    DM = 1,
    /// <summary>
    /// Provider-manager
    /// </summary>
    PM = 2,
    /// <summary>
    /// Shopservice
    /// </summary>
    SS = 3,
    /// <summary>
    /// Roksh-backend
    /// </summary>
    RB = 4,
    /// <summary>
    /// Shared
    /// </summary>
    Shared = 5,
    /// <summary>
    /// Resource Manager
    /// </summary>
    RM = 6,
}

export const ApplicationLabelMapping = {
    [Application.Roksh]: 'Roksh fontend',
    [Application.DM]: 'Delivery manager',
    [Application.PM]: 'Provider manager',
    [Application.SS]: 'Shop service',
    [Application.RB]: 'Roksh backend',
    [Application.Shared]: 'Shared',
    [Application.RM]: 'Resource manager',
};
