<h1>Registration Confirmation</h1>
<ng-template #loading>
    <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</ng-template>
<div *ngIf="isConfirmed || errorMessage; else loading">
    <div class="alert alert-danger w-50" role="alert" *ngIf="errorMessage">
        <strong>Error: </strong>{{ errorMessage }}
    </div>
    <div class="alert alert-success w-50 text-center" role="alert" *ngIf="isConfirmed">
        <h3>Your user registration has been confirmed successfully.</h3>
        <a class="btn btn-primary" [routerLink]="['/login']"> Click here to login! </a>
    </div>
</div>
