<app-native-barcode-reader-input></app-native-barcode-reader-input>
<app-collect-approval-modal></app-collect-approval-modal>
<app-replacement-editor-modal></app-replacement-editor-modal>
<app-task-failed-modal (onTaskFailSet)="onTaskFailSet($event)"></app-task-failed-modal>
<app-bill-approval-modal></app-bill-approval-modal>
<app-create-item-modal></app-create-item-modal>
<app-collecting-done-failed-modal></app-collecting-done-failed-modal>
<lib-modal
    [title]="'collecting.too-many-virtual-payment-request-title' | translate"
    [componentID]="'too-many-virtual-payment-request'"
>
    <lib-progress-line [id]="'too-many-virtual-payment-progress'"></lib-progress-line>
    <p class="mt-2">
        {{ 'collecting.too-many-virtual-payment-request-text' | translate }}
    </p>
</lib-modal>
<lib-modal
    [title]="'collecting.payment-in-progress-title' | translate"
    [componentID]="'payment-in-progress'"
>
    <p>{{ 'collecting.payment-in-progress-text' | translate }}</p>
    <button
        (click)="modalService.setModalVisibility(false, 'payment-in-progress')"
        class="btn btn-block btn-warning"
    >
        OK
    </button>
</lib-modal>
<app-checkout-warning-modal
    [modalName]="'virtual-payment-suspended'"
    [supportCallButton]="true"
>
</app-checkout-warning-modal>
<app-checkout-warning-modal
    [modalName]="'virtual-payment-reservation-failed'"
    [supportCallButton]="true"
>
</app-checkout-warning-modal>
<app-checkout-warning-modal
    [modalName]="'invalid-total-price'"
    [supportCallButton]="true"
    [translateArgs]="{
        totalPrice: collectingItemsService.getCollectedTotalPrice()
    }"
>
</app-checkout-warning-modal>
<app-checkout-warning-modal
    [modalName]="'virtual-checkout-payment-failed'"
    [supportCallButton]="true"
>
</app-checkout-warning-modal>
<app-checkout-warning-modal
    [modalName]="'virtual-checkout-payment-is-unavailable'"
    [supportCallButton]="true"
>
</app-checkout-warning-modal>
<app-checkout-warning-modal
    [modalName]="'supplier-not-exists'"
    [routerLink]="'/my-account'"
>
</app-checkout-warning-modal>
<app-checkout-warning-modal
    [modalName]="'payment-is-already-finished'"
    [routerLink]="'/my-tasks'"
>
</app-checkout-warning-modal>
<app-checkout-warning-modal
    [modalName]="'payment-finalization-failed'"
    [supportCallButton]="true"
    [routerLink]="'/my-tasks'"
>
</app-checkout-warning-modal>
<app-checkout-warning-modal [modalName]="'payment-is-succeeded-no-go-back'">
</app-checkout-warning-modal>
<lib-modal
    [title]="'collecting.order-item-image' | translate"
    [componentID]="'order-item-image'"
    [bgColorClass]="ColorClass.Info"
>
    <h5 class="text-center">
        {{ collectingItemsService.shownOrderItemImage.orderItemName }}
    </h5>
    <img
        class="img-fluid mt-5"
        [src]="collectingItemsService.shownOrderItemImage.url"
        alt="collectingItemsService.shownOrderItemImage.orderItemName"
    />
</lib-modal>
<ng-container *ngIf="states.isBarcodeReadingEnabled">
    <app-product-identify-modal></app-product-identify-modal>
    <app-invalid-offline-product-modal></app-invalid-offline-product-modal>
    <app-unidentified-item-modal></app-unidentified-item-modal>
    <app-manual-sku-check-modal></app-manual-sku-check-modal>
    <!--old web browser barcode reader modal-->
    <app-barcode-reader-modal
        (onBarcodeRead)="offlineProductsService.processGtin($event)"
    ></app-barcode-reader-modal>
    <app-checkout-warning-modal
        [modalName]="'markup-payment-failed'"
        [supportCallButton]="true"
    >
    </app-checkout-warning-modal>
    <app-checkout-warning-modal
        [modalName]="'invalid-markup-total-price'"
        [supportCallButton]="true"
    >
    </app-checkout-warning-modal>

    <app-found-product-chooser-modal></app-found-product-chooser-modal>
</ng-container>
<app-checkout-qr-modal
    [isInspectingInProgress]="states.isInspectingInProgress"
></app-checkout-qr-modal>
