import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OrdersService } from 'dm-src/app/modules/orders/orders.service';
import { Order } from '../../../../models/order';
import { ReplaySubject } from 'rxjs';
import { environment } from 'dm-src/environments/environment';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-order-delivery-data',
    templateUrl: './order-delivery-data.component.html',
    styleUrls: ['./order-delivery-data.component.scss'],
})
export class OrderDeliveryDataComponent implements OnInit, OnDestroy {
    public selectedOrder: Order;
    public timeSlotList: any[]; // TODO TimeSlotDto
    public estimatedDeliveryEndEditable: Date;
    public deliveryDateFormat: string = environment.deliveryDateFormat;
    private _destroy$ = new ReplaySubject<boolean>();

    @Input() public orderCode: string;
    loading!: boolean;

    constructor(private _ordersService: OrdersService) {}

    ngOnInit(): void {
        this._ordersService.selectedOrder.pipe(take(1)).subscribe((selectedOrder) => {
            this.selectedOrder = selectedOrder;
            this.estimatedDeliveryEndEditable = this.selectedOrder.estimatedDeliveryEnd;
        });
    }

    public changeEstimatedDeliveryEnd(): boolean {
        if (
            !confirm(
                'Are you sure, you want to change the Estimated Delivery End, my friend?'
            )
        ) {
            return false;
        }

        const d = new Date(this.estimatedDeliveryEndEditable);

        this._ordersService.changeEstimatedDeliveryEnd(d).subscribe((response) => {
            const orderID = response.body.orderID;
            this._ordersService.selectOrder(this.selectedOrder.orderID, true);
            this.timeSlotList = null;
        });
    }

    public changeTimeslot(): void {
        this._ordersService.getAvailableTimeSlots().subscribe((response) => {
            if (response.status === 200) {
                this.timeSlotList = response.body;
                this.timeSlotList.forEach((timeSlot) => {
                    timeSlot.date = timeSlot.date.replace('T', ' ');
                });
            }
        });
    }

    selectThisTimeslot(timeSlot): boolean {
        if (!confirm('Are you sure, you want to change the timeslot, my friend?')) {
            return false;
        }
        this.loading = true;
        const d = new Date(timeSlot.date);
        this._ordersService.changeTimeSlotForDelivery(d).subscribe(
            (response) => {
                const orderID = response.body.orderID;

                this._ordersService.selectOrder(orderID, true);
                this.timeSlotList = null;

                this.selectedOrder.deliveryDay = response.body.deliveryDay;
                this.selectedOrder.deliveryTimeSlot = response.body.deliveryTimeSlot;
            },
            null,
            () => {
                this.loading = false;
            }
        );
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.complete();
    }
}
