import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DeliveryTaskState } from 'shared';
import { TasksService } from 'dm-src/app/modules/tasks/tasks.service';
import { ReplaySubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Modal } from 'dm-src/types/modal';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-task-state-modal',
    templateUrl: './task-state-modal.component.html',
    styleUrls: ['./task-state-modal.component.scss'],
})
export class TaskStateModalComponent implements OnInit, OnDestroy {
    private _destroy$: ReplaySubject<boolean>;
    private _selectedTaskID: string;
    @ViewChild('taskStateModal', { static: false })
    private _taskStateModal: TemplateRef<any>;
    public availableTaskStates: DeliveryTaskState[];
    public selectedTaskState: DeliveryTaskState;
    public DeliveryTaskState = DeliveryTaskState;

    constructor(
        private _tasksService: TasksService,
        private _modalService: NgbModal,
        private _translateService: TranslateService,
        private snackBar: MatSnackBar
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit(): void {
        this._tasksService.visibleModal
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((modal) => {
                if (modal === Modal.SetTaskState) {
                    const selectedTaskIDs =
                        this._tasksService.selectedTasksIDs.getValue();
                    if (selectedTaskIDs != null && selectedTaskIDs.length === 1) {
                        this._selectedTaskID = selectedTaskIDs[0];
                        const task = this._tasksService.getTask(this._selectedTaskID);

                        if (
                            DeliveryTaskState[task.state] === DeliveryTaskState.Canceled
                        ) {
                            this.snackBar.open(
                                this._translateService.instant('error.canceled-state'),
                                null,
                                {
                                    verticalPosition: 'bottom',
                                    horizontalPosition: 'center',
                                    duration: 4000,
                                    panelClass: ['error-snackbar'],
                                }
                            );
                        } else {
                            this.selectedTaskState = DeliveryTaskState[task.state];
                            this.setAvailableTaskStates();
                            this._modalService.open(this._taskStateModal, {
                                windowClass: 'modal-holder',
                                size: 'sm',
                                centered: true,
                            });
                        }
                    } else {
                        this._modalService.dismissAll();
                    }
                }
            });
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
    }

    public setState() {
        this._tasksService.setTaskState(this._selectedTaskID, this.selectedTaskState);
        this._modalService.dismissAll();
    }

    private setAvailableTaskStates() {
        this.availableTaskStates = [
            DeliveryTaskState.Done,
            DeliveryTaskState.Failed,
            DeliveryTaskState.InProgress,
            DeliveryTaskState.ToDo,
            DeliveryTaskState.NotAccepted,
        ];
    }
}
