<h1>Orders</h1>
<ng-template #loading>
    <app-preloader></app-preloader>
</ng-template>
<div class="table-responsive">
    <table
        class="table table-hover"
        *ngIf="providerOrdersService.orders | async; else loading"
    >
        <thead
            (onFilter)="listService.onColumnFilter($event)"
            (onSort)="listService.onColumnSort($event)"
            [columns]="[
                {
                    name: ['OrderCode'],
                    label: 'Order Code',
                    filterOperator: QueryOperator.Contains,
                    filterType: FilterType.Text,
                    isVisible: true,
                    width: 120
                },
                {
                    name: ['DeliveryDate'],
                    label: 'Delivery Date',
                    filterType: FilterType.DatePicker,
                    isVisible: true
                },
                {
                    name: ['Created'],
                    label: 'Order Date',
                    filterType: FilterType.DatePicker,
                    isVisible: true
                },
                {
                    name: ['ProviderShopID'],
                    label: 'Provider Shop',
                    filterType: FilterType.Select,
                    filterOptions: (providerOrdersService.filterOptions | async)
                        .providerShops,
                    isVisible: true
                },
                {
                    name: ['IsEmailsSent'],
                    label: 'Is Email Sent?',
                    filterType: FilterType.Boolean,
                    isVisible: true
                },
                {
                    name: ['OrderStateID'],
                    label: 'Order State',
                    filterOptions: orderStateOptions,
                    filterType: FilterType.Select,
                    isVisible: true
                },
                {
                    name: ['OrderPaymentList.StatusID'],
                    label: 'Payment State',
                    filterOptions: paymentStateOptions,
                    filterType: FilterType.Multiselect,
                    logicalOperator: LogicalOperator.Or,
                    isVisible: true
                }
            ]"
            class="thead-light"
            interactive-table-header
        ></thead>
        <tbody *ngIf="(providerOrdersService.orders | async).length > 0; else noresults">
            <tr *ngFor="let order of providerOrdersService.orders | async; index as i">
                <th scope="row">{{ listService.listIndexOffset + i + 1 }}</th>
                <td>
                    <span
                        class="badge badge-warning order-code"
                        (click)="openOrderDetailsModal(order)"
                        >{{ order.orderCode }}</span
                    >
                </td>
                <td class="text-nowrap">
                    {{ order.deliveryDate | date : dateFormat : '+2' : 'hu-HU' }}
                </td>
                <td class="text-nowrap">
                    {{ order.created | date : dateFormat : '+2' : 'hu-HU' }}
                </td>
                <td class="text-nowrap">{{ order.providerShop }}</td>
                <td class="text-center">
                    <lib-boolean-icon
                        [value]="order.isOrderConfirmationEmailSent"
                    ></lib-boolean-icon>
                </td>
                <td class="state-column">
                    <app-state-badge [state]="order.orderState"></app-state-badge>
                </td>
                <td>
                    <app-payment-state-badge
                        [state]="order.paymentState"
                    ></app-payment-state-badge>
                </td>
            </tr>
        </tbody>
        <ng-template #noresults>
            <tbody>
                <tr>
                    <td colspan="6">
                        No results found with the given filter parameters.
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </table>
</div>
<div class="d-flex justify-content-center">
    <lib-page-size-dropdown
        class="page-size-selector"
        (onPageSizeChanged)="listService.setPageSize($event)"
    ></lib-page-size-dropdown>
    <ngb-pagination
        [(page)]="listService.currentPaginatorPage"
        [pageSize]="(listService.listProperties | async).pageSize"
        [collectionSize]="(listService.listProperties | async).rowCount"
        [boundaryLinks]="true"
        [rotate]="true"
        [maxSize]="20"
        (pageChange)="listService.onPageChange()"
    >
    </ngb-pagination>
</div>
<app-provider-order-details-modal
    [order]="providerOrdersService.selectedOrder"
></app-provider-order-details-modal>
