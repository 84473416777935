import { OrderItem } from '../models/order-item';
import { OrderReplacementItem } from '../models/order-replacement-item';

export const prepareOrderItemList = (orderItems: OrderItem[]) =>
    orderItems.map((orderItem) => prepareOrderItem(orderItem));

export const prepareOrderItem = (orderItem: OrderItem) => {
    const realOrderItem = new OrderItem();
    for (const [key, value] of Object.entries(orderItem)) {
        realOrderItem[key] = value;
    }
    if (orderItem.orderReplacementItem !== null) {
        const realOrderReplacementItem = new OrderReplacementItem();
        for (const [key, value] of Object.entries(orderItem.orderReplacementItem)) {
            realOrderReplacementItem[key] = value;
        }
        realOrderReplacementItem.setImageSrcs();
        realOrderItem.orderReplacementItem = realOrderReplacementItem;
    }
    realOrderItem.setImageSrcs();
    return realOrderItem;
};
