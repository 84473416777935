import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BtnCloseComponent } from './btn-close.component';

@NgModule({
    declarations: [BtnCloseComponent],
    exports: [BtnCloseComponent],
    imports: [CommonModule, TranslateModule],
})
export class BtnCloseModule {}
