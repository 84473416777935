import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RoleChooserService } from './role-chooser.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from '../users.service';
import { User } from 'projects/shared/src/lib/models/user';

@Component({
    selector: 'app-role-chooser',
    templateUrl: './role-chooser.component.html',
    styleUrls: ['./role-chooser.component.scss'],
})
export class RoleChooserComponent implements OnInit, OnDestroy {
    @Input() public user: User;
    public userRoles: string[];
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private _roleChooserService: RoleChooserService,
        private _usersService: UsersService
    ) {}

    ngOnInit() {
        this._usersService.users.pipe(takeUntil(this.destroyed$)).subscribe((users) => {
            this.userRoles = users.find((user) => user.userID === this.user.userID).roles;
        });
    }

    public onClick(): void {
        this._roleChooserService.setUser(this.user);
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
