import {
    Component,
    OnInit,
    Inject,
    ViewChild,
    ElementRef,
    Input,
    OnDestroy,
} from '@angular/core';
import { CollectingStatesService } from '../collecting-states.service';
import {
    AppSettings,
    ColorClass,
    FlashMessageService,
    IOrdersService,
    IRealtimeClientService,
    ModalService,
    ORDERS_SERVICE_IMPL,
    REALTIME_CLIENT_IMPL,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    OrderStateEventLog,
    EventType,
    ActionType,
} from 'shared';
import { Subscription, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CollectingItemsService } from '../collecting-items.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { DecimalPipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-markup-collecting-buttons',
    templateUrl: './markup-collecting-buttons.component.html',
    styleUrls: ['./markup-collecting-buttons.component.scss'],
})
export class MarkupCollectingButtonsComponent implements OnInit, OnDestroy {
    timeLeft: number;
    private timerEndDate: Date;
    private interval: NodeJS.Timeout;
    formattedTimer: string;
    private translateCheckout: string;
    private translateError: string;
    private translateItemRejectedByUser: string;
    @ViewChild('wrapperHelper', { static: false }) wrapperHelper: ElementRef;
    public collectingHub = AppSettings.get('realtimeUrl') + '/collectinghub';
    private customerCalledSubscription = new Subscription();
    @Input() timeoutFinishedEvents: Observable<void>;
    private timeoutFinishedSubscription: Subscription;
    private currentOrderIdFromUrl = '';
    private currentTaskIdFromUrl = '';

    constructor(
        public states: CollectingStatesService,
        public collectingItemsService: CollectingItemsService,
        private _modalService: ModalService,
        @Inject(ORDERS_SERVICE_IMPL)
        private _ordersService: IOrdersService,
        @Inject(REALTIME_CLIENT_IMPL)
        private _realtimeClient: IRealtimeClientService,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService,
        private _translateService: TranslateService,
        private snackBar: MatSnackBar,
        private messageService: FlashMessageService,
        private iOsAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService,
        private numberPipe: DecimalPipe
    ) {
        var urlPathArr = location.href.split('/');
        this.currentOrderIdFromUrl = urlPathArr[urlPathArr.length - 1];
        this.currentTaskIdFromUrl = urlPathArr[urlPathArr.length - 2];
    }

    ngOnDestroy(): void {
        this.timeoutFinishedSubscription.unsubscribe();
    }

    ngOnInit(): void {
        if (this.states.onlineConsultation) {
            this.setTimeLeft();
            this.collectingItemsService.collectedItems.subscribe((items) => {
                this.states.allowCheckout =
                    items.filter(
                        (item) =>
                            item.orderReplacementItem != null &&
                            (item.orderReplacementItem?.quantityRequestedByUser == null ||
                                item.orderReplacementItem?.quantityRequestedByUser === 0)
                    ).length === 0;
            });
        } else {
            this.states.allowCheckout = true;
        }

        this._translateService.get('collecting.checkout').subscribe((res) => {
            this.translateCheckout = res;
        });

        this._translateService.get('common.error').subscribe((res) => {
            this.translateError = res;
        });

        this._translateService.get('error.rejected-items').subscribe((res) => {
            this.translateItemRejectedByUser = res;
        });

        this._realtimeClient.connect(this.collectingHub)?.then(() => {
            this._realtimeClient.on('timeoutfinished', this.collectingHub, (response) => {
                if (response[0] === this.states.orderID.toString()) {
                    this.timeLeft = 0;
                }
            });
        });

        this.timeoutFinishedSubscription = this.timeoutFinishedEvents.subscribe(
            () => (this.timeLeft = 0)
        );
    }

    get checkPreferenceSettingsStates(): boolean {
        if (!this.states.existReplacementProvision) {
            return true;
        } else if (this.states.existPreferenceSettings) {
            if (
                this.states.customerCalled ||
                this.states.sendApprovalRequest ||
                this.states.acceptsEverything ||
                this.states.noAskForReplacementItems
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    get displayButton(): string {
        if (this.timeLeft > 0) {
            return this.formattedTimer;
        }

        return this.translateCheckout;
    }

    transformToMMSSFormat(value: number): string {
        const minutes: number = Math.floor(value / 60);
        const seconds = value - minutes * 60;

        const formattedMinutes = this.numberPipe.transform(minutes, '2.0');
        const formattedSeconds = this.numberPipe.transform(seconds, '2.0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    setTimeLeft(): void {
        this._ordersService
            .getCountNotHandledReplacement(this.states.orderID)
            .subscribe((res) => {
                if (res.body != null) {
                    this.updateTimerState().subscribe();
                }
            });
    }

    updateTimerState(): Observable<HttpResponse<Date>> {
        return this._ordersService.getTimeLeftToModifyOrder(this.states.orderID).pipe(
            map((endDate) => {
                if (endDate.body) {
                    this.timerEndDate = new Date(endDate.body);
                }

                if (this.timeLeft === undefined && this.timerEndDate) {
                    this.timeLeft = Math.round(
                        (this.timerEndDate?.valueOf() - Date.now()) / 1000
                    );
                }

                if (this.timerEndDate > new Date()) {
                    this.startTimer();
                } else {
                    this.timeOutFinished();
                }
                return endDate;
            })
        );
    }

    startTimer() {
        this.interval ??= setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft = Math.round(
                    (this.timerEndDate?.valueOf() - Date.now()) / 1000
                );
                this.formattedTimer = this.transformToMMSSFormat(this.timeLeft);
            } else {
                clearInterval(this.interval);
                this.timeLeft = 0;
                this.timeOutFinished();
            }
        }, 1000);
    }

    timeOutFinished(): void {
        // set old things unapproved + realtime service call
        this._ordersService.timeOutFinished(this.states.orderID).subscribe({
            complete: () => {
                this.collectingItemsService.timeoutFinished();
                var urlPathArr = location.href.split('/');
                var timerEndUrlOrderID = urlPathArr[urlPathArr.length - 1];

                if (timerEndUrlOrderID === this.currentOrderIdFromUrl) {
                    this.collectingItemsService.getOrderItems(
                        this.currentTaskIdFromUrl,
                        Number(this.currentOrderIdFromUrl)
                    );
                }
            },
        });
    }

    public showQRModal(): void {
        this._ordersService
            .getCountNotHandledReplacement(this.states.orderID)
            .subscribe((res) => {
                if (res.body != null) {
                    if (res.body == 0) {
                        if (this.states.onlineConsultation) {
                            this._ordersService
                                .collectFinished(this.states.orderID)
                                .subscribe((res) => {
                                    this.updateTimerState().subscribe(() => {
                                        if (this.timeLeft > 0) {
                                            this.collectingItemsService.requestApproveFromCustomer();
                                        }
                                        if (
                                            this.timeLeft <= 0 &&
                                            !this.collectingItemsService.existItemRejectedByUser() &&
                                            !this.states.isButtonClickForbidden
                                        ) {
                                            this.snackBar.open(
                                                this._translateService.instant(
                                                    'messages.ok-proceed-to-checkout'
                                                ),
                                                null,
                                                {
                                                    verticalPosition: 'top',
                                                    horizontalPosition: 'center',
                                                    duration: 4000,
                                                }
                                            );
                                            this.doShowQRModal();
                                        }
                                    });
                                });
                        } else {
                            this.doShowQRModal();
                        }
                    } else {
                        this._ordersService
                            .collectFinished(this.states.orderID)
                            .subscribe((res) => {
                                if (this.states.onlineConsultation) {
                                    this.updateTimerState().subscribe(() => {
                                        if (this.timeLeft > 0) {
                                            this.collectingItemsService.requestApproveFromCustomer();
                                        } else if (this.timeLeft <= 0) {
                                            if (
                                                this.collectingItemsService.existItemRejectedByUser()
                                            ) {
                                                this.messageService.showMessage(
                                                    this.translateError,
                                                    this.translateItemRejectedByUser,
                                                    10000,
                                                    ColorClass.Danger
                                                );
                                            } else {
                                                this.doShowQRModal();
                                            }
                                        }
                                    });
                                } else {
                                    this.doShowQRModal();
                                }
                            });
                    }
                }
            });
    }

    public doShowQRModal(): void {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.MarkupCheckoutButtonClicked;
        orderStateEventLog.fromStateJson = null;
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this.states.orderID;
        orderStateEventLog.orderCode = this.states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this._modalService.setModalVisibility(true, 'checkout-qr-modal');
    }

    public approvalRequest(): void {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.RequestApprovalFromTheCustomer;
        orderStateEventLog.fromStateJson = null;
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this.states.orderID;
        orderStateEventLog.orderCode = this.states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this.states.sendApprovalRequest = true;
        this.showQRModal();
    }

    public callDone(): void {
        this.customerCalledSubscription = this._ordersService
            .setCustomerCalled(this.states.orderID)
            .subscribe(
                (resp) => {
                    if (resp.status === 200) {
                        this.states.customerCalled = true;
                    }
                },
                (error) => {
                    console.error(error.message);
                },
                () => {
                    this.states.customerCalled = true;

                    this.wrapperHelper.nativeElement.click();
                    this.customerCalledSubscription.unsubscribe();
                }
            );
    }
}
