import { Component, OnInit } from '@angular/core';
import { ModalService } from 'shared';
import { CollectingStatesService } from '../../collecting-states.service';
import { ProductIdentificationMode } from '../../../../../types/product-identification-mode';

@Component({
    selector: 'app-unidentified-item-modal',
    templateUrl: './unidentified-item-modal.component.html',
    styleUrls: ['./unidentified-item-modal.component.scss'],
})
export class UnidentifiedItemModalComponent implements OnInit {
    constructor(
        private _modalService: ModalService,
        private _states: CollectingStatesService
    ) {}

    get canOpenManualSKUChecker(): boolean {
        return (
            this._states.productIdentificationMode ===
            ProductIdentificationMode.BarcodeRead
        );
    }

    ngOnInit(): void {}

    public showManualSKUCheckModal(): void {
        this.closeModal();
        this._modalService.setModalVisibility(true, 'manual-sku-check-modal');
    }

    public closeModal(): void {
        this._modalService.setModalVisibility(false, 'unidentified-item-modal');
    }
}
