<table class="custom-event-editor" width="100%" cellpadding="5">
    <tbody>
        <tr>
            <td class="e-textlabel">
                {{ 'supplier-resources.slot-from' | translate }}
            </td>
            <td colspan="4">
                <input id="StartTime" class="e-field" type="text" name="StartTime" />
            </td>
        </tr>
        <tr>
            <td class="e-textlabel">
                {{ 'supplier-resources.slot-to' | translate }}
            </td>
            <td colspan="4">
                <input id="EndTime" class="e-field" type="text" name="EndTime" />
            </td>
        </tr>
        <tr>
            <td class="e-textlabel">
                {{ 'supplier-resources.available-slots' | translate }}
            </td>
            <td colspan="4">
                <input
                    id="AvailableSlot"
                    class="e-field e-input"
                    type="number"
                    min="1"
                    step="1"
                    name="AvailableSlot"
                />
                <div
                    class="error"
                    *ngIf="supplierResourcesService.isAvailableSlotInvalid | async"
                >
                    {{ 'supplier-resources.invalid-available-slots' | translate }}
                </div>
            </td>
        </tr>
        <tr>
            <td class="e-textlabel">
                {{ 'supplier-resources.reserved-slots-full' | translate }}
            </td>
            <td colspan="4">
                <span id="ReservedSlot"></span>
                <input
                    id="ReservedSlotValue"
                    class="e-field e-input"
                    type="hidden"
                    name="ReservedSlot"
                />
            </td>
        </tr>
        <tr>
            <td class="e-textlabel" valign="top">
                {{ 'supplier-resources.slot-reserved-by' | translate }}
            </td>
            <td colspan="4">
                <ul>
                    <li *ngFor="let supplier of resourceSuppliers">
                        {{ supplier.lastName }} {{ supplier.firstName }}
                    </li>
                </ul>
            </td>
        </tr>
    </tbody>
</table>
