<tr>
    <th *ngIf="hasBatchColumn" scope="col">
        <div class="form-group form-check">
            <input
                type="checkbox"
                class="form-check-input"
                (change)="checkAll($event.srcElement)"
            />
        </div>
    </th>
    <th scope="col">#</th>
    <ng-container *ngFor="let column of columns; let i = index">
        <th
            class="text-nowrap"
            *ngIf="column.isVisible"
            [style.width]="column.width > 0 ? column.width + 'px' : 'auto'"
        >
            <span [ngClass]="getColumnClass(column.name)" (click)="sort(column.name)">
                {{ column.label | translate }}
            </span>
            <i
                class="fa fa-filter filter-icon"
                (click)="showColumnFilter(column.name)"
            ></i>
            <div
                *ngIf="column.name === filteredColumn"
                [ngSwitch]="column.filterType"
                [ngClass]="{
                    'active-filter': column.name === filteredColumn,
                    filter: true
                }"
            >
                <input
                    *ngSwitchCase="FilterType.Text"
                    (keyup)="filterText(column, $event)"
                    type="text"
                    [placeholder]="column.label"
                />
                <ngx-select
                    *ngSwitchCase="FilterType.Select"
                    [noAutoComplete]="false"
                    [allowClear]="true"
                    (remove)="filterClear(column)"
                    (select)="filterSelect(column, $event)"
                    [items]="column.filterOptions"
                >
                </ngx-select>
                <div *ngSwitchCase="FilterType.Multiselect">
                    <input
                        class="w-100 form-control"
                        (keyup)="filterColumns(column, $event, i)"
                        type="text"
                        [placeholder]="column.label"
                    />
                    <mat-form-field>
                        <mat-select
                            #paymentStateSelect
                            [formControl]="paymentStatesControl"
                            placeholder="{{ 'common.choose' | translate }}"
                            [multiple]="true"
                            (selectionChange)="filterMultiselect(column, $event)"
                            [(ngModel)]="column.model"
                            #multiSelect
                        >
                            <mat-option
                                [value]="0"
                                (click)="toggleAllSelection(paymentStateSelect)"
                                >All</mat-option
                            >
                            <mat-option
                                *ngFor="let state of column.filterOptions"
                                [value]="state"
                            >
                                {{ state.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <lib-date-picker
                    *ngSwitchCase="FilterType.DatePicker"
                    (select)="filterDateRange(column, $event)"
                >
                </lib-date-picker>
                <lib-boolean-filter
                    *ngSwitchCase="FilterType.Boolean"
                    (onFilterChanged)="filterBoolean(column, $event)"
                    (onFilterCleared)="filterClear(column)"
                >
                </lib-boolean-filter>
            </div>
        </th>
    </ng-container>
</tr>
