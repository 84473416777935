import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyWorktimesComponent } from './my-worktimes.component';
import { ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';
import { WorktimeEntryEditorComponent } from './worktime-entry-editor/worktime-entry-editor.component';
import { QuickInfoHeaderComponent } from 'dm-src/app/modules/my-worktimes/worktime-entry-quickinfo/quick-info-header/quick-info-header.component';
import { QuickInfoContentComponent } from 'dm-src/app/modules/my-worktimes/worktime-entry-quickinfo/quick-info-content/quick-info-content.component';
import { QuickInfoFooterComponent } from 'dm-src/app/modules/my-worktimes/worktime-entry-quickinfo/quick-info-footer/quick-info-footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSelectModule } from 'ngx-select-ex';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { MomentModule } from 'ngx-moment';
import { SharedModule } from 'shared';

@NgModule({
    declarations: [
        MyWorktimesComponent,
        WorktimeEntryEditorComponent,
        QuickInfoHeaderComponent,
        QuickInfoContentComponent,
        QuickInfoFooterComponent,
    ],
    imports: [
        CommonModule,
        ScheduleAllModule,
        TranslateModule,
        NgxSelectModule,
        AppSharedModule,
        MomentModule,
        SharedModule,
    ],
})
export class MyWorktimesModule {}
