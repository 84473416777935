import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ColorClass } from '../../types/color-class';

@Injectable({
    providedIn: 'root',
})
export class IonToasterService {
    constructor(
        private _toastController: ToastController,
        private _translateService: TranslateService
    ) {}

    public error(message: string, duration: number = 4000): void {
        this.showMessage(message, duration, ColorClass.Danger);
    }

    public success(message: string, duration: number = 3000): void {
        this.showMessage(message, duration, ColorClass.Success);
    }

    public custom(message: string, color: ColorClass, duration: number = 4000): void {
        this.showMessage(message, duration, color);
    }

    public translate(message: string): string {
        try {
            return this._translateService.instant(message) || message;
        } catch {
            return message;
        }
    }

    private showMessage(
        message: string,
        duration: number = 3000,
        color: ColorClass = ColorClass.Primary
    ): void {
        if (!message) return;

        this._toastController
            .create({
                message: this.translate(message),
                duration,
                color,
            })
            .then((x) => {
                x.present();
            });
    }
}
