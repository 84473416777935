import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
    ACCOUNTS_SERVICE_IMPL,
    IAccountsService,
} from '../../../services/accounts/iaccounts.service';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../../../services/authentication/iauthentication.service';
import { IonToasterService } from '../../../services/ionic/ion.toaster.service';
import { ColorClass } from '../../../types/color-class';
import { FlashMessageService } from '../../flash-message/flash-message.service';
import { ChangePasswordComponent } from './change-password.component';

@Component({
    selector: 'lib-change-password-ionic',
    templateUrl: './change-password.ionic.component.html',
    styleUrls: ['./change-password.ionic.component.scss'],
})
export class ChangePasswordIonicComponent extends ChangePasswordComponent {
    flashMessageService: FlashMessageService;

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) _accountsService: IAccountsService,
        @Inject(AUTH_SERVICE_IMPL) _authService: IAuthenticationService,
        _formBuilder: UntypedFormBuilder,
        _route: ActivatedRoute,
        _flashMessageService: FlashMessageService,
        private _toasterService: IonToasterService
    ) {
        super(_accountsService, _authService, _formBuilder, _route, _flashMessageService);

        this.flashMessageService = _flashMessageService;

        this.flashMessageService.type.subscribe((type) => {
            if (type == ColorClass.Danger) {
                this._toasterService.error(_flashMessageService.lastMessage());
            }
        });
    }
}
