import { Component } from '@angular/core';

@Component({
    selector: 'app-native-take-photo-result',
    templateUrl: './native-take-photo-result.component.html',
    styleUrls: ['./native-take-photo-result.component.css'],
})
export class NativeTakePhotoResultComponent {
    public base64ResultContent: string = '';
}
