import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TaskUser } from 'shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TasksService } from '../tasks.service';
import { ReplaySubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { Modal } from 'dm-src/types/modal';
import {
    ACCOUNTS_SERVICE_IMPL,
    AUTH_SERVICE_IMPL,
    IAccountsService,
    IAuthenticationService,
} from 'shared';
import { TaskCollectionValidation } from 'dm-src/types/task-collection-validation';
import { DeliveryTaskType } from 'shared';
import { UserRole } from 'shared';
import { ModalService } from 'shared';

@Component({
    selector: 'app-user-modal',
    templateUrl: './user-modal.component.html',
    styleUrls: ['./user-modal.component.scss'],
})
export class UserModalComponent implements OnInit, OnDestroy {
    private destroyed$: ReplaySubject<boolean>;
    @ViewChild('userChooserModal', { static: false })
    private _userChooserModal: TemplateRef<any>;
    public invalidUserData: TaskUser;
    public validationErrors: TaskCollectionValidation;
    public UserRole = UserRole;
    public DeliveryTaskType = DeliveryTaskType;
    public isUserAssignedToASelectedTask: boolean;

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountsService: IAccountsService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _modalService: NgbModal,
        public tasksService: TasksService,
        public appModalService: ModalService
    ) {
        this.destroyed$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit() {
        this.validationErrors = null;
        this.invalidUserData = null;
        this.tasksService.visibleModal
            .pipe(skip(1))
            .pipe(takeUntil(this.destroyed$))
            .subscribe((modal) => {
                if (modal === Modal.AssignTaskToUser) {
                    if (this.tasksService.hasSelectedTasks) {
                        this.showModal();
                        this.isUserAssignedToASelectedTask =
                            this.tasksService.isUserAssignedToASelectedTask;
                    } else {
                        this.hideModal();
                    }
                }
            });
    }

    private showModal() {
        this._modalService.open(this._userChooserModal, {
            windowClass: 'modal-holder',
            centered: true,
            size: 'lg',
        });
    }

    public hideModal() {
        this.tasksService.setVisibleModal(null);
        this.tasksService.selectTask(null);
        this._modalService.dismissAll();
    }

    public assignToTask(user: TaskUser) {
        this.reset();
        this.validationErrors = this.tasksService.validateTaskCollectionByRole(
            user.roles
        );
        if (
            this.validationErrors.userRoles !== undefined &&
            this.validationErrors.userRoles.length > 0
        ) {
            this.invalidUserData = user;
        } else {
            this.tasksService.assignTaskToUser(user);
            this._modalService.dismissAll();
        }
    }

    public unassignTasks(): void {
        this.reset();

        this.tasksService.unassignTasks();
        this.tasksService.setVisibleModal(null);
        this.tasksService.selectTask(null);
        this.appModalService.setModalVisibility(false, 'unassign-tasks');
    }

    public hideUnassignModal(): void {
        this.appModalService.setModalVisibility(false, 'unassign-tasks');
        this.showModal();
    }

    public reset(): void {
        this.validationErrors = null;
        this.invalidUserData = null;
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
