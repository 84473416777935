import { Component, Inject } from '@angular/core';
import { NavMenuItems } from 'dm-src/app/modules/nav-menu/nav-menu-items';
import { environment } from '../../../environments/environment.prod';
@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css'],
})
export class NavMenuComponent {
    public menuItems: any[];

    appVersion = environment.appVersion;

    constructor() {
        this.menuItems = NavMenuItems;
    }
}
