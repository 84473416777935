<div class="container">
    <div class="card card-container">
        <img
            id="profile-img"
            class="profile-img-card"
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        />
        <p id="profile-name" class="profile-name-card">
            {{ 'common.login' | translate }}
        </p>
        <lib-flash-message id="login"></lib-flash-message>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-signin">
            <span id="reauth-email" class="reauth-email"></span>
            <input
                type="email"
                id="inputEmail"
                formControlName="email"
                class="form-control"
                placeholder="{{ 'common.email' | translate }}"
                required
                autofocus
                [ngClass]="{ 'is-invalid': isSubmitted && fields.email.errors }"
            />
            <div *ngIf="isSubmitted && fields.email.errors" class="invalid-feedback">
                <div *ngIf="fields.email.errors.required">Email is required</div>
            </div>
            <input
                type="password"
                id="inputPassword"
                formControlName="password"
                class="form-control"
                placeholder="{{ 'common.password' | translate }}"
                required
                [ngClass]="{ 'is-invalid': isSubmitted && fields.password.errors }"
            />
            <div *ngIf="isSubmitted && fields.password.errors" class="invalid-feedback">
                <div *ngIf="fields.password.errors.required">Password is required</div>
            </div>
            <div id="remember" class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        value="remember-me"
                        formControlName="rememberMe"
                    />
                    Remember me
                </label>
            </div>
            <button class="btn btn-lg btn-primary btn-block btn-signin" type="submit">
                Sign in
            </button>
        </form>
        <a [routerLink]="['/login/forgotpassword']" class="forgot-password">
            {{ 'login.forgot-password' | translate }}</a
        >
        <img
            *ngIf="showCountrySelector"
            [src]="selectedCountryTld"
            (click)="handleCountryChooser()"
            alt="Country"
            class="country-selector"
        />
    </div>
</div>
