import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AUTH_SERVICE_IMPL, IAuthenticationService } from 'shared';
import { HttpResponse } from '@angular/common/http';
import { SchedulerResourceEvent } from 'dm-src/models/scheduler-resource-event';
import {
    ISupplierResourcesService,
    SUPPLIER_RESOURCES_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isupplier-resources-service';
import { SupplierResource } from 'dm-src/models/supplier-resource';

@Injectable({
    providedIn: 'root',
})
export class SupplierResourcesService {
    public bypassWorktimeStorage = false;
    public currentSupplierRegionID: string;
    private _isAvailableSlotInvalid: BehaviorSubject<boolean>;
    private _currentSupplierID: string;
    private _schedulerEvents: BehaviorSubject<SchedulerResourceEvent[]>;

    public get schedulerEvents(): Observable<SchedulerResourceEvent[]> {
        return this._schedulerEvents.asObservable();
    }

    public get isAvailableSlotInvalid(): Observable<boolean> {
        return this._isAvailableSlotInvalid.asObservable();
    }

    constructor(
        @Inject(SUPPLIER_RESOURCES_SERVICE_IMPL)
        private _supplierResourcesService: ISupplierResourcesService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {
        this._schedulerEvents = new BehaviorSubject<SchedulerResourceEvent[]>([]);
        this._isAvailableSlotInvalid = new BehaviorSubject<boolean>(false);
        this._currentSupplierID = this._authService.currentUser.userID;
        this.currentSupplierRegionID = null;
    }

    public getSechedulerEvents(): void {
        this._supplierResourcesService
            .getSupplierResources(this.currentSupplierRegionID)
            .subscribe((response) => {
                if (response.status === 200) {
                    response.body.map((event, index) => {
                        event.Id = index;
                        event.StartTime = new Date(event.StartTime);
                        event.EndTime = new Date(event.EndTime);
                        return event;
                    });
                    this._schedulerEvents.next(response.body);
                }
            });
    }

    public createSchedulerEvents(
        events: SchedulerResourceEvent[]
    ): Observable<HttpResponse<string[]>> {
        const supplierResources = this.getSupplierResources(events);
        return this._supplierResourcesService.createSupplierResources(supplierResources);
    }

    public updateSchedulerEvent(event: SchedulerResourceEvent) {
        const supplierResource = this.getSupplierResource(event);

        this._supplierResourcesService
            .updateSupplierResource(supplierResource)
            .subscribe();
    }

    public deleteSchedulerEvent(
        supplierResourceID: string
    ): Observable<HttpResponse<void>> {
        return this._supplierResourcesService.deleteSupplierResource(supplierResourceID);
    }

    public setAvailableSlotInvalid(isInvalid: boolean) {
        this._isAvailableSlotInvalid.next(isInvalid);
    }

    public changeSupplierRegion(supplierRegionID: string) {
        this.currentSupplierRegionID = supplierRegionID;
        this.getSechedulerEvents();
    }

    private getSupplierResources(events: SchedulerResourceEvent[]): SupplierResource[] {
        return events.map((event) => {
            return this.getSupplierResource(event);
        });
    }

    private getSupplierResource(event: SchedulerResourceEvent): SupplierResource {
        const supplierResource = new SupplierResource();

        if (event.EventID) {
            supplierResource.supplierResourceID = event.EventID;
        }

        supplierResource.slotStart = event.StartTime;
        supplierResource.slotEnd = event.EndTime;
        supplierResource.availableSlot = event.AvailableSlot;
        supplierResource.supplierRegionID = this.currentSupplierRegionID;

        return supplierResource;
    }
}
