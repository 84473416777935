import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { CommonEventLog } from '../../models/common-event-log';
import { OrderStateEventLog } from '../../models/order-state-event-log';
import { SupplierWorktimeEventLog } from '../../models/supplier-worktime-event-log';
import { SupplierResourceEventLog } from '../../models/supplier-resource-event-log';
import { ErrorLogDto } from '../../dtos/error-log-dto';
import { InfoLogDto } from '../../dtos/info-log-dto';
import { InjectionToken } from '@angular/core';
import { ModuleID } from '../../types/module-id';

export const LOGGER_CLIENT_SERVICE_IMPL = new InjectionToken<ILoggerClientService>(
    'loggerClientServiceImpl'
);
export interface ILoggerClientService {
    addCommonEventLog(commonEventLog: CommonEventLog): Observable<HttpResponse<boolean>>;
    addOrderStateEventLog(
        orderStateEventLog: OrderStateEventLog
    ): Observable<HttpResponse<boolean>>;
    addSupplierWorktimeEventLog(
        supplierWorktimeEventLog: SupplierWorktimeEventLog
    ): Observable<HttpResponse<boolean>>;
    addSupplierResourceEventLog(
        supplierResourceEventLog: SupplierResourceEventLog
    ): Observable<HttpResponse<boolean>>;
    addErrorLog(
        errorLogDto: ErrorLogDto,
        moduleID: ModuleID
    ): Observable<HttpResponse<boolean>>;
    addInfoLog(
        infoLogDto: InfoLogDto,
        moduleID: ModuleID
    ): Observable<HttpResponse<boolean>>;
}
