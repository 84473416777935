import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderOrdersService } from 'dm-src/app/modules/provider-orders/provider-orders.service';
import { ProviderOrder } from 'dm-src/models/provider-order';
import { environment } from 'dm-src/environments/environment';

@Component({
    selector: 'app-provider-order-details-modal',
    templateUrl: './provider-order-details-modal.component.html',
    styleUrls: ['./provider-order-details-modal.component.scss'],
})
export class ProviderOrderDetailsModalComponent implements OnInit, OnDestroy {
    @Input() order: ProviderOrder;
    public billPhotoURL: string;
    private _destroy$: ReplaySubject<boolean>;
    @ViewChild('orderDetailsModal', { static: false })
    private _orderDetailsModal: TemplateRef<any>;

    constructor(
        private _providerOrdersService: ProviderOrdersService,
        private _modalService: NgbModal
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit() {
        this._providerOrdersService.isOrderDetailsModalShown
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isShown) => {
                if (isShown) {
                    this.billPhotoURL = this.getBillPhotoUrl(
                        this._providerOrdersService.selectedOrder.billPhotoFilename
                    );
                    this._modalService.open(this._orderDetailsModal, {
                        windowClass: 'modal-holder',
                        size: 'xl',
                        centered: true,
                    });
                } else {
                    this._modalService.dismissAll();
                }
            });
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
    }

    private getBillPhotoUrl(filename: string): string {
        if (filename !== null) {
            return `${environment.deliveryManagerMediaUrl}${environment.billPhotoFolder}/${filename}`;
        }
        return null;
    }
}
