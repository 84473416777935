import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MyTasksService } from 'dm-src/app/modules/my-tasks/my-tasks.service';
import {
    IQRFactoryService,
    QR_FACTORY_SERVICE_IMPL,
} from 'dm-src/services/qr-factory/iqr-factory.service';
import {
    ColorClass,
    FileHelper,
    OrderItem,
    OrderItemState,
    prepareOrderItemList,
    TaskListItem,
} from 'shared';
import { MarkupCheckoutData } from 'projects/shared/src/lib/models/markup-checkout-data';

@Component({
    selector: 'app-package-verify-modal',
    templateUrl: './package-verify-modal.component.html',
    styleUrls: ['./package-verify-modal.component.scss'],
})
export class PackageVerifyModalComponent implements OnInit {
    public task: TaskListItem;
    public qrImage: any;
    public isInProgress = false;
    public elapsedTime: string;
    public ColorClass = ColorClass;
    public orderData: MarkupCheckoutData;
    public orderItems: OrderItem[];

    constructor(
        @Inject(QR_FACTORY_SERVICE_IMPL)
        private _qrFactoryService: IQRFactoryService,
        private _myTasksService: MyTasksService
    ) {}

    ngOnInit(): void {
        this._myTasksService.selectedTask.subscribe((selectedTask) => {
            if (selectedTask) {
                this.task = selectedTask;
                this._qrFactoryService
                    .getForCheckout(selectedTask.orderQrCode, false)
                    .subscribe(
                        (response) => {
                            this.isInProgress = false;
                            if (response.status === 200) {
                                this.createImageFromBlob(response.body);
                            }
                        },
                        () => {
                            this.isInProgress = false;
                        }
                    );

                this._qrFactoryService
                    .getOrderData(selectedTask.orderQrCode)
                    .subscribe((response) => {
                        this.orderData = response.body;
                        this.orderData.orderItemList = prepareOrderItemList(
                            this.orderData.orderItemList
                        );
                        this.orderItems = this.getOrderedOrderItems(
                            this.orderData.orderItemList
                        );
                    });
            }
        });

        setInterval(() => {
            this.elapsedTime = this.getElapsedTime();
        }, 1000);
    }

    async createImageFromBlob(image: Blob) {
        this.qrImage = await FileHelper.createImageFromBlobAsync(image);
    }

    private getElapsedTime(): string {
        if (this.task) {
            let endTime = new Date();
            let startTime = new Date(this.task.createdAt);

            let timeDiff = endTime.getTime() - startTime.getTime();

            timeDiff = timeDiff / 1000;
            let seconds = Math.floor(timeDiff % 60);
            let secondsAsString = seconds < 10 ? '0' + seconds : seconds + '';

            timeDiff = Math.floor(timeDiff / 60);
            let minutes = timeDiff % 60;
            let minutesAsString = minutes < 10 ? '0' + minutes : minutes + '';

            timeDiff = Math.floor(timeDiff / 60);
            let hours = timeDiff % 24;

            timeDiff = Math.floor(timeDiff / 24);
            let days = timeDiff;

            let totalHours = hours + days * 24;
            let totalHoursAsString = totalHours < 10 ? '0' + totalHours : totalHours + '';

            if (totalHoursAsString === '00') {
                return minutesAsString + ':' + secondsAsString;
            } else {
                return totalHoursAsString + ':' + minutesAsString + ':' + secondsAsString;
            }
        }
    }

    private getOrderedOrderItems(orderItems: OrderItem[]): OrderItem[] {
        let notFounds: OrderItem[] = [];
        let lrwNotFounds: OrderItem[] = [];
        let availableNotLRW: OrderItem[] = [];
        let i = orderItems.length;
        while (i--) {
            if (orderItems[i].state == OrderItemState.NotFound && !orderItems[i].isLRW) {
                notFounds.push(orderItems[i]);
                orderItems.splice(i, 1);
            } else if (
                orderItems[i].state == OrderItemState.NotFound &&
                orderItems[i].isLRW
            ) {
                lrwNotFounds.push(orderItems[i]);
                orderItems.splice(i, 1);
            } else if (!orderItems[i].isLRW) {
                availableNotLRW.push(orderItems[i]);
                orderItems.splice(i, 1);
            }
        }

        let orderedOrderItems = orderItems
            .concat(availableNotLRW)
            .concat(lrwNotFounds)
            .concat(notFounds);
        return orderedOrderItems;
    }
}
