<div class="container">
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="card p-4">
                <div class="card-header bg-secondary">Order payment details</div>
                <div class="row mt-2 px-1">
                    <div class="col-12 col-lg-6 col-md-6">
                        <p class="m-0 p-0">
                            <strong>{{ 'orders.total-price' | translate }}</strong>
                        </p>
                        <p class="mb-0 pb-0">
                            {{
                                (orderDetails | async).totalPrice?.toLocaleString(
                                    'en-US',
                                    { minimumFractionDigits: 2 }
                                )
                            }}
                            {{ 'common.currency' | translate }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-6 col-md-6">
                        <p class="m-0 p-0">
                            <strong>{{
                                'orders.customer-total-price' | translate
                            }}</strong>
                        </p>
                        <p class="mb-0 pb-0">
                            {{
                                (orderDetails | async).customerTotalPrice?.toLocaleString(
                                    'en-US',
                                    { minimumFractionDigits: 2 }
                                )
                            }}
                            {{ 'common.currency' | translate }}
                        </p>
                    </div>
                    <div class="col-12 px-3 m-0 p-0">
                        <hr class="mb-2 p-0" />
                    </div>
                    <div class="col-12 col-lg-6 col-md-6">
                        <p class="m-0 p-0">
                            <strong>{{
                                'orders.personal-shopper-paid' | translate
                            }}</strong>
                        </p>
                        <p class="mb-0 pb-0">
                            {{
                                (orderDetails | async).paidTotalPrice?.toLocaleString(
                                    'en-US',
                                    { minimumFractionDigits: 2 }
                                )
                            }}
                            {{ 'common.currency' | translate }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-6 col-md-6">
                        <p class="m-0 p-0">
                            <strong>{{
                                'orders.customer-paid-total-price' | translate
                            }}</strong>
                        </p>
                        <p class="mb-0 pb-0">
                            {{
                                (
                                    orderDetails | async
                                ).customerPaidTotalPriceWithDiscount?.toLocaleString(
                                    'en-US',
                                    {
                                        minimumFractionDigits: 2
                                    }
                                )
                            }}
                            {{ 'common.currency' | translate }}
                        </p>
                    </div>
                    <div class="col-12 px-3 m-0 p-0">
                        <hr class="mb-2 p-0" />
                    </div>
                    <div class="col-12 col-lg-6 col-md-6">
                        <p class="m-0 p-0">
                            <strong>{{
                                'orders.total-price-discount' | translate
                            }}</strong>
                        </p>
                        <p class="mb-0 pb-0">
                            {{
                                (orderDetails | async).totalPriceDiscount?.toLocaleString(
                                    'en-US',
                                    { minimumFractionDigits: 2 }
                                )
                            }}
                            {{ 'common.currency' | translate }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-6 col-md-6">
                        <p class="m-0 p-0">
                            <strong>{{ 'orders.roksh-discount' | translate }}</strong>
                        </p>
                        <p class="mb-0 pb-0">
                            {{
                                (
                                    orderDetails | async
                                ).rokshDiscountTotalPrice?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2
                                })
                            }}
                            {{ 'common.currency' | translate }}
                        </p>
                    </div>
                    <div class="col-12 px-3 m-0 p-0">
                        <hr class="mb-2 p-0" />
                    </div>
                    <div class="col-12 col-lg-6 col-md-6">
                        <p class="m-0 p-0">
                            <strong>{{ 'orders.roksh-tip-amount' | translate }}</strong>
                        </p>
                        <p class="mb-0 pb-0">
                            <span *ngIf="!(orderDetails | async).isSuperOrder"
                                >{{ 'orders.roksh-tip-amount' | translate }}:
                            </span>
                            <span *ngIf="(orderDetails | async).isSuperOrder"
                                >{{ 'orders.multi-order-roksh-tip-amount' | translate }}:
                            </span>
                            {{
                                (orderDetails | async).rokshTipAmount?.toLocaleString(
                                    'en-US',
                                    { minimumFractionDigits: 2 }
                                )
                            }}
                            {{ 'common.currency' | translate }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-6 col-md-6">
                        <p class="m-0 p-0">
                            <strong>{{ 'orders.system-usage-fee' | translate }}</strong>
                        </p>
                        <p class="mb-0 pb-0">
                            {{
                                (orderDetails | async).systemUsageFee?.toLocaleString(
                                    'en-US',
                                    { minimumFractionDigits: 2 }
                                )
                            }}
                            {{ 'common.currency' | translate }}
                        </p>
                    </div>
                    <div class="col-12 px-3 m-0 p-0">
                        <hr class="mb-2 p-0" />
                    </div>
                    <div class="col-12 col-lg-6 col-md-6">
                        <p class="m-0 p-0">
                            <strong>{{ 'orders.delivery-fee' | translate }}</strong>
                        </p>
                        <app-delivery-fee
                            [orderDetails]="orderDetails | async"
                            [orderID]="(selectedOrder | async).orderID"
                        >
                        </app-delivery-fee>
                    </div>
                    <div class="col-12 col-lg-6 col-md-6">
                        <p class="m-0 p-0">
                            <strong>{{ 'orders.small-order-fee' | translate }}</strong>
                        </p>
                        <p class="mb-0 pb-0">
                            <span *ngIf="!(orderDetails | async).isSuperOrder"
                                >{{ 'orders.small-order-fee' | translate }}:
                            </span>
                            <span *ngIf="(orderDetails | async).isSuperOrder"
                                >{{ 'orders.multi-order-small-order-fee' | translate }}:
                            </span>
                            {{
                                (orderDetails | async).smallOrderFee?.toLocaleString(
                                    'en-US',
                                    { minimumFractionDigits: 2 }
                                )
                            }}
                            {{ 'common.currency' | translate }}
                        </p>
                    </div>
                    <div class="col-12 mb-2">
                        <p class="m-0 p-2 text-right">
                            <strong *ngIf="!(orderDetails | async).isSuperOrder">
                                {{ 'orders.finished-total-price' | translate }}
                            </strong>
                            <strong *ngIf="(orderDetails | async).isSuperOrder">
                                {{
                                    'orders.multi-order-finished-total-price' | translate
                                }}
                            </strong>
                        </p>
                        <h4
                            class="text-monospace text-uppercase text-primary p-2 text-right bg-secondary"
                        >
                            {{
                                (
                                    (orderDetails | async).deliveryFee +
                                    (orderDetails | async).customerPaidTotalPrice
                                )?.toLocaleString('en-US', { minimumFractionDigits: 2 })
                            }}
                            {{ 'common.currency' | translate }}
                        </h4>
                        <div class="d-flex justify-content-end">
                            <p>{{ 'orders.finished-total-price-comment' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <div class="btn-group" role="group">
                        <button
                            *appHasRoles="['administrator', 'support']"
                            class="btn btn-warning"
                            [ngClass]="{ hidden: isRefundConfirmationVisible }"
                            [disabled]="!isPaymentRefundActive"
                            (click)="isRefundConfirmationVisible = true"
                        >
                            Refund payment
                        </button>
                        <div *ngIf="isRefundConfirmationVisible" class="text-center">
                            Are you sure you want to refund payment for order
                            <strong>{{ orderCode }}</strong
                            >?
                            <div class="btn-group mt-1">
                                <button class="btn btn-success" (click)="refundPayment()">
                                    Yes
                                </button>
                                <button
                                    class="btn btn-warning"
                                    (click)="isRefundConfirmationVisible = false"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
