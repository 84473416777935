export enum EnvironmentName {
    DEV,
    MASTER_HU,
    MASTER_AT,
    STAGING_HU,
    STAGING_AT,
    PROD_HU,
    PROD_AT,
    NONE_OF_THEM,
}
