import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { IAuthenticationService } from './iauthentication.service';
import { User } from '../../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginCredentials } from '../../dtos/login-credentials';
import { map } from 'rxjs/operators';
import { IPolicyRoles } from '../../types/i-policy-roles';
import { ILoginParams } from '../../dtos/ilogin-params';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
@Sentry.TraceClassDecorator()
// @Injectable({ providedIn: 'root' })
export class AuthenticationService implements IAuthenticationService {
    private _applicationUserSubject: BehaviorSubject<User>;
    private _authToken?: string;
    public applicationUser: Observable<User>;

    constructor(
        @Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService,
        private _policyRoles: IPolicyRoles[],
        private _userStorageName
    ) {
        this._applicationUserSubject = new BehaviorSubject<User>(
            this.getUserFromStorage()
        );
        this.applicationUser = this._applicationUserSubject.asObservable();
    }

    public get currentUser(): User {
        return this._applicationUserSubject.getValue();
    }

    public updateCurrentUser(user: User): void {
        this._applicationUserSubject.next(user);
        localStorage.setItem(this._userStorageName, JSON.stringify(user));
    }

    public login(loginParams: ILoginParams): Observable<HttpResponse<User>> {
        const body = new LoginCredentials();

        body.email = loginParams.email;
        body.password = loginParams.password;
        body.rememberMe = loginParams.rememberMe;
        body.isApplication = loginParams.isApplication;

        return this._apiClient.postWithResponse<User>(`authentication/login`, body).pipe(
            map((response) => {
                const currentUser = new User(this._policyRoles);
                Object.assign(currentUser, response.body);
                this._applicationUserSubject.next(currentUser);
                localStorage.setItem(this._userStorageName, JSON.stringify(currentUser));
                return response;
            })
        );
    }

    public logout(): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(`authentication/logout`, null).pipe(
            map((response) => {
                if (response.status === 200) {
                    this.removeUserFromStorage();
                }
                return response;
            })
        );
    }

    public isAuthenticated(): boolean {
        return this._applicationUserSubject.value != null;
    }

    public removeUserFromStorage(): void {
        localStorage.removeItem(this._userStorageName);
        this._applicationUserSubject.next(null);
    }

    public getUserFromStorage(): User {
        const userData = JSON.parse(localStorage.getItem(this._userStorageName));
        if (userData === null) {
            return null;
        }
        const storedUser = new User(this._policyRoles);
        Object.assign(storedUser, userData);
        return storedUser;
    }

    getPolicyRoles(): IPolicyRoles[] {
        return this._policyRoles;
    }
}
