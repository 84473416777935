import { Injectable } from '@angular/core';
import { User } from 'projects/shared/src/lib/models/user';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RoleChooserService {
    private _user: BehaviorSubject<User>;

    constructor() {
        this._user = new BehaviorSubject(null);
    }

    get user() {
        return this._user.asObservable();
    }

    setUser(user: User) {
        this._user.next(user);
    }
}
