import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstantDeliveryComponent } from './instant-delivery.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AppSharedModule } from '../shared/app-shared.module';
import { SharedModule } from 'shared';

@NgModule({
    declarations: [InstantDeliveryComponent],
    imports: [
        CommonModule,
        TranslateModule,
        NgxSelectModule,
        NgbNavModule,
        SharedModule,
        AppSharedModule,
    ],
})
export class InstantDeliveryModule {}
