import { Component, OnInit } from '@angular/core';
import { ModalService } from 'shared';
import { OfflineProductsService } from '../../offline-products.service';

@Component({
    selector: 'app-invalid-offline-product-modal',
    templateUrl: './invalid-offline-product-modal.component.html',
    styleUrls: ['./invalid-offline-product-modal.component.scss'],
})
export class InvalidOfflineProductModalComponent implements OnInit {
    constructor(
        public offlineProductsService: OfflineProductsService,
        private _modalService: ModalService
    ) {}

    ngOnInit(): void {}

    public collect(): void {
        this.cancel();
        this.offlineProductsService.collectNewOrderItem();
    }

    public cancel(): void {
        this._modalService.setModalVisibility(false, 'invalid-offline-product-modal');
    }
}
