import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from 'shared';
import { IBarcodeFactoryService } from './ibarcode-factory.service';

@Injectable()
export class BarcodeFactoryService implements IBarcodeFactoryService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getBarcode(orderID: number): Observable<HttpResponse<Blob>> {
        return this._apiClient.getBlobWithResponse(
            `barcodefactory/GetBarcodeForOrder/${orderID}`
        );
    }
}
