import { OrderItemState } from '../types/order-item-state';
import { AppSettings } from '../../appsettings';

export abstract class BaseOrderItem {
    public providerOfflineProductID?: number;
    public unit: string;
    public price: number;
    public unitPrice: number;
    public totalPrice: number;
    public collectedQuantity = 0;
    public quantity: number;
    public weight: number;
    public productPhotoID: string;
    public providerProductImageURL: string;
    public sku: string;
    public gtinList: string[];
    public vat: number;
    public isVisible = false;
    public isBulk = false;
    public isLRW = false;
    public isWerwProduct = false;
    public categoryID: number;
    public parentCategoryID: number;
    public itemImageSrc: string = null;
    public itemThumbnailSrc: string = null;
    public isReplace: boolean;
    public quantityRequestedByUser: number;
    public setImageSrcs(): void {
        if (this.productPhotoID !== null) {
            this.itemThumbnailSrc = `${AppSettings.get('mediaServiceUrl')}/thumbnail/${
                this.productPhotoID
            }`;
            this.itemImageSrc = `${AppSettings.get('mediaServiceUrl')}/large/${
                this.productPhotoID
            }`;
        } else if (this.providerProductImageURL !== null) {
            this.itemThumbnailSrc = this.providerProductImageURL;
            this.itemImageSrc = this.providerProductImageURL;
        }
    }
}
