<lib-modal [title]="title" [bgColorClass]="color" [componentID]="'checkout-qr-modal'">
    <lib-flash-message [id]="'qr-code-message'"></lib-flash-message>
    <div class="text-center">
        <p>{{ text }}</p>
        <img [src]="qrImage" *ngIf="!isInProgress; else inProgress" />
        <ng-template #inProgress>
            <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            ></span>
            <span>{{ 'common.working' | translate }}...</span>
        </ng-template>
    </div>
    <button class="btn btn-info w-100" (click)="navigateToMyTasks()">
        {{ 'navigation.my-tasks' | translate }}
    </button>
</lib-modal>
