import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import {
    DeliveringTaskListItem,
    DeliveryTaskState,
    ITasksService,
    TASKS_SERVICE_IMPL,
    ColorClass,
    SumUpFinalize,
    CashOnDeliveryFinalize,
    PaymentType,
    LatLng,
    GEOLOCATION_SERVICE_IMPL,
    IGeolocationService,
    AppSettings,
} from 'shared';
import { MyTasksService } from 'dm-src/app/modules/my-tasks/my-tasks.service';
import { FlashMessage } from 'dm-src/dtos/flash-message';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { SumUpCallbackModel, SumupService } from 'dm-src/services/sumup/sumup.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil, take, filter } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-delivering-task-button',
    templateUrl: './delivering-task-buttons.component.html',
    styleUrls: ['./delivering-task-buttons.component.scss'],
})
export class DeliveringTaskButtonsComponent implements OnInit, OnDestroy {
    @Input() task: DeliveringTaskListItem;
    public DeliveryTaskState = DeliveryTaskState;
    private sumUpFinalize: SumUpFinalize;
    private cashOnDeliveryFinalize: CashOnDeliveryFinalize;
    public isPaidOnDelivery?: boolean;
    public paymentButtonNeeded: boolean;
    public myObservable: any;
    public myObservableData: any;
    private sumUpEndStatuses: string[] = ['SUCCESSFUL', 'FAILED'];

    private interval: NodeJS.Timeout;
    private componentDestroyed$: Subject<boolean> = new Subject();
    public paymentStatus = '';
    public detroyedComponent = '';
    public isButtonBlocked = false;
    lastLatLonData = new LatLng(0, 0);
    private uniqTransactionIdWithTaskId = '';
    public countryCode = location.origin.includes('.at') ? 'at' : 'hu';


    constructor(
        @Inject(TASKS_SERVICE_IMPL) private _tasksService: ITasksService,
        @Inject(GEOLOCATION_SERVICE_IMPL)
        private _geolocationService: IGeolocationService,
        private _myTasksService: MyTasksService,
        private snackBar: MatSnackBar,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService,
        private _sumupService: SumupService,
        private isWrapping: IsWrappingService,
        public datepipe: DatePipe,
        private translateService: TranslateService,
    ) {}

    ngOnInit(): void {
        this.checkPayment();
        this.paymentButtonNeeded =
            this.task.paymentType === PaymentType.PayOnDeliveryCard;
        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService.getCurrentPostMessage().subscribe((response) => {
                if (response.functionName === 'OPEN_SUMUP') {
                  this.sumupSubScribeEvent({
                    smpStatus: response.result['smp-status'],
                    smpTxCode: response.result['smp-tx-code'],
                    foreignTxId: response.result['foreign-tx-id'],
                  });
                }
            });
        }
    }

    public handleCollapse(collapsed: boolean) {
        if (!collapsed) {
            this.paymentButtonNeeded =
                this.task.paymentType === PaymentType.PayOnDeliveryCard ||
                this.task.paymentType === PaymentType.PayOnDeliveryCash;

            if (
                !this.isPaidOnDelivery &&
                this.paymentButtonNeeded &&
                this.task.paymentType === PaymentType.PayOnDeliveryCard
            ) {
                this.genereateUniqId();

                this.checkPayment();
            }
        }
    }

    public sumupSubScribeEvent(_sumUpCallbackModel?: SumUpCallbackModel) {
        this.myObservable = this._sumupService
            .getCallbackResult()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (response: SumUpCallbackModel) => {
                    if(_sumUpCallbackModel){
                      this.myObservableData = _sumUpCallbackModel;
                    } else {
                      this.myObservableData = response;
                    }
                    if (this.myObservableData != null) {
                        this.sumUpFinalize = {
                            taskID: this.uniqTransactionIdWithTaskId,
                            totalAmountFromPayment: this.getTotalPrice(),
                        };

                        if (this.sumUpFinalize) {
                            this._tasksService
                                .finalize(this.sumUpFinalize)
                                .pipe(take(5))
                                .subscribe(
                                    () => {
                                        this.paymentStatus =
                                            'Payment finalization finished (s) - (' +
                                            this.datepipe.transform(
                                                new Date(),
                                                'yyyy/MM/dd-h:mm:ss'
                                            ) +
                                            ')';
                                        this.myObservable.unsubscribe();
                                        this.isButtonBlocked = false;
                                        this.ngOnDestroy();
                                    },
                                    (error) => {
                                        console.error(error);
                                        this.paymentStatus =
                                            'Payment finalization finished (e)- (' +
                                            this.datepipe.transform(
                                                new Date(),
                                                'yyyy/MM/dd-h:mm:ss'
                                            ) +
                                            ')';
                                        this.myObservable.unsubscribe();
                                        this.isButtonBlocked = false;
                                        this.ngOnDestroy();
                                    }
                                );
                        }

                        if (
                            this.myObservableData?.smpStatus.toLocaleLowerCase() !==
                            'success'
                        ) {
                            this.genereateUniqId();

                            this.isPaidOnDelivery = false;
                            this.translateService
                                .get('payment-state.Failed')
                                .pipe(takeUntil(this.componentDestroyed$))
                                .subscribe((text) =>
                                    this._iosAndAndroidNatvieFunctionsService.showToast(
                                        text
                                    )
                                );
                        } else {
                            this.isPaidOnDelivery = true;
                        }

                        this.myObservable.unsubscribe();
                    }
                },
                (error) => {
                    this.myObservable.unsubscribe();
                    console.error(error);
                    this.isButtonBlocked = false;
                    this.ngOnDestroy();
                }
            );
    }

    public acceptDelivering(): void {
        this._tasksService
            .checkIfTheUserIsTheSame(this.task.deliveryTaskID)
            .subscribe((isTheSameUser) => {
                if (isTheSameUser) {
                    this._tasksService
                        .acceptDeliveringTask(this.task.deliveryTaskID)
                        .subscribe((response) => {
                            if (response.status === 200) {
                                this.task.state =
                                    DeliveryTaskState[DeliveryTaskState.ToDo];
                            }
                        });
                } else {
                    this.snackBar.open(
                        this.translateService.instant(
                            'messages.the-user-is-not-the-same'
                        ),
                        null,
                        {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 10000,
                            panelClass: ['error-snackbar'],
                        }
                    );
                }
            });
    }

    public startDelivering(): void {
        this._tasksService
            .startDeliveringTask(this.task.deliveryTaskID)
            .subscribe((response) => {
                if (response.status === 200) {
                    this.task.state = DeliveryTaskState[DeliveryTaskState.InProgress];
                }
            });
    }

    public setDeliveringDoneWithLatLong(): void {
        let supplierPosition = new LatLng(0, 0);
        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService.getCurrentLocation(
                this.task.deliveryTaskID,
                'DeliveringTaskButtonsComponent'
            );
            this._iosAndAndroidNatvieFunctionsService.currentLatLonData
                .pipe(take(2))
                .subscribe((response) => {
                    if (response?.taskID === this.task.deliveryTaskID) {
                        const latLonData = response.message;
                        this.lastLatLonData = {
                            lat: latLonData?.coords?.latitude,
                            lng: latLonData?.coords?.longitude,
                        };
                        this.setDeliveringDone(this.lastLatLonData);
                    }
                });
        } else if (
            this._iosAndAndroidNatvieFunctionsService.osType === 'Android' &&
            this.isWrapping.isWrapping()
        ) {
            const wrapperLastLocationData = JSON.parse(
                this._iosAndAndroidNatvieFunctionsService.getCurrentLocation()
            );

            supplierPosition.lng = wrapperLastLocationData?.lon;
            supplierPosition.lat = wrapperLastLocationData?.lat;
            this.setDeliveringDone(supplierPosition);
        } else {
            this._geolocationService
                .getCurrentPosition()
                .subscribe((positionResponse) => {
                    if (positionResponse.coords !== undefined) {
                        supplierPosition = new LatLng(
                            positionResponse.coords.latitude,
                            positionResponse.coords.longitude
                        );
                        this.setDeliveringDone(supplierPosition);
                    }
                });
        }
    }

    public setDeliveringFailed(): void {
        this._myTasksService.setTaskFailed(this.task.deliveryTaskID);
        this.ngOnDestroy();
    }

    public setDeliveringDone(supplierPosition: LatLng): void {
        this._tasksService
            .setDeliveringTaskDone(
                this.task.deliveryTaskID,
                this.task.orderID,
                supplierPosition.lat,
                supplierPosition.lng
            )
            .subscribe((response) => {
                if (response.status === 200) {
                    this._myTasksService.removeTask(this.task.deliveryTaskID);

                    const message = new FlashMessage();

                    message.type = ColorClass.Success;
                    message.title = 'Success';
                    message.message = 'Collecting task finished successfully';

                    this._myTasksService.showFlashMessage(message);
                    this._myTasksService.getNextTask();
                    this.ngOnDestroy();
                }
            });
    }

    handleSumUpPay(): void {
        if (this.isButtonBlocked) return;
        this.isButtonBlocked = true;

        this.myObservableData = null;
        this.genereateUniqId();
        this._tasksService
            .createOrderPaymentHistory({
                transaction: 'INPROGRESS',
                request: this.uniqTransactionIdWithTaskId,
                response: this.task.deliveryTaskID,
                amount: this.getTotalPrice(),
            })
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((resp) => {
                this._iosAndAndroidNatvieFunctionsService.openSumUp(
                    this.getTotalPrice(),
                    AppSettings.get('currency'),
                    `${this.uniqTransactionIdWithTaskId}`,
                    AppSettings.get('sumUpAffiliateKey'),
                    this.task.orderCode
                );

              if(!window.ReactNativeWebView) {
                this.waitingForSumUpResponse();
              }
            });
    }

    waitingForSumUpResponse(): void {
      this.interval = setInterval(() => {
        this._tasksService
          .getOrderPaymentHistory(this.task.deliveryTaskID)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            (response) => {
              if (
                this.sumUpEndStatuses.includes(
                  response.transaction
                )
              ) {
                if(!window.ReactNativeWebView) {
                  this.sumupSubScribeEvent();
                }
              }

              this._tasksService
                .isPaidOnDelivery(this.task.deliveryTaskID)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe((res) => {
                  this.paymentStatus =
                    'Payment started - ' +
                    this.datepipe.transform(
                      new Date(),
                      'yyyy/MM/dd-h:mm:ss'
                    );
                  if (res) {
                    clearInterval(this.interval);
                    if(!window.ReactNativeWebView) {
                      this.sumupSubScribeEvent();
                    }
                  }
                });
            },
            (error) => {
              console.log('error', error);
              this.componentDestroyed$.next(true);
              this.componentDestroyed$.complete();
              clearInterval(this.interval);
            }
          );
      }, 5000);
    }

    handleCashOnDeliveryPay(): void {
        if (this.isButtonBlocked) return;

        this.isButtonBlocked = true;

        let infoMessage = this.translateService.instant('messages.cash-on-delivery-info');

        if (confirm(infoMessage) === true) {
            this.cashOnDeliveryFinalize = {
                taskID: this.task.deliveryTaskID,
                totalAmountFromPayment: this.getTotalPrice(),
            };

            this._tasksService
                .cashOnDeliveryFinalize(this.cashOnDeliveryFinalize)
                .subscribe(
                    (successful) => {
                        if (successful) {
                            this.isPaidOnDelivery = true;
                            this.isButtonBlocked = true;
                        } else {
                            this.isPaidOnDelivery = false;
                            this.isButtonBlocked = false;
                        }
                    },
                    (error) => {
                        this.isPaidOnDelivery = false;
                        this.isButtonBlocked = false;

                        console.log(error);
                    }
                );
        } else {
            this.isPaidOnDelivery = false;
            this.isButtonBlocked = false;
        }
    }

    public getTotalPrice(): number {
        return (
            this.task.customerPaidTotalPrice +
            this.task.deliveryFee +
            this.getWishListOrdersSumTotalPrice()
        );
    }

    public getWishListOrdersSumTotalPrice(): number {
        let wishListOrders = this.task.wishListOrders.filter(
            (x) => x.isCompletedWishListOrder && x.totalPrice && 0 < x.totalPrice
        );

        let sum = 0;

        if (!wishListOrders || wishListOrders.length === 0) {
            return sum;
        }

        wishListOrders.forEach((x) => (sum += x.totalPrice));

        return sum;
    }

    public showPaymentButton(): boolean {
        return (
            !this.isPaidOnDelivery &&
            this.paymentButtonNeeded &&
            this.task.paymentType === PaymentType.PayOnDeliveryCard &&
            this.task.state === DeliveryTaskState[this.DeliveryTaskState.InProgress]
        );
    }

    public showCashOnDeliveryButton(): boolean {
        return (
            !this.isPaidOnDelivery &&
            this.paymentButtonNeeded &&
            this.task.paymentType === PaymentType.PayOnDeliveryCash &&
            this.task.state === DeliveryTaskState[this.DeliveryTaskState.InProgress]
        );
    }

    getIsPaid(): boolean {
        return this.isPaidOnDelivery || !this.paymentButtonNeeded;
    }

    genereateUniqId(): void {
        this.uniqTransactionIdWithTaskId = `${this.task.deliveryTaskID}|${Math.round(
            +new Date() * 1000
        )}`;
    }

    private checkPayment() {
        this._tasksService
            .isSuccessfulPayment(this.task.deliveryTaskID)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((result) => {
                this.isPaidOnDelivery = result;
            });
    }

    ngOnDestroy(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.detroyedComponent =
            'Ready for navigation - (' +
            this.datepipe.transform(new Date(), 'yyyy/MM/dd-h:mm:ss') +
            ')';
    }
}
