import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../../services/authentication/iauthentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProgressLineService } from '../progress-line/progress-line.service';
import { FlashMessageService } from '../flash-message/flash-message.service';
import { ILoginParams } from '../../dtos/ilogin-params';
import { BehaviorSubject } from 'rxjs';
import { ColorClass } from '../../types/color-class';
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'lib-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    public rememberMe = false;
    public loginForm: UntypedFormGroup;
    public returnUrl: string;
    public isSubmitted: boolean;
    public isLoading: boolean;
    public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    secretKey = '%~g6YnQjp&u5&*B';
    encrypt(value: string): string {
        return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
    }

    decrypt(textToDecrypt: string) {
        return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(
            CryptoJS.enc.Utf8
        );
    }

    @Input() showCountrySelector = false;
    @Input() isApplication = false;
    @Input() osType = '';
    @Output() public loginClick = new EventEmitter<string>();
    public countryTLDList = ['hu', 'at'];
    public selectedCountryTld = 'shared/assets/images/at.svg';
    constructor(
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _formBuilder: UntypedFormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private _progressLineService: ProgressLineService,
        private _flashMessageService: FlashMessageService
    ) {
        if (this.showCountrySelector) {
            const domainInfo = document.location.hostname.split('.');
            if (domainInfo[domainInfo.length - 1] === 'at') {
                this.selectedCountryTld = this.selectedCountryTld.replace('at', 'com');
            }
        }
    }

    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            rememberMe: [this.rememberMe],
        });

        this._route.paramMap.subscribe((params: ParamMap) => {
            if (params.get('usr')) {
                this.fields.email.value = params.get('usr');
                this.fields.password.value = atob(params.get('pw'));
                setTimeout(() => {
                    this.onSubmit();
                }, 500);
            }
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams.returnUrl || '/';

        setTimeout(() => {
            const currentUser = this._authService.currentUser;
            if (currentUser) {
                this._router.navigate(['/dashboard']);
            }
        }, 1000);

        if (localStorage.getItem('rememberMe') == 'true') {
            this.loginForm.controls.rememberMe.setValue(
                localStorage.getItem('rememberMe')
            );
            this.loginForm.controls.email.setValue(localStorage.getItem('loginEmail'));
            this.loginForm.controls.password.setValue(
                this.decrypt(localStorage.getItem('loginPassword') ?? '')
            );
        }
    }

    public get fields(): any {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.isSubmitted = true;

        if (this.loginForm.invalid && !this._route.snapshot.queryParams.usr) {
            return;
        }

        if (
            this.fields.email.value === 'testdeliverymanager7@gmail.com' &&
            !document.location.href.includes('delivery-manager-staging')
        ) {
            return (document.location.href = `https://delivery-manager-staging.roksh.com/login/${
                this.fields.email.value
            }/${btoa(this.fields.password.value)}`);
        }

        this.isLoading = true;
        this.loading.next(true);
        this._progressLineService.showProgressLine();

        const loginParams: ILoginParams = {
            email: this.fields.email.value,
            password: this.fields.password.value,
            rememberMe: this.fields.rememberMe.value,
            isApplication: this.isApplication,
        };

        this._authService
            .login(loginParams)
            .pipe(first())
            .subscribe(
                () => {
                    if (loginParams.rememberMe) {
                        localStorage.setItem('rememberMe', 'true');
                        localStorage.setItem('loginEmail', loginParams.email);
                        localStorage.setItem(
                            'loginPassword',
                            this.encrypt(loginParams.password)
                        );
                    } else {
                        localStorage.setItem('rememberMe', 'false');
                        localStorage.setItem('loginEmail', '');
                        localStorage.setItem('loginPassword', '');
                    }

                    this.loginClick.emit(loginParams.email);

                    this._router.navigate([this.returnUrl]);
                },
                (error: HttpErrorResponse) => {
                    this.isLoading = false;
                    this.loading.next(false);
                    setTimeout(() => {
                        this._flashMessageService.showMessage(
                            'Error',
                            error.error,
                            10000,
                            ColorClass.Danger,
                            'login'
                        );
                    }, 0);

                    if (error.error?.reason === 'ForcedPasswordChange') {
                        const navigationExtras: NavigationExtras = {
                            queryParams: {
                                token: error.error.token,
                                email: error.error.email,
                            },
                        };
                        this._router.navigate(
                            ['/login/changepassword'],
                            navigationExtras
                        );
                    }

                    if (error.error === 'login.dont-use-this-app-from-browser') {
                        setTimeout(() => {
                            if (this.osType.toLowerCase() === 'ios') {
                                window.location.replace(
                                    'https://apps.apple.com/us/app/delivery-manager-by-roksh/id1623346613'
                                );
                            } else if (this.osType.toLowerCase() === 'android') {
                                window.location.replace(
                                    'https://webgyar.com/apk_65116564asdd16a514/dmapp/production/'
                                );
                            } else {
                                window.location.replace(
                                    'https://appadvice.com/app/delivery-manager-by-roksh/1623346613'
                                );
                            }
                        }, 10000);
                    }
                },
                () => {
                    this._progressLineService.hideProgressLine();
                    this.loading.next(false);
                }
            );
    }

    handleCountryChooser() {
        const domainInfo = document.location.hostname.split('.');
        const tld = domainInfo[domainInfo.length - 1];
        if (tld) {
            if (tld === 'com') {
                document.location.href = document.location.href.replace('.com', '.at');
            } else if (tld === 'at') {
                document.location.href = document.location.href.replace('.at', '.com');
            }
            // do not do anything.. for example: http://localhost:4200
        }
    }
}
