<header>
    <nav
        class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow"
    >
        <div class="container">
            <a class="navbar-brand d-sm-none d-lg-block" [routerLink]="['/']"
                >Roksh Delivery Manager {{ versionString }}</a
            >

            <a class="navbar-brand d-none d-sm-block d-lg-none" [routerLink]="['/']"
                >RDM</a
            >
            <a class="d-none d-sm-block d-lg-none">{{ versionString }}</a>

            <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target=".navbar-collapse"
                aria-label="Toggle navigation"
                [attr.aria-expanded]="isExpanded"
                (click)="toggle()"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div
                class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
                [ngClass]="{ show: isExpanded }"
            >
                <ul class="navbar-nav flex-grow">
                    <ng-container *ngFor="let item of menuItems">
                        <li
                            *ngIf="isVisible(item)"
                            [ngClass]="{ 'nav-item': true, dropdown: hasChildren(item) }"
                            [routerLinkActive]="['link-active']"
                            [routerLinkActiveOptions]="{ exact: true }"
                            ngbDropdown
                        >
                            <a
                                *ngIf="!hasChildren(item); else parentItem"
                                (click)="toggle(item)"
                                class="nav-link text-dark"
                                [routerLink]="[item.path]"
                                >{{ item.label | translate }}</a
                            >
                            <ng-template #parentItem>
                                <span class="nav-link text-dark" ngbDropdownToggle>{{
                                    item.label | translate
                                }}</span>
                            </ng-template>
                            <div *ngIf="hasChildren(item)" ngbDropdownMenu>
                                <ng-container *ngFor="let subItem of item.children">
                                    <a
                                        *ngIf="isVisible(subItem)"
                                        ngbDropdownItem
                                        class="dropdown-item"
                                        (click)="toggle(subItem)"
                                        [routerLink]="[subItem.path]"
                                        >{{ subItem.label | translate }}</a
                                    >
                                </ng-container>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </nav>
</header>
