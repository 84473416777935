import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../services/authentication/iauthentication.service';
import { UserRole } from '../types/user-role';
import { FlashMessageService } from '../modules/flash-message/flash-message.service';

@Injectable({
    providedIn: 'root',
})
export class AdminGuard  {
    constructor(
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _router: Router,
        private _flashMessageService: FlashMessageService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this._authService.currentUser;
        if (!currentUser) {
            this._router.navigate(['/login'], {
                queryParams: { returnUrl: state.url },
            });
            return false;
        } else if (!currentUser.hasRoles([UserRole.Administrator])) {
            this._flashMessageService.showMessage(
                'Access denied',
                `You're not allowed to access this route`
            );
            this._router.navigate(['']);
            return false;
        }
        return true;
    }

    private findCurrentRoute(items: any[], searchedPath): any {
        if (items) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].path === searchedPath) {
                    return items[i].path;
                }
                const foundChild = this.findCurrentRoute(items[i].children, searchedPath);
                if (foundChild) {
                    return foundChild;
                }
            }
        }
    }
}
