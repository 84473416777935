import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import {
    ColorClass,
    FlashMessageService,
    ITasksService,
    TASKS_SERVICE_IMPL,
} from 'shared';

@Component({
    selector: 'app-task-generator',
    templateUrl: './task-generator.component.html',
    styleUrls: ['./task-generator.component.scss'],
})
export class TaskGeneratorComponent implements OnInit {
    public orderCodeForm: UntypedFormGroup;
    public isSubmitted: boolean;
    public isLoading = false;
    public errorMessage: string;
    public isRegisteredSuccessfully: boolean;

    constructor(
        @Inject(TASKS_SERVICE_IMPL) private _tasksService: ITasksService,
        private _formBuilder: UntypedFormBuilder,
        private _route: ActivatedRoute,
        private _flashMessageService: FlashMessageService,
        private _router: Router
    ) {}

    ngOnInit() {
        this.isRegisteredSuccessfully = false;
        this.orderCodeForm = this._formBuilder.group({
            orderCode: ['', Validators.required],
        });
    }

    public get fields() {
        return this.orderCodeForm.controls;
    }

    onSubmit() {
        this.isSubmitted = true;

        if (this.orderCodeForm.invalid) {
            return;
        }

        this.isLoading = true;

        this._tasksService
            .assignDeliveringTask(this.orderCodeForm.value)
            .pipe(first())
            .subscribe(
                (success) => {
                    this._router.navigate(['/my-tasks']);
                },
                (error) => {
                    this._flashMessageService.showMessage(
                        'common.error',
                        error.error,
                        10000,
                        ColorClass.Danger
                    );
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }
}
