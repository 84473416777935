import { Component, Inject, OnInit } from '@angular/core';
import { AUTH_SERVICE_IMPL, IAuthenticationService } from 'shared';
import { Router } from '@angular/router';
import { ColorClass } from 'shared';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    public ColorClass = ColorClass;
    constructor(
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _router: Router,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {}

    ngOnInit(): void {
        if (!this._authService.isAuthenticated()) {
            this._router.navigate(['/login']);
        }
    }

    handleFrontendVersion() {
        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService.newFrontendUrlChoose();
        } else {
            this._iosAndAndroidNatvieFunctionsService.selectFrontendVersion(
                'Válasszon verziót.'
            );
        }
    }
}
