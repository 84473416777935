// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    realtimeServerUrl: 'http://localhost:5001',
    deliveryManagerApiBaseUrl: '',
    deliveryManagerMediaUrl: 'https://delivermanagermedia.blob.core.windows.net/',
    billPhotoFolder: 'billphotos',
    deliveryDateFormat: 'yyyy MMM dd. HH:mm',
    worktimeRegistrationDateFormat: 'YYYY. MM. DD.',
    columnFilterDateFormat: 'YYYY. MMM. DD. - YYYY. MMM. DD.',
    bugsnagApiKey: '9c504abc41e566129a6c652385847f1f',
    supportPhone: '0618088575',
    loggedEnvironments: ['master', 'staging', 'production'],
    validatedTotalPriceThresholdPercentage: 10,
    validatedTotalPriceThresholdAmount: 1000,
    calculatedTotalPriceThresholdPercentage: 200,
    calculatedTotalPriceThresholdAmount: 10000,
    googleTagManagerID: 'GTM-TCPHPJ8',
    googleMapsApiKey: 'AIzaSyBcIFCMxEax202tsFH5wJ-g51JpqNUdYhc',
    supplierWorktimeCalendarOffsetDays: 7,
    supplierWorktimeCalendarVisibleDays: 30,
    backendBaseURL: 'https://localhost:5009/api',
    //backendBaseURL: "http://192.168.0.120:5009/api/",
    appVersion: require('../../../../package.json').version,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
