<div class="row mt-2 px-1">
    <div class="col-12 col-lg-6 col-md-6">
        <p class="m-0 p-0">
            <strong> {{ 'daily-review.cash-today' | translate }}: </strong>
        </p>
        <p class="mb-0 pb-0">
            {{ dailyCashAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) }}
            {{ 'common.currency' | translate }}
        </p>
    </div>
</div>
