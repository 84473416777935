import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerCard } from 'dm-src/models/customer-card';
import {
    BARCODE_FACTORY_SERVICE_IMPL,
    IBarcodeFactoryService,
} from 'dm-src/services/barcode-factory/ibarcode-factory.service';

@Component({
    selector: 'app-customer-card-container',
    templateUrl: './customer-card-container.component.html',
    styleUrls: ['./customer-card-container.component.scss'],
})
export class CustomerCardContainerComponent implements OnInit {
    public orderID: number;
    public customerCard: CustomerCard = new CustomerCard();
    public isInprogress: boolean;
    public hasCardToDisplay: boolean;

    constructor(
        private _route: ActivatedRoute,
        @Inject(BARCODE_FACTORY_SERVICE_IMPL)
        private _barcodeFactoryService: IBarcodeFactoryService
    ) {}

    ngOnInit(): void {
        try {
            this.orderID = parseInt(this._route.snapshot.paramMap.get('{orderId}'));
        } finally {
            this.isInprogress = true;
            this._barcodeFactoryService.getBarcode(this.orderID).subscribe((response) => {
                if (response?.ok && response.status !== 204) {
                    this.hasCardToDisplay = true;
                    this.createImageFromBlob(response?.body);
                    this.customerCard.barcodeData = response?.headers?.get('x-file-name');
                    this.customerCard.providerName =
                        response?.headers?.get('x-provider-name');
                } else {
                    this.hasCardToDisplay = false;
                }
                this.isInprogress = false;
            });
        }
    }

    private createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                this.customerCard.barcode = reader.result;
            },
            false
        );

        if (image) {
            reader.readAsDataURL(image);
        }
    }
}
