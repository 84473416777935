import { AbstractControl, Validators } from '@angular/forms';

export const requiredIf = (predicate: Function) => {
    return (formControl: AbstractControl) => {
        if (!formControl.parent) {
            return null;
        }
        if (predicate()) {
            return Validators.required(formControl);
        }
        return null;
    };
};
