import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NativeTakePhotoResultComponent } from './native-take-photo-result.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [NativeTakePhotoResultComponent],
    exports: [NativeTakePhotoResultComponent],
    imports: [CommonModule, TranslateModule, FormsModule],
})
export class NativeTakePhotoResultModule {}
