import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';

@Component({
    selector: 'app-permission-request-window',
    templateUrl: './permission-request-window.component.html',
    styleUrls: ['./permission-request-window.component.css'],
})
export class PermissionRequestWindowComponent implements OnInit {
    constructor(
        private router: Router,
        private iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {}

    ngOnInit(): void {}

    handleBack(): Promise<boolean> {
        return this.router.navigate(['/']);
    }

    handleAccept(): void {
        this.iosAndAndroidNatvieFunctionsService.getPermissionRequest(
            'android.permission.ACCESS_FINE_LOCATION'
        );
    }
}
