<div class="control-section">
    <div *libCan="Policy.SetOtherSuppliersWorktime" class="row mb-3">
        <div class="col-md-8 col-lg-9"></div>
        <div class="col-sm-12 col-md-4 col-lg-3">
            <app-supplier-chooser
                (onSelect)="myWorktimesService.onSupplierFilterChanged($event)"
            ></app-supplier-chooser>
        </div>
    </div>
    <ejs-schedule
        #scheduler
        [eventSettings]="eventSettings"
        [timeScale]="timeScale"
        [workHours]="workHours"
        (created)="onSchedulerCreated()"
        (eventClick)="onEventClick($event)"
        (resizeStart)="onBeforeEventResize($event)"
        (dragStart)="onBeforeEventMove($event)"
        (popupOpen)="onTimeCellClicked($event)"
        (actionBegin)="onSchedulerAction($event)"
        (actionComplete)="onAfterSchedulerAction($event)"
    >
        <e-views>
            <e-view option="Day">
                <ng-template #eventTemplate let-data>
                    <div class="template-wrap">
                        <div class="time">
                            {{ data.StartTime | amDateFormat : 'HH:mm' }} -
                            {{ data.EndTime | amDateFormat : 'HH:mm' }}
                        </div>
                    </div>
                </ng-template>
            </e-view>
            <e-view option="WorkWeek">
                <ng-template #eventTemplate let-data>
                    <div class="template-wrap">
                        <div class="time">
                            {{ data.StartTime | amDateFormat : 'HH:mm' }} -
                            {{ data.EndTime | amDateFormat : 'HH:mm' }}
                        </div>
                    </div>
                </ng-template>
            </e-view>
            <e-view option="Week">
                <ng-template #eventTemplate let-data>
                    <div class="template-wrap">
                        <div class="time">
                            {{ data.StartTime | amDateFormat : 'HH:mm' }} -
                            {{ data.EndTime | amDateFormat : 'HH:mm' }}
                        </div>
                    </div>
                </ng-template>
            </e-view>
            <e-view option="Month">
                <ng-template #eventTemplate let-data>
                    <div class="template-wrap">
                        <div class="time">
                            {{ data.StartTime | amDateFormat : 'HH:mm' }} -
                            {{ data.EndTime | amDateFormat : 'HH:mm' }}
                        </div>
                    </div>
                </ng-template>
            </e-view>
            <e-view option="Agenda">
                <ng-template #eventTemplate let-data>
                    <div class="template-wrap">
                        <div class="time">
                            {{ data.StartTime | amDateFormat : 'HH:mm' }} -
                            {{ data.EndTime | amDateFormat : 'HH:mm' }}
                        </div>
                    </div>
                </ng-template>
            </e-view>
        </e-views>
        <ng-template #editorTemplate>
            <app-worktime-entry-editor></app-worktime-entry-editor>
        </ng-template>
    </ejs-schedule>
</div>
