import { ICollectingService } from 'dm-src/services/collecting/icollecting.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CloseCollectingTask } from 'dm-src/dtos/close-collecting-task';
import { CollectingDoneResponse } from 'shared';
import { CollectingPayment } from 'dm-src/dtos/collecting-payment';
import { CollectingPaymentResponse } from 'dm-src/dtos/collecting-payment-response';
import { CollectingOrderItems } from 'dm-src/models/collecting-order-items';
import { SetDeliveryTaskFailed } from 'shared';
import { CheckVirtualPaymentResponse } from 'dm-src/dtos/check-virtual-payment-response';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from 'shared';

@Injectable()
export class CollectingService implements ICollectingService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getOrderItems(
        taskID: string,
        orderID: Number
    ): Observable<HttpResponse<CollectingOrderItems>> {
        return this._apiClient.getWithResponse<CollectingOrderItems>(
            `collecting/orderitems/${taskID}/${orderID}`
        );
    }

    public startPayment(
        requestBody: CollectingPayment
    ): Observable<HttpResponse<CollectingPaymentResponse>> {
        return this._apiClient.postWithResponse<CollectingPaymentResponse>(
            'collecting/startPayment',
            requestBody
        );
    }

    public releaseReservedPayment(
        requestBody: CollectingPayment
    ): Observable<HttpResponse<CheckVirtualPaymentResponse>> {
        return this._apiClient.postWithResponse<CheckVirtualPaymentResponse>(
            'collecting/releaseReservedPayment',
            requestBody
        );
    }

    public finishPayment(
        requestBody: CloseCollectingTask
    ): Observable<HttpResponse<CollectingDoneResponse>> {
        return this._apiClient.postWithResponse<CollectingDoneResponse>(
            'collecting/finishPayment',
            requestBody
        );
    }

    public close(
        requestBody: CloseCollectingTask
    ): Observable<HttpResponse<CollectingDoneResponse>> {
        return this._apiClient.postWithResponse<CollectingDoneResponse>(
            'collecting/done',
            requestBody
        );
    }

    public failed(requestBody: SetDeliveryTaskFailed): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>('collecting/failed', requestBody);
    }
}
