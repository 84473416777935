import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { OfflineProductsService } from 'dm-src/app/modules/collecting/offline-products.service';
import {
    AnimationHelper,
    ModalService,
    OfflineProduct,
    OfflineProductMapperService,
    OrderItem,
} from 'shared';
import { CollectingStatesService } from 'dm-src/app/modules/collecting/collecting-states.service';
import { ProductIdentificationSource } from 'dm-src/types/product-identification-source';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-found-product-chooser-modal',
    templateUrl: './found-product-chooser-modal.component.html',
    styleUrls: ['./found-product-chooser-modal.component.scss'],
})
export class FoundProductChooserModalComponent implements OnInit, OnDestroy {
    private _destroy$: ReplaySubject<boolean>;
    private _selectedOrderItem: OrderItem = null;
    private _selectedItemIndex = 0;
    public isOfflineProductIdentification = false;

    public orderItems: OrderItem[];
    public offlineProducts: OfflineProduct[];
    public identifiedOrderItems: OrderItem[];

    constructor(
        private _states: CollectingStatesService,
        private _offlineProdService: OfflineProductsService,
        private _mapper: OfflineProductMapperService,
        private _translateService: TranslateService,
        private _modalService: ModalService
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    get isItemToPick(): boolean {
        return (
            this._states.productIdentificationSource ===
            ProductIdentificationSource.ToPick
        );
    }

    get foundSingleItem(): boolean {
        return this.orderItems?.length === 1;
    }

    get isAddNewItemVisible(): boolean {
        return this.isItemToPick && !this.isOfflineProductIdentification;
    }

    public get hints(): string {
        let message;
        if (this.isOfflineProductIdentification) {
            message = 'collecting.found-product-chooser-text';
        } else if (this.foundSingleItem) {
            message = 'collecting.related-single-order-item-approval-text';
        } else {
            message = 'collecting.related-order-item-chooser-text';
        }
        return this._translateService.instant(message);
    }

    ngOnInit(): void {
        this._offlineProdService.foundOfflineProducts
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((offlineProducts) => {
                this._offlineProdService.selectedOfflineProduct = null;
                this.offlineProducts = offlineProducts;
                this.isOfflineProductIdentification = offlineProducts.length > 1;
                if (!this.isOfflineProductIdentification) {
                    this.orderItems = this._offlineProdService.foundOrderItems;
                    this._selectedOrderItem = this._mapper.mapOfflineProductToOrderItem(
                        offlineProducts[0],
                        null,
                        null
                    );
                } else {
                    this.orderItems = offlineProducts.map((offlineProduct) =>
                        this._mapper.mapOfflineProductToOrderItem(
                            offlineProduct,
                            null,
                            null
                        )
                    );
                }
            });
    }

    public onProductSelected(itemIndex: number): void {
        if (this.isOfflineProductIdentification) {
            AnimationHelper.scrollToTop();
            this._selectedOrderItem = this.orderItems[itemIndex];
            this._selectedItemIndex = itemIndex;
            this._offlineProdService.selectedOfflineProduct =
                this.offlineProducts[itemIndex];
            this._offlineProdService.onOfflineProductSelected();
            const foundOrderItems = this._offlineProdService.foundOrderItems;
            if (foundOrderItems.length > 0) {
                this.orderItems = foundOrderItems;
                this.isOfflineProductIdentification = false;
            } else {
                this._offlineProdService.onFoundProductAssigned(null);
            }
        } else {
            if (this._offlineProdService.selectedOfflineProduct === null) {
                this._offlineProdService.selectedOfflineProduct =
                    this.offlineProducts[this._selectedItemIndex];
            }
            const offlineProd = this._offlineProdService.selectedOfflineProduct;
            const orderItem = this.orderItems[itemIndex];
            orderItem.isBulk = offlineProd.isBulk;
            orderItem.isWerwProduct = offlineProd.isWerwProduct;

            if (offlineProd.isWerwProduct) {
                orderItem.quantity = offlineProd.quantity;
            }

            this._offlineProdService.onFoundProductAssigned(orderItem);
        }
    }

    public onNewOrderItemClicked(): void {
        if (this._offlineProdService.selectedOfflineProduct === null) {
            this._offlineProdService.selectedOfflineProduct = this.offlineProducts[0];
        }
        this._offlineProdService.collectNewOrderItem();
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.complete();
    }

    public cancel(): void {
        this._modalService.setModalVisibility(false, 'found-product-chooser-modal');
    }

    public resetFoundProduct(): void {
        this._offlineProdService.selectedOfflineProduct = null;
        this._offlineProdService.resetFoundOrderItems();
    }
}
