<ng-template #taskFailedModal let-modal>
    <div class="modal-header modal-fullscreen bg-danger text-white">
        <h4 class="modal-title" id="modal-basic-title">
            {{ 'my-tasks.task-failed' | translate }}
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ 'my-tasks.only-close-if-returned' | translate }}</p>
        <p>{{ 'my-tasks.task-failed-reason-text' | translate }}</p>
        <form [formGroup]="taskFailureForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <textarea
                    class="form-control"
                    formControlName="reason"
                    id="reason"
                    placeholder="{{ 'my-tasks.reason-of-failure' | translate }}"
                    required
                    autofocus
                    [ngClass]="{ 'is-invalid': isSubmitted && fields.reason.errors }"
                >
                </textarea>
                <div *ngIf="isSubmitted && fields.reason.errors" class="invalid-feedback">
                    <div *ngIf="fields.reason.errors.required">
                        {{ 'my-tasks.task-failed-reason-required' | translate }}
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="btn-group">
                    <button type="submit" class="form-control btn btn-danger">
                        {{ 'my-tasks.task-failed' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
