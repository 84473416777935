<div
    *ngIf="taskGroupItem.taskType === TaskGroupItemType.CollectingTask"
    collecting-task-item
    [task]="taskGroupItem.task"
    class="col-12 col-sm-6 col-lg-4 mb-md-2 m-auto"
></div>
<div
    *ngIf="
        taskGroupItem.taskType === TaskGroupItemType.DeliveringTask &&
        (!IsContainer || IsExtraDelivery)
    "
    delivering-task-item
    [task]="taskGroupItem.task"
    class="col-12 col-sm-6 col-lg-4 mb-md-2 m-auto"
></div>
<div
    *ngIf="
        taskGroupItem.taskType === TaskGroupItemType.DeliveringTask &&
        IsContainer &&
        !IsExtraDelivery
    "
    delivery-container-placing-task-item
    [task]="taskGroupItem.task"
    class="col-12 col-sm-6 col-lg-4 mb-md-2 m-auto"
></div>
<div
    *ngIf="taskGroupItem.taskType === TaskGroupItemType.RefundingTask"
    refunding-task-item
    [task]="taskGroupItem.task"
    class="col-12 col-sm-6 col-lg-4 mb-md-2 m-auto"
></div>
<div
    *ngIf="taskGroupItem.taskType === TaskGroupItemType.ContainerPlacingTask"
    container-placing-task-item
    [task]="taskGroupItem.task"
    class="col-12 col-sm-6 col-lg-4 mb-md-2 m-auto"
></div>
