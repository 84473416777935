<lib-modal
    [title]="'collecting.product-identify-title'"
    [componentID]="'found-product-chooser-modal'"
    (onClose)="resetFoundProduct()"
>
    <p class="font-weight-bold">{{ hints }}</p>
    <app-found-offline-product
        *ngFor="let orderItem of orderItems; let idx = index"
        (onProductSelected)="this.onProductSelected($event)"
        [orderItem]="orderItem"
        [canChoose]="!foundSingleItem"
        [offlineProductIndex]="idx"
    ></app-found-offline-product>
    <button
        *ngIf="isAddNewItemVisible"
        class="btn btn-warning btn-lg btn-block mt-2"
        (click)="onNewOrderItemClicked()"
    >
        <span class="font-weight-normal text-smaller">{{
            'collecting.new-order-item' | translate
        }}</span>
    </button>
    <button
        *ngIf="foundSingleItem"
        class="btn btn-success btn-lg btn-block mt-2 cart"
        (click)="onProductSelected(0)"
    >
        <span class="font-weight-bold text-smaller">{{
            'collecting.add-cart' | translate
        }}</span>
    </button>
    <lib-btn-close></lib-btn-close>
</lib-modal>
