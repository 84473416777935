import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbAlertModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { BtnActionComponent } from './btn-action/btn-action.component';
import { BtnActionContainerComponent } from './btn-action-container/btn-action-container.component';
import { TaskDetailsItemComponent } from './task-details-item/task-details-item.component';
import { StateBadgeComponent } from 'dm-src/app/modules/shared/state-badge/state-badge.component';
import { TaskFailedModalComponent } from 'dm-src/app/modules/shared/task-failed-modal/task-failed-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentStateBadgeComponent } from 'dm-src/app/modules/shared/payment-state-badge/payment-state-badge.component';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierChooserComponent } from 'dm-src/app/modules/shared/supplier-chooser/supplier-chooser.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { MomentModule } from 'ngx-moment';
import { OrderItemsListComponent } from 'dm-src/app/modules/shared/list/order-items-list/order-items-list.component';
import { SharedModule } from 'shared';
import { OrderItemComponent } from 'dm-src/app/modules/shared/list/order-items-list/order-item/order-item.component';
import { OrderReplacementItemComponent } from 'dm-src/app/modules/shared/list/order-items-list/order-replacement-item/order-replacement-item.component';
import { OrderReturnControlComponent } from 'dm-src/app/modules/shared/list/order-items-list/order-return-control/order-return-control.component';
import { OrderMissingItemComponent } from 'dm-src/app/modules/shared/list/order-items-list/order-missing-item/order-missing-item.component';
import { PreloaderModule } from 'dm-src/app/modules/preloader/preloader.module';
import { SchedulerBaseComponent } from './scheduler-base/scheduler-base.component';
import { OrderRemovedItemComponent } from './list/order-items-list/order-removed-item/order-removed-item.component';
import { CustomerContactDataComponent } from './customer-contact-data/customer-contact-data.component';
import { OrderDataComponent } from './order-data/order-data.component';
import { CustomerNotesComponent } from './order-data/customer-notes/customer-notes.component';
import { DeliveryFeeComponent } from './order-data/delivery-fee/delivery-fee.component';
import { OrderPaymentDataComponent } from './order-payment-data/order-payment-data.component';
import { OrderDeliveryDataComponent } from './order-delivery-data/order-delivery-data.component';
import { OrderCorrectionComponent } from './order-correction/order-correction.component';
import { OrderInvoiceComponent } from './order-invoice/order-invoice.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatSelectModule } from '@angular/material/select';
import { SupportCommentComponent } from './order-data/support-comment/support-comment.component';
import { MultiOrderInfoComponent } from './order-data/multi-order-info/multi-order-info.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        BtnActionComponent,
        BtnActionContainerComponent,
        StateBadgeComponent,
        PaymentStateBadgeComponent,
        TaskDetailsItemComponent,
        TaskFailedModalComponent,
        SupplierChooserComponent,
        OrderHistoryComponent,
        OrderItemsListComponent,
        CustomerContactDataComponent,
        OrderDataComponent,
        OrderCorrectionComponent,
        OrderInvoiceComponent,
        OrderItemComponent,
        OrderReplacementItemComponent,
        OrderDeliveryDataComponent,
        OrderPaymentDataComponent,
        OrderMissingItemComponent,
        OrderReturnControlComponent,
        CustomerNotesComponent,
        DeliveryFeeComponent,
        SchedulerBaseComponent,
        OrderRemovedItemComponent,
        SupportCommentComponent,
        MultiOrderInfoComponent,
    ],
    imports: [
        CommonModule,
        NgbNavModule,
        MomentModule,
        NgbAlertModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        NgxSelectModule,
        SharedModule,
        PreloaderModule,
        NgxIntlTelInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        BtnActionContainerComponent,
        BtnActionComponent,
        StateBadgeComponent,
        PaymentStateBadgeComponent,
        TaskDetailsItemComponent,
        OrderCorrectionComponent,
        OrderInvoiceComponent,
        TaskFailedModalComponent,
        SupplierChooserComponent,
        OrderHistoryComponent,
        OrderItemsListComponent,
        OrderDeliveryDataComponent,
        CustomerContactDataComponent,
        OrderPaymentDataComponent,
        OrderDataComponent,
        CustomerNotesComponent,
        DeliveryFeeComponent,
        SchedulerBaseComponent,
    ],
})
export class AppSharedModule {}
