<lib-modal
    [title]="'collecting.invalid-offline-product-title'"
    [componentID]="'invalid-offline-product-modal'"
>
    <p class="text-danger">
        {{ 'collecting.scanned-product-not-list' | translate }}
    </p>
    <p class="font-weight-bold">
        {{ 'collecting.scanned-product' | translate }}
    </p>
    <div class="product-details">
        <table>
            <tr>
                {{
                    offlineProductsService.selectedOfflineProduct?.productName
                }}
                {{
                    'collecting.product' | translate
                }}
            </tr>
            <tr>
                {{
                    'collecting.unit-price' | translate
                }}:
                {{
                    offlineProductsService.selectedOfflineProduct?.markupPrice
                }}
                /
                {{
                    'collecting.' + offlineProductsService.selectedOfflineProduct?.unit
                        | translate
                }}
            </tr>
            <tr>
                {{
                    'common.barcode' | translate
                }}:
                {{
                    (offlineProductsService.selectedOfflineProduct?.gtinList)[0]
                }}
            </tr>
            <tr>
                {{
                    'collecting.unit' | translate
                }}:
                {{
                    'collecting.' + offlineProductsService.selectedOfflineProduct?.unit
                        | translate
                }}
            </tr>
        </table>
    </div>

    <button class="btn btn-success btn-lg btn-block mt-2" (click)="collect()">
        <span class="font-weight-bold text-smaller">{{
            'collecting.add-cart' | translate
        }}</span>
    </button>
    <lib-btn-close></lib-btn-close>
</lib-modal>
