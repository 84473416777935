import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'lib-boolean-filter',
    templateUrl: './boolean-filter.component.html',
    styleUrls: ['./boolean-filter.component.scss'],
})
export class BooleanFilterComponent {
    public elementID = Math.floor(Math.random() * Math.floor(10000));
    @Input() public value: boolean = null;
    @Output() public onFilterChanged = new EventEmitter<boolean>();
    @Output() public onFilterCleared = new EventEmitter<void>();

    constructor() {}

    public onFilterChange(value: boolean): void {
        this.value = value;
        this.onFilterChanged.emit(value);
    }

    public onFilterClear(): void {
        this.value = null;
        this.onFilterCleared.emit();
    }
}
