import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SumupCallbackInputComponent } from './sumup-callback-input.component';

@NgModule({
    declarations: [SumupCallbackInputComponent],
    exports: [SumupCallbackInputComponent],
    imports: [CommonModule, TranslateModule, FormsModule],
})
export class SumupCallbackInputModule {}
