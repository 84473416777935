import { Component, Inject, Input } from '@angular/core';
import { ProgressLineService } from '../../modules/progress-line/progress-line.service';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../../services/authentication/iauthentication.service';

@Component({
    selector: 'lib-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css'],
})
export class NavMenuComponent {
    @Input() menuItems: any[];
    @Input() version: string = '';
    public isExpanded = false;

    constructor(
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _progressLineService: ProgressLineService
    ) {}

    get versionString() {
        if (this.version) {
            return `v${this.version}`;
        } else return '';
    }

    public isVisible(item: any): boolean {
        const currentUser = this._authService.currentUser;
        if (item.visibleFor !== undefined && currentUser) {
            return currentUser.hasRoles(item.visibleFor);
        } else if (item.isAuthVisible !== undefined) {
            return (
                (item.isAuthVisible && currentUser !== null) ||
                (!item.isAuthVisible && currentUser === null)
            );
        }
    }

    public hasChildren(item: any): boolean {
        return item.children !== undefined;
    }

    public collapse() {
        this.isExpanded = false;
    }

    public toggle(item: any = null) {
        this.isExpanded = !this.isExpanded;
        if (
            item != null &&
            item.hasInitialProgressBar !== undefined &&
            item.hasInitialProgressBar
        ) {
            this._progressLineService.showProgressLine();
        }
    }
}
