<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    {{ 'daily-review.title' | translate }}
                </div>
                <div class="card-body">
                    <nav ngbNav #nav="ngbNav" class="nav-tabs">
                        <ng-container ngbNavItem *appHasRoles="['picker', 'deliverer']"
                            ><a ngbNavLink>{{
                                'daily-review.financial-information' | translate
                            }}</a>
                            <ng-template ngbNavContent>
                                <app-financial-information></app-financial-information>
                            </ng-template>
                        </ng-container>
                    </nav>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>
</div>
