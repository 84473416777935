import { QueryOperator } from './query-operator';
import { ISelectOption } from './i-select-option';
import { LogicalOperator } from './logical-operator';

export class TableColumn {
    public name: string[];
    public label: string;
    public width: number = 0;
    public filterOperator: QueryOperator = QueryOperator.Equals;
    public logicalOperator: LogicalOperator = LogicalOperator.And;
    public filterOptions: ISelectOption[] = [];
    public filterType: TableColumnFilterType = TableColumnFilterType.Text;
    public wordPerField = false;
    public isVisible = true;
    public model: ISelectOption[] = [];

    constructor(init?: Partial<TableColumn>) {
        Object.assign(this, init);
    }
}

export enum TableColumnFilterType {
    Text,
    Select,
    Multiselect,
    DatePicker,
    DateTimePicker,
    Boolean,
}
