import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierResourcesComponent } from './supplier-resources.component';
import { ResourceEntryEditorComponent } from 'dm-src/app/modules/supplier-resources/resource-entry-editor/resource-entry-editor.component';
import { WorktimeSettingsComponent } from './worktime-settings/worktime-settings.component';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { SupplierRegionChooserModule } from 'dm-src/app/modules/common/supplier-region-chooser/supplier-region-chooser.module';
import { LibCommonModule } from 'shared';

@NgModule({
    declarations: [
        SupplierResourcesComponent,
        ResourceEntryEditorComponent,
        WorktimeSettingsComponent,
    ],
    imports: [
        CommonModule,
        ScheduleAllModule,
        TranslateModule,
        AppSharedModule,
        SupplierRegionChooserModule,
        LibCommonModule,
    ],
})
export class SupplierResourcesModule {}
