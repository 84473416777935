import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { OfflineProduct, OfflineProductMapperService, OrderItem } from 'shared';

@Component({
    selector: 'app-found-offline-product',
    templateUrl: './found-offline-product.component.html',
    styleUrls: ['./found-offline-product.component.scss'],
})
export class FoundOfflineProductComponent {
    @Output() public onProductSelected = new EventEmitter<number>();
    @Input() public canChoose = true;
    @Input() public orderItem: OrderItem;
    @Input() public offlineProductIndex: number;

    public onProductClick(): void {
        this.onProductSelected.emit(this.offlineProductIndex);
    }
}
