<div id="order-items-container">
    <div
        *ngIf="collectingItemsService.states.existPreferenceSettings"
        class="text-center"
    >
        <span
            class="alert d-flex justify-content-center align-items-center alert-dark font-weight-bold"
        >
            {{ 'common.customer-name' | translate }}:
            {{ customerName() }}
        </span>
        <span *ngIf="collectingItemsService.states.usePaperBag; else multiUseBag">
            {{ 'collecting.ask-for-paper-bag' | translate }} <br />
        </span>
        <ng-template #multiUseBag>
            <span *ngIf="collectingItemsService.states.usePaperBag === false">
                {{ 'collecting.no-ask-for-paper-bag' | translate }} <br
            /></span>
        </ng-template>

        <span *ngIf="collectingItemsService.states.onlineConsultation">
            {{ 'collecting.online-consultation' | translate }} <br />
        </span>
        <span *ngIf="collectingItemsService.states.noAskForReplacementItems">
            {{ 'collecting.no-replacement-item' | translate }} <br />
        </span>
        <span *ngIf="collectingItemsService.states.acceptsEverything">
            {{ 'collecting.accepts-all-repl-items' | translate }}<br />
        </span>
        <div *ngIf="collectingItemsService.states.showPhoneButton" class="text-center">
            <span>
                {{ 'collecting.phone-consultation' | translate }}
            </span>
            <a
                href="tel:{{ collectingItemsService.states.customerPhone }}"
                class="button"
            >
                <u>
                    {{ collectingItemsService.states.customerPhone }}
                </u>
            </a>
        </div>
    </div>
    <app-order-items-container
        [classes]="'text-white bg-info'"
        [name]="'items-to-pick'"
        [icon]="'fa-search'"
        [parent]="'order-items-container'"
        [orderItems]="collectingItemsService.itemsToPick"
        [title]="'collecting.items-to-pick' | translate"
        [orderRemovedItemReasons]="collectingItemsService.orderRemovedItemReasons"
    >
    </app-order-items-container>
    <app-order-items-container
        [classes]="'text-white bg-success'"
        [name]="'collected-items'"
        [icon]="'fa-cart-arrow-down'"
        [parent]="'order-items-container'"
        [orderItems]="collectingItemsService.collectedItems"
        [currentTotal]="collectingItemsService.collectedTotalPrice"
        [sumDeposit]="collectingItemsService.sumDeposit"
        [title]="'collecting.collected-items' | translate"
        [orderRemovedItemReasons]="collectingItemsService.orderRemovedItemReasons"
    >
    </app-order-items-container>
    <app-order-items-container
        [classes]="'text-white bg-warning'"
        [name]="'replaced-items'"
        [icon]="'fa-refresh'"
        [parent]="'order-items-container'"
        [orderItems]="collectingItemsService.replacedItems"
        [title]="'collecting.replaced-items' | translate"
        [orderRemovedItemReasons]="collectingItemsService.orderRemovedItemReasons"
    >
    </app-order-items-container>
    <app-order-items-container
        [classes]="'text-white bg-danger'"
        [name]="'missing-items'"
        [icon]="'fa-exclamation-triangle'"
        [parent]="'order-items-container'"
        [orderItems]="collectingItemsService.notFoundItems"
        [title]="'collecting.missing-items' | translate"
        [orderRemovedItemReasons]="collectingItemsService.orderRemovedItemReasons"
    >
    </app-order-items-container>
    <button
        class="btn btn-outline-primary w-100"
        *ngIf="customerChat"
        (click)="openChat()"
    >
        Customer Chat
    </button>
    <app-customer-card-container></app-customer-card-container>
    <app-markup-collecting-buttons
        *ngIf="states.hasMarkupPayment; else collectingButtons"
        [timeoutFinishedEvents]="collectingItemsService.timeoutFinishedSubject"
    >
    </app-markup-collecting-buttons>
    <ng-template #collectingButtons>
        <app-collecting-buttons></app-collecting-buttons>
    </ng-template>
    <button
        (click)="taskFailedModalService.setFailModalVisible(true)"
        [disabled]="states.isCollectingForbidden"
        class="btn btn-block btn-outline-danger task-action-btn w-75 mx-auto my-3"
    >
        <i class="fa fa-ban"></i>{{ 'collecting.task-failed' | translate }}
    </button>
</div>
<app-actions-toolbar></app-actions-toolbar>
<app-collecting-modals></app-collecting-modals>
