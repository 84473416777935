import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ServiceBaseService } from '../service-base.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    appInsights: ApplicationInsights;
    constructor() {
        super();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: ServiceBaseService.getAppInsightKey(),
                enableAutoRouteTracking: true, // Option to log all route changes
            },
        });
        this.appInsights.loadAppInsights();
    }

    handleError(error: Error) {
        console.log('%cERROR: ', 'color: #1c87c9;', error);

        this.appInsights.trackException({
            exception: error,
        });
    }
}
