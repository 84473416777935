import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ISelectOption } from 'shared';
import { NativeTakePhotoService } from 'dm-src/services/native-take-photo/native-take-photo.service';

export const NATIVE_TAKE_PHOTO = new InjectionToken<IproviderNativeTakePhotoService>(
    'providerNativeTakePhotoService'
);

export interface IproviderNativeTakePhotoService {
    getProviderNativeTakePhotoServiceOptions(): Observable<HttpResponse<ISelectOption[]>>;
}
