import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
    AppSettings,
    ColorClass,
    FlashMessageService,
    IRealtimeClientService,
    ModalService,
    REALTIME_CLIENT_IMPL,
} from 'shared';
import {
    IQRFactoryService,
    QR_FACTORY_SERVICE_IMPL,
} from 'dm-src/services/qr-factory/iqr-factory.service';
import { CollectingStatesService } from '../../collecting-states.service';
import { CollectingSharedService } from '../../collecting-shared.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-checkout-qr-modal',
    templateUrl: './checkout-qr-modal.component.html',
    styleUrls: ['./checkout-qr-modal.component.scss'],
})
export class CheckoutQrModalComponent implements OnInit, OnDestroy {
    @Input() public isInspectingInProgress: boolean;
    public ColorClass = ColorClass;
    public isInProgress = false;
    public qrImage: string | ArrayBuffer;

    public markupHub = AppSettings.get('realtimeUrl') + '/markuphub';

    public get title(): string {
        const message = this.isInspectingInProgress
            ? 'collecting.markup-inspection-in-progress-title'
            : 'collecting.qr-code-for-checkout-title';
        return this._translateService.instant(message);
    }

    public get color(): ColorClass {
        return this.isInspectingInProgress ? ColorClass.Danger : ColorClass.Success;
    }

    public get text(): string {
        const message = this.isInspectingInProgress
            ? 'collecting.markup-inspection-in-progress-text'
            : 'collecting.qr-code-for-checkout-text';
        return this._translateService.instant(message);
    }

    constructor(
        @Inject(QR_FACTORY_SERVICE_IMPL)
        private _qrFactoryService: IQRFactoryService,
        @Inject(REALTIME_CLIENT_IMPL)
        private _realtimeClient: IRealtimeClientService,
        private _states: CollectingStatesService,
        private _flashMessageService: FlashMessageService,
        private _sharedService: CollectingSharedService,
        private _modalService: ModalService,
        private _router: Router,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.isInProgress = true;
        this._modalService.isModalVisible.subscribe((isVisible) => {
            if (!isVisible || this._modalService.componentID !== 'checkout-qr-modal') {
                return false;
            }
            this._qrFactoryService
                .getForCheckout(this._states.orderQrCode, true)
                .subscribe(
                    (response) => {
                        this.isInProgress = false;
                        if (response.status === 200) {
                            this.createImageFromBlob(response.body);
                        }
                    },
                    (error) => {
                        this.isInProgress = false;
                        if (error.status === 500) {
                            this.close();
                            this._modalService.setModalVisibility(
                                true,
                                'invalid-markup-total-price'
                            );
                        } else {
                            this._flashMessageService.showStoredMessage(
                                'qr-code-message'
                            );
                        }
                    }
                );
        });
        try {
          this._realtimeClient.initHubConnection(
            'markuphub',
            AppSettings.get('realtimeUrl')
          );
          this._realtimeClient.connect(this.markupHub).then(() => {
            this._realtimeClient.on('OnMarkupInspectingStarted', this.markupHub, () => {
              this.isInspectingInProgress = true;
              this._states.isInspectingInProgress = true;
              console.log('Client received OnMarkupInspectingStarted.');
            });
            this._realtimeClient.on('OnCheckoutApproved', this.markupHub, () => {
              this._sharedService.redirectToMyTasks();
              this.close();
            });
            this._realtimeClient.on('OnMarkupPaymentFailed', this.markupHub, () => {
              this.close();
              this._modalService.setModalVisibility(true, 'markup-payment-failed');
            });
          });
        } catch (e) {
          console.log("ERROR", e);
        }

    }

    private createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                this.qrImage = reader.result;
            },
            false
        );

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    navigateToMyTasks(): void {
        this._modalService.setModalVisibility(false, 'checkout-qr-modal');
        this._router.navigate(['/my-tasks']);
    }

    ngOnDestroy(): void {
        this._realtimeClient.disconnect(this.markupHub);
    }

    private close(): void {
        this._modalService.setModalVisibility(false, 'checkout-qr-modal');
    }
}
