import { DeliveryTaskType } from '../types/delivery-task-type';
import { DeliveryTaskState } from '../types/delivery-task-state';

export class DeliveryTaskInfo {
    public userID: string;
    public username: string;
    public orderID: number;
    public taskName: string;
    public notes: string;
    public taskType: DeliveryTaskType;
    public taskState: DeliveryTaskState;
}
