import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from 'shared';
import { SupplierTrackingFilter } from '../../../dtos/supplier-tracking-filter';
import { SupplierTrack } from '../../../dtos/supplier-track';
import { SupplierMarker } from '../../../dtos/supplier-marker';
import { ISelectOption } from '../../../../../shared/src/public-api';
import { SupplierFilterDTO } from '../../../dtos/supplier-filter';
import { SupplierWorker } from '../../../dtos/supplier-worker';
import { SupplierOrder, SupplierOrderHistory } from '../../../dtos/supplier-order';
import { Supplier } from '../../../models/supplier';

@Injectable({
    providedIn: 'root',
})
export class SupplierTrackingService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getWorkers(
        requestFilter: SupplierTrackingFilter
    ): Observable<HttpResponse<SupplierMarker[]>> {
        return this._apiClient.postWithResponse<SupplierWorker[]>(
            `suppliertracking/get-workers`,
            requestFilter
        );
    }

    public getWorkerDetails(
        userID: string,
        date: string
    ): Observable<HttpResponse<SupplierWorker>> {
        return this._apiClient.getWithResponse<SupplierWorker>(
            //`suppliertracking/get-worker-details/${supplierID}/${date.toISOString().split('T')[0]}`
            `suppliertracking/get-worker-details/${userID}/${date}`
        );
    }

    public getOrders(
        regionId: string,
        date: string
    ): Observable<HttpResponse<SupplierOrder[]>> {
        return this._apiClient.getWithResponse<SupplierOrder[]>(
            `suppliertracking/get-orders/${regionId}/${date}`
        );
    }

    public getOrderHistory(
        orderID: number
    ): Observable<HttpResponse<SupplierOrderHistory[]>> {
        return this._apiClient.getWithResponse<SupplierOrderHistory[]>(
            `suppliertracking/get-order-history/${orderID}`
        );
    }

    /*
  public getTracks(
    requestFilter: SupplierTrackingFilter
  ): Observable<HttpResponse<SupplierTrack[]>> {
    return this._apiClient.postWithResponse<SupplierTrack[]>(
      `suppliertracking/get-tracks`,
      requestFilter
    );
  }

  public getSuppliers(
    requestFilter: SupplierFilterDTO
  ): Observable<HttpResponse<ISelectOption[]>> {
    return this._apiClient.postWithResponse<ISelectOption[]>(
      `suppliers/get-supplier-options`,
      requestFilter
    );
  }
  */
}
