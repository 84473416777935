import { Injectable } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { IImageCompressionService } from './iimage-compression.service';

@Injectable()
export class ImageCompressionService implements IImageCompressionService {
    public sizeOfOriginalImage: number;
    public sizeOfCompressedImage: number;

    constructor(private _ngxImageCompressService: NgxImageCompressService) {}

    public compressImage(
        image: any,
        originalResolution = false,
        quality = 50
    ): Promise<string> {
        var orientation = -1;

        this.sizeOfOriginalImage =
            this._ngxImageCompressService.byteCount(image) / (1024 * 1024);

        // console.warn(`Size in megabytes is now: ${this.sizeOfOriginalImage}`);

        return this._ngxImageCompressService
            .compressFile(image, orientation, 50, quality)
            .then((result) => {
                this.sizeOfCompressedImage =
                    this._ngxImageCompressService.byteCount(result) / (1024 * 1024);

                // console.warn(`Size in megabytes after compression: ${this.sizeOfCompressedImage}`);

                return result;
            });
    }
}
