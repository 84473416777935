export enum Policy {
    EditSuppliers,
    ResetSupplierAccounts,
    ApproveMarkupCheckout,
    AccessUserManager,
    ManageUsers,
    AccessProductManager,
    InspectMarkupOrder,
    AccessOrderReturn,
    StartOrderReturn,
    ApproveOrderReturn,
    SetOtherSuppliersWorktime,
    AccessTranslations,
}
