import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ISelectOption } from 'shared';
import {
    IProviderShopService,
    PROVIDERSHOP_SERVICE_IMPL,
} from '../../../../services/providershop/iprovidershop-service';

@Component({
    selector: 'providershop-chooser',
    templateUrl: './providershop-chooser.component.html',
    styleUrls: ['./providershop-chooser.component.scss'],
})
export class ProviderShopChooserComponent implements OnInit {
    @Output() public onSelect = new EventEmitter<string>();
    @Input() selectedProviderShopID: string;
    public providerShops: ISelectOption[];

    constructor(
        @Inject(PROVIDERSHOP_SERVICE_IMPL)
        private _providerShopService: IProviderShopService
    ) {}

    ngOnInit(): void {
        this._providerShopService.getProviderShopOptions().subscribe((response) => {
            if (response.status === 200) {
                this.providerShops = response.body;
            }
        });
    }

    public onChange(supplierID: string): void {
        this.onSelect.emit(supplierID);
    }

    public getDefaultValue() {
        return [this.selectedProviderShopID];
    }
}
