<!--style="width:640px;height:480px;-->

<div class="video-container">
    <video autoplay webkit-playsinline="true" playsinline="true"></video>

    <div class="app__overlay" id="app__overlay">
        <div class="app__overlay-frame"></div>
        <!-- Scanner animation -->
        <div class="custom-scanner"></div>
        <div class="app__help-text"></div>
    </div>
</div>

<div class="bl_left"></div>
<div class="bl_right"></div>
<div class="bl_top"></div>
<div class="bl_bottom"></div>

<!--<canvas id="canv" style="position:absolute;top:1024px"></canvas>-->

<canvas id="canv" style="width: 5px; height: 5px; display: none"></canvas>
