import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { skip, takeUntil } from 'rxjs/operators';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { environment } from 'dm-src/environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CollectingStatesService } from '../../collecting-states.service';

@Component({
    selector: 'app-collecting-done-failed-modal',
    templateUrl: './collecting-done-failed-modal.component.html',
    styleUrls: ['./collecting-done-failed-modal.component.scss'],
})
export class CollectingDoneFailedModalComponent implements OnInit, OnDestroy {
    public event: string;
    public supportPhone: string;
    @ViewChild('collectingDoneFailedModal', { static: false })
    private _collectingDoneFailedModal: TemplateRef<any>;
    private _destroy$: ReplaySubject<boolean>;

    constructor(
        private _orderItemsService: CollectingItemsService,
        private _states: CollectingStatesService,
        private _modalService: NgbModal,
        private _router: Router,
        private _translateService: TranslateService
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit() {
        this.supportPhone = environment.supportPhone;
        this.event = !this._states.isPaymentReserved
            ? this._translateService.instant('common.payment')
            : this._translateService.instant('common.invoicing');
        this._states.isCollectingDoneFailedModalVisible
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isVisible) => {
                if (isVisible.valueOf()) {
                    this._modalService.open(this._collectingDoneFailedModal, {
                        windowClass: 'modal-holder',
                        centered: true,
                        size: 'sm',
                    });
                } else {
                    this._modalService.dismissAll();
                }
            });
    }

    public closeModal() {
        this._states.setCollectingDoneFailedModalVisible(false);
        this._router.navigate(['my-tasks']);
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.complete();
    }
}
