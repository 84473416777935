import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'shared';
import { Router } from '@angular/router';

@Component({
    selector: 'app-checkout-warning-modal',
    templateUrl: './checkout-warning-modal.component.html',
    styleUrls: ['./checkout-warning-modal.component.css'],
})
export class CheckoutWarningModalComponent implements OnInit {
    @Input() public modalName: string;
    @Input() public routerLink = null;
    @Input() public supportCallButton = false;
    @Input() public translateArgs = {};

    constructor(public modalService: ModalService, private _router: Router) {}

    ngOnInit(): void {}

    public onSupportCallClick(): void {
        this.modalService.setModalVisibility(false, this.modalName);
        if (this.routerLink !== null) {
            this._router.navigate([this.routerLink]);
        }
    }

    public onOkClick(): void {
        this.modalService.setModalVisibility(false, this.modalName);
        if (this.routerLink !== null) {
            this._router.navigate([this.routerLink]);
        }
    }
}
