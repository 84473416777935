export enum OrderState {
    CollectingIsNotAssigned = 1,
    CollectingIsWaitingForAccept = 2,
    CollectingNotAccepted = 3,
    CollectingToDo = 4,
    CollectingInProgress = 5,
    CollectingDone = 6,
    CollectingFailed = 7,
    DeliveringIsNotAssigned = 8,
    DeliveringIsWaitingForAccept = 9,
    DeliveringNotAccepted = 10,
    DeliveringToDo = 11,
    DeliveringInProgress = 12,
    DeliveringDone = 13,
    DeliveringFailed = 14,
    InvoicingToDo = 15,
    InvoicingInProgress = 16,
    InvoicingDone = 17,
    InvoicingFailed = 18,
    PaymentRefundFailed = 20,
    PaymentRefunded = 21,
}
