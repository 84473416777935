import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardCardComponent } from './dashboard-card/dashboard-card.component';
import { RouterModule } from '@angular/router';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'shared';

@NgModule({
    declarations: [DashboardComponent, DashboardCardComponent],
    imports: [
        CommonModule,
        RouterModule,
        NgxBootstrapIconsModule,
        SharedModule,
        AppSharedModule,
        TranslateModule,
    ],
})
export class DashboardModule {}
