export class AnimationHelper {
    public static scrollToTop(stepPixles: number = 20, stepTime: number = 16): void {
        const scrollToTop = window.setInterval(() => {
            const pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - stepPixles);
            } else {
                window.clearInterval(scrollToTop);
            }
        }, stepTime);
    }
}
