import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-multi-order-info',
    templateUrl: './multi-order-info.component.html',
    styleUrls: ['./multi-order-info.component.css'],
})
export class MultiOrderInfoComponent implements OnInit {
    @Input() public superOrderCode: string;
    @Input() public orderCodeList: string[];

    ngOnInit(): void {
    }
}
