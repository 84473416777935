import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ColorClass, OrderItemState } from 'shared';

@Component({
    selector: 'app-order-item-state-view',
    templateUrl: './order-item-state-view.component.html',
    styleUrls: ['./order-item-state-view.component.scss'],
})
export class OrderItemStateViewComponent implements OnChanges {
    public iconClasses: string;
    @Input() public orderItemState: OrderItemState;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        this.setState();
    }

    setState(): void {
        switch (this.orderItemState) {
            case OrderItemState.Collected:
            case OrderItemState.Replaced:
                this.iconClasses = 'fa fa-check-circle fa-2x text-primary';
                break;
            case OrderItemState.NotFound:
                this.iconClasses = 'fa fa-eye-slash fa-2x text-dark';
                break;
            case OrderItemState.Approved:
                this.iconClasses = 'fa fa-thumbs-up fa-2x text-success';
                break;
            case OrderItemState.Declined:
                this.iconClasses = 'fa fa-thumbs-down fa-2x text-danger';
                break;
        }
    }
}
