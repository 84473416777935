import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeliveryTaskState } from 'shared';
import { TasksService } from 'dm-src/app/modules/tasks/tasks.service';
import { Modal } from 'dm-src/types/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { TaskListItem } from 'shared';

@Component({
    selector: 'app-task-state-badge',
    templateUrl: './task-state-badge.component.html',
    styleUrls: ['./task-state-badge.component.scss'],
})
export class TaskStateBadgeComponent implements OnChanges {
    @Input() taskState: DeliveryTaskState;
    @Input() task: TaskListItem;
    public label: string;
    public _classes: BehaviorSubject<string>;
    private _adjustableStates: DeliveryTaskState[] = [
        DeliveryTaskState.Failed,
        DeliveryTaskState.Done,
    ];

    public get classes(): Observable<string> {
        return this._classes.asObservable();
    }

    constructor(private _tasksService: TasksService) {
        this._classes = new BehaviorSubject<string>(null);
    }

    public onClick(): void {
        this._tasksService.selectTask(this.task);
        this._tasksService.setVisibleModal(Modal.SetTaskState);
    }

    public isClickable(): boolean {
        return this._adjustableStates.includes(this.taskState);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setBadgeProps();
    }

    private setBadgeProps() {
        let classes = 'badge ';
        switch (this.taskState) {
            case DeliveryTaskState.WaitingForAccept:
                classes += 'badge-warning';
                break;
            case DeliveryTaskState.NotAccepted:
                classes += 'badge-danger';
                break;
            case DeliveryTaskState.ToDo:
                classes += 'badge-info';
                break;
            case DeliveryTaskState.InProgress:
                classes += 'badge-secondary';
                break;
            case DeliveryTaskState.Done:
                classes += 'badge-success';
                break;
            case DeliveryTaskState.Failed:
                classes += 'badge-danger';
                break;
        }
        this.label = DeliveryTaskState[this.taskState];
        this._classes.next(classes);
    }
}
