import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnProgressComponent } from './btn-progress.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [BtnProgressComponent],
    exports: [BtnProgressComponent],
    imports: [CommonModule, TranslateModule],
})
export class BtnProgressModule {}
