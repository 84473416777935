import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSetting } from '../../models/user-setting';
import { IUserSettingsService } from './iuser-settings.service';
import { HttpResponse } from '@angular/common/http';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';

@Injectable()
export class UserSettingsService implements IUserSettingsService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getUserSettings(
        userID: string,
        section: string,
        key: string
    ): Observable<HttpResponse<UserSetting[]>> {
        return this._apiClient.getWithResponse<UserSetting[]>(
            `usersettings/${userID}/${section}/${key}`
        );
    }

    public setUserSettings(userSettings: UserSetting): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            `usersettings/update`,
            userSettings
        );
    }
}
