import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    ITasksService,
    TASKS_SERVICE_IMPL,
    IOrdersService,
    ORDERS_SERVICE_IMPL,
} from 'shared';
import { TaskListItem } from 'shared';
import { Order } from 'dm-src/models/order';
import { CustomerContactData, OrderDetails } from 'shared';
import { HttpResponse } from '@angular/common/http';
import { TaskUser } from 'shared';
import { UpdateCustomerNotes } from 'shared';
import { AssignTaskToUser } from 'shared';
import { OrderHistory } from 'shared';
import { DeliveryTaskState } from 'shared';
import { share } from 'rxjs/operators';
import { TaskListFilterOptions } from 'shared';
import { ACCOUNTS_SERVICE_IMPL, IAccountsService, ListService } from 'shared';
import { Modal } from 'dm-src/types/modal';
import { UserRole } from 'shared';
import { DeliveryTaskType } from 'shared';
import { TaskCollectionValidation } from 'dm-src/types/task-collection-validation';
import { ProgressLineService } from 'shared';
import { FlashMessageService } from 'shared';
import { MarkupCorrectionDto } from 'projects/shared/src/lib/dtos/markup-correction';
import { OrdersService } from '../orders/orders.service';

@Injectable({
    providedIn: 'root',
})
export class TasksService {
    private _selectedOrder: BehaviorSubject<Order>;
    private _customerData: BehaviorSubject<CustomerContactData>;
    private _orderDetails: BehaviorSubject<OrderDetails>;
    private _tasks: BehaviorSubject<TaskListItem[]>;
    private _selectedOrderID: BehaviorSubject<number>;
    private _isOrderDetailsModalShown: BehaviorSubject<boolean>;
    private _visibleModal: BehaviorSubject<Modal>;
    private _selectedTasks: TaskListItem[];
    public _filterOptions: BehaviorSubject<TaskListFilterOptions>;
    public selectedTasksIDs: BehaviorSubject<string[]>;
    public isUserAssignedToASelectedTask = false;
    public hasSelectedTasks = false;

    get tasks() {
        return this._tasks.asObservable();
    }

    get selectedTasks$(): Observable<string[]> {
        return this.selectedTasksIDs.asObservable();
    }

    get orderID() {
        return this._selectedOrderID.asObservable();
    }

    get isOrderDetailsModalShown() {
        return this._isOrderDetailsModalShown.asObservable();
    }

    get filterOptions(): Observable<TaskListFilterOptions> {
        return this._filterOptions.asObservable();
    }

    get visibleModal(): Observable<Modal> {
        return this._visibleModal.asObservable();
    }

    public get selectedOrder(): Observable<Order> {
        return this._selectedOrder.asObservable();
    }

    public get customerData(): Observable<CustomerContactData> {
        return this._customerData.asObservable();
    }

    public get orderDetails(): Observable<OrderDetails> {
        return this._orderDetails.asObservable();
    }

    constructor(
        private _serviceProvider: Injector,
        @Inject(ORDERS_SERVICE_IMPL) private _ordersService: IOrdersService,
        @Inject(TASKS_SERVICE_IMPL) private _tasksService: ITasksService,
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountService: IAccountsService,
        private _flashMessageService: FlashMessageService,
        private _progressLineService: ProgressLineService,
        private _listService: ListService,
        public ordersService: OrdersService
    ) {
        this._selectedOrderID = new BehaviorSubject<number>(null);
        this._tasks = new BehaviorSubject<TaskListItem[]>([]);
        this._isOrderDetailsModalShown = new BehaviorSubject<boolean>(false);
        this._filterOptions = new BehaviorSubject<TaskListFilterOptions>(null);
        this.getFilterOptions();
        this._visibleModal = new BehaviorSubject(null);
        this.selectedTasksIDs = new BehaviorSubject<string[]>([]);

        this._selectedOrder = new BehaviorSubject<Order>(null);
        this._customerData = new BehaviorSubject<CustomerContactData>(null);
        this._orderDetails = new BehaviorSubject<OrderDetails>(null);

        this.tasks.subscribe((tasks) => {
            const selectedTasks = tasks.filter((task) => task.isInCollection);
            this._selectedTasks = selectedTasks;
            this.selectedTasksIDs.next(selectedTasks.map((task) => task.deliveryTaskID));
        });

        this.selectedTasks$.subscribe((taskIDs) => {
            this.hasSelectedTasks = taskIDs.length > 0;
            this.setIsUserAssignedToASelectedTask();
        });
    }

    public setVisibleModal(modal: Modal): void {
        this._visibleModal.next(modal);
    }

    public selectTask(task: TaskListItem) {
        this._selectedTasks = task !== null ? [task] : [];
        this.selectedTasksIDs.next(task !== null ? [task.deliveryTaskID] : []);
    }

    public addTaskToCollection(taskID: string) {
        this.setTaskCollection(true, taskID);
    }

    public removeTaskFromCollection(taskID: string) {
        this.setTaskCollection(false, taskID);
    }

    public selectOrder(orderID: number) {
        this._ordersService.getOrder(orderID).subscribe((response) => {
            if (response.status === 200) {
                this._selectedOrder.next(response.body);
                this.ordersService.setSelectedOrder(response.body);
                this._ordersService
                    .getCustomerContactData(orderID)
                    .subscribe((customerData) =>
                        this.ordersService.setCustomerData(customerData)
                    );
                this.updateOrderDetails(orderID);
            }
        });

        this._selectedOrderID.next(orderID);
    }

    public assignTaskToUser(user: TaskUser) {
        const requestBody = new AssignTaskToUser();
        requestBody.taskIDs = this.selectedTasksIDs.getValue();
        requestBody.userID = user.userID;

        this._tasksService.assignToUser(requestBody).subscribe((response) => {
            if (response.status === 200) {
                const tasks = this._tasks.getValue().map((task) => {
                    if (requestBody.taskIDs.includes(task.deliveryTaskID)) {
                        task.username = user.username;
                        task.isInCollection = false;

                        if (DeliveryTaskState[task.state] !== DeliveryTaskState.Canceled)
                            task.state =
                                DeliveryTaskState[DeliveryTaskState.WaitingForAccept];
                    }
                    return task;
                });
                this._tasks.next(tasks);
                this.selectTask(null);
            }
        });
    }

    public refundPayment() {
        return this._ordersService.refundOrderPayment(this._selectedOrderID.getValue());
    }

    public getAvailableTimeSlots() {
        return this._ordersService.getAvailableTimeSlots(
            this._selectedOrderID.getValue()
        );
    }

    public changeEstimatedDeliveryEnd(estimatedDeliveryEnd: Date) {
        return this._ordersService.changeEstimatedDeliveryEnd(
            this._selectedOrderID.getValue(),
            estimatedDeliveryEnd
        );
    }

    public changeTimeSlotForDelivery(timeSlot: Date) {
        return this._ordersService.changeTimeSlotForDelivery(
            this._selectedOrderID.getValue(),
            timeSlot
        );
    }

    public unassignTasks(): void {
        const taskIDs = this.selectedTasksIDs.getValue();
        this._tasksService.unassignTasks(taskIDs).subscribe(
            (response) => {
                if (response.status === 200) {
                    const tasks = this._tasks.value.map((task) => {
                        if (taskIDs.some((t) => t === task.deliveryTaskID)) {
                            task.isInCollection = false;
                            task.username = null;
                        }
                        return task;
                    });
                    this._tasks.next(tasks);
                    this.selectTask(null);
                }
            },
            (error) => {
                this._flashMessageService.showStoredMessage();
            }
        );
    }

    public setTaskState(taskID: string, taskState: DeliveryTaskState): void {
        const request = this._tasksService.setTaskState(taskID, taskState);
        request.subscribe((response) => {
            if (response.status === 200) {
                const tasks = this._tasks.value.map((task) => {
                    if (task.deliveryTaskID === taskID) {
                        task.state = DeliveryTaskState[taskState];
                    }
                    return task;
                });
                this._tasks.next(tasks);
                this.selectTask(null);
            }
        });
    }

    public getTask(taskID: string): TaskListItem {
        return this._tasks.getValue().find((x) => x.deliveryTaskID === taskID);
    }

    public initTasksList() {
        this._listService.onQueryResult = (result) => {
            if (result === null) {
                result = [];
            }

            this._tasks.next(result);
        };
        this._listService.listApiEndpoint = 'tasks';
        this._listService.resetList();
        this._listService.getResults();
    }

    public setOrderDetailsModalVisibility(isVisible: boolean) {
        this._isOrderDetailsModalShown.next(isVisible);
    }

    public getFilterOptions(): void {
        this._progressLineService.showProgressLine();
        this._tasksService.getFilterOptions().subscribe((filterOptions) => {
            this._filterOptions.next(filterOptions);
            this._progressLineService.hideProgressLine();
        });
    }

    public setTaskCollection(isInCollection: boolean, taskID: string = null) {
        const tasks = this._tasks.value.map((task) => {
            if (task.deliveryTaskID === taskID || taskID === null) {
                task.isInCollection = isInCollection;
            }
            return task;
        });
        this._tasks.next(tasks);
    }

    public validateTaskCollectionByRole(
        userRoles: string[],
        selectedTask: boolean = false
    ): TaskCollectionValidation {
        const selectedTaskTypes = this._selectedTasks
            .map((task) => task.type)
            .filter((type, idx, collection) => collection.indexOf(type) === idx);

        const response = new TaskCollectionValidation();
        response.deliveryTypes = [];
        response.userRoles = [];

        if (
            !userRoles.includes(UserRole[UserRole.Picker].toLowerCase()) &&
            selectedTaskTypes.includes(DeliveryTaskType[DeliveryTaskType.Collecting])
        ) {
            response.deliveryTypes.push(DeliveryTaskType.Collecting);
            response.userRoles.push(UserRole.Picker);
        }

        if (
            !userRoles.includes(UserRole[UserRole.Deliverer].toLowerCase()) &&
            selectedTaskTypes.includes(DeliveryTaskType[DeliveryTaskType.Delivering])
        ) {
            response.deliveryTypes.push(DeliveryTaskType.Delivering);
            response.userRoles.push(UserRole.Deliverer);
        }

        return response;
    }

    public updateCustomerContactData(
        contactData: CustomerContactData
    ): Observable<HttpResponse<void>> {
        contactData.orderID = this._selectedOrderID.getValue();
        const request = this._ordersService
            .updateCustomerContactData(contactData)
            .pipe(share());

        request.subscribe((response) => {
            if (response.status === 200) {
                this.ordersService.setCustomerData(contactData);
                this._customerData.next(contactData);
            }
        });
        return request;
    }

    public updateCustomerNotes(notes: string): Observable<HttpResponse<void>> {
        const requestBody = new UpdateCustomerNotes();
        requestBody.notes = notes;
        requestBody.orderID = this._selectedOrderID.getValue().toString();
        return this._ordersService.updateCustomerNotes(requestBody);
    }

    public createCollectingTask(): Observable<HttpResponse<void>> {
        const tasksService = this._serviceProvider.get(TASKS_SERVICE_IMPL);
        const apiCall = tasksService
            .createCollectingTask(this._selectedOrderID.getValue())
            .pipe(share());

        apiCall.subscribe(
            (response) => {
                this.updateOrderDetails(this._selectedOrderID.getValue());
            },
            () => {
                const flashMessageService =
                    this._serviceProvider.get(FlashMessageService);
                flashMessageService.showStoredMessage('order-details-modal');
            }
        );

        return apiCall;
    }

    public createContainerPlacingTask(): Observable<HttpResponse<void>> {
        const tasksService = this._serviceProvider.get(TASKS_SERVICE_IMPL);
        const apiCall = tasksService
            .createContainerPlacingTask(this._selectedOrderID.getValue())
            .pipe(share());

        apiCall.subscribe(
            (response) => {
                this.updateOrderDetails(this._selectedOrderID.getValue);
            },
            () => {
                const flashMessageService =
                    this._serviceProvider.get(FlashMessageService);
                flashMessageService.showStoredMessage('order-details-modal');
            }
        );

        return apiCall;
    }

    public createDeliveringTask(): Observable<HttpResponse<void>> {
        const tasksService = this._serviceProvider.get(TASKS_SERVICE_IMPL);
        const apiCall = tasksService
            .createDeliveringTask(this._selectedOrderID.getValue())
            .pipe(share());

        apiCall.subscribe(
            (reponse) => {
                this.updateOrderDetails(this._selectedOrderID.getValue());
            },
            () => {
                const flashMessageService =
                    this._serviceProvider.get(FlashMessageService);
                flashMessageService.showStoredMessage('order-details-modal');
            }
        );

        return apiCall;
    }

    public correctStockForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>> {
        return this._ordersService.correctStockForOrder(correctionDto);
    }

    public correctInvoiceForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>> {
        return this._ordersService.correctInvoiceForOrder(correctionDto);
    }

    public correctInvoiceAndStockForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>> {
        return this._ordersService.correctInvoiceAndStockForOrder(correctionDto);
    }

    public generateInvoiceJpl(orderID: number): Observable<HttpResponse<Blob>> {
        return this._ordersService.generateInvoiceJpl(orderID);
    }

    public getOrderHistory(orderID: number): Observable<OrderHistory[]> {
        return this._ordersService.getOrderHistory(orderID);
    }

    private updateOrderDetails(orderID) {
        this._ordersService
            .getOrderDetails(orderID)
            .subscribe((orderDetails) =>
                this.ordersService.setOrderDetails(orderDetails)
            );
    }

    private setIsUserAssignedToASelectedTask(): void {
        this.isUserAssignedToASelectedTask = this._selectedTasks.some(
            (t) => t.username !== null
        );
    }
}
