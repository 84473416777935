import { Injectable } from '@angular/core';
import { CollectingStatesService } from './collecting-states.service';
import { ProgressLineService } from 'shared';
import Timeout = NodeJS.Timeout;
import { ModalService } from 'shared';
import { FlashMessageService } from 'shared';
import { AnimationHelper } from 'shared';

@Injectable({
    providedIn: 'root',
})
export class CollectingErrorsService {
    private _apiCallback: Timeout;

    constructor(
        private _states: CollectingStatesService,
        private _flashMessageService: FlashMessageService,
        private _progressLineService: ProgressLineService,
        private _modalService: ModalService
    ) {}

    public showErrorMessage(): void {
        this._flashMessageService.showStoredMessage('main', 8000);
        AnimationHelper.scrollToTop();
    }

    public handlePaymentErrorResponse(httpStatus: number, callback: Function): void {
        if (httpStatus === 429) {
            this._states.isVirtualPaymentWaiting = true;
            this._progressLineService.showProgressLine(
                'too-many-virtual-payment-progress'
            );
            this._modalService.setModalVisibility(
                true,
                'too-many-virtual-payment-request'
            );
            this._apiCallback = setTimeout(
                () => {
                    this._progressLineService.hideProgressLine(
                        'too-many-virtual-payment-progress'
                    );
                    this._modalService.setModalVisibility(
                        false,
                        'too-many-virtual-payment-request'
                    );
                    callback();
                    clearTimeout(this._apiCallback);
                    this._states.isVirtualPaymentWaiting = false;
                    this._apiCallback = null;
                },
                60000,
                [callback]
            );
        } else {
            this._flashMessageService.showStoredMessage();
        }
    }

    public handleOfflineProductError(httpStatus: number): void {
        this._states.isOrderItemIdentificationInProgress = false;
        if (httpStatus === 404) {
            this._modalService.setModalVisibility(true, 'unidentified-item-modal');
        } else {
            this.showErrorMessage();
        }
    }
}
