import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxSelectModule } from 'ngx-select-ex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProviderShopChooserComponent } from './providershop-chooser.component';

@NgModule({
    declarations: [ProviderShopChooserComponent],
    exports: [ProviderShopChooserComponent],
    imports: [CommonModule, NgxSelectModule, FormsModule, ReactiveFormsModule],
})
export class ProviderShopChooserModule {}
