<th scope="row">{{ index + 1 }}</th>
<td>{{ replacementItem?.itemName }}</td>
<td>
    <img class="product-photo" src="{{ replacementItem?.itemThumbnailSrc }}" />
</td>
<td *ngIf="replacementItem">
    <span (click)="toggleOriginalItem()" class="badge badge-{{ class }}">{{
        orderItemState | humancase
    }}</span>
    <span (click)="toggleOriginalItem()" class="badge badge-warning">Replacement</span>
</td>
<td *ngIf="!replacementItem">
    <span class="badge badge-danger">Under replacement</span>
</td>
<td>{{ replacementItem?.unitPrice }}</td>
<td></td>
<td>{{ replacementItem?.collectedQuantity }}</td>
<td>{{ replacementItem?.quantity }}</td>
<td>{{ replacementItem?.price }}</td>
<td
    order-return-control
    [orderItem]="orderItem"
    [isReturnInProgress]="orderItemListService.isReturnInProgress | async"
></td>
<td>{{ orderItem.removedReasonComment }}</td>
<td>
    <img
        src="{{ orderItem.removedReasonImageUrl }}"
        class="reason-image"
        (click)="openImage(orderItem.removedReasonImageUrl)"
    />
</td>
