import { Component, Inject, Input, OnInit } from '@angular/core';
import { Order } from 'dm-src/models/order';
import {
    ColorClass,
    FlashMessageService,
    IOrdersService,
    ORDERS_SERVICE_IMPL,
} from 'shared';
import { environment } from 'dm-src/environments/environment';

@Component({
    selector: 'app-order-invoice',
    templateUrl: './order-invoice.component.html',
    styleUrls: ['./order-invoice.component.scss'],
})
export class OrderInvoiceComponent implements OnInit {
    @Input() order: Order;
    public deliveryDateFormat: string = environment.deliveryDateFormat;
    public invoiceDate: Date;
    public invoiceNumber: string = '';
    public isLoading = false;
    public apiError = '';
    public invoiceList: [];

    constructor(
        @Inject(ORDERS_SERVICE_IMPL) private _ordersService: IOrdersService,
        private _flashMessageService: FlashMessageService
    ) {}

    ngOnInit(): void {
        this._ordersService.getOrderInvoices(this.order.orderID).subscribe((data) => {
            this.invoiceList = data;
        });
    }
}
