import { Component, Inject } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { ProgressLineService } from '../../modules/progress-line/progress-line.service';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../../services/authentication/iauthentication.service';
import { NavMenuComponent } from './nav-menu.component';

@Component({
    selector: 'lib-nav-menu-ionic',
    templateUrl: './navmenu.ionic.component.html',
    styleUrls: ['./navmenu.ionic.component.scss'],
})
export class NavMenuIonicComponent extends NavMenuComponent {
    constructor(
        @Inject(AUTH_SERVICE_IMPL) _authService: IAuthenticationService,
        _progressLineService: ProgressLineService,
        private _menu: MenuController
    ) {
        super(_authService, _progressLineService);
    }

    ngOnInit(): void {}

    close(): void {
        this._menu.close();
    }
}
