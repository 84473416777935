import { ISelectOption } from '../../types/i-select-option';
import { TableColumn } from '../../types/table-column';

export abstract class BaseList {
    constructor(public columns: TableColumn[]) {}

    protected setColumnProperty(name: string[], key: string, value: any): void {
        const columnIndex = this.columns.findIndex(
            (x) => JSON.stringify(x.name) === JSON.stringify(name)
        );

        if (columnIndex > -1) {
            this.columns[columnIndex][key] = value;
        }
    }

    protected setColumnsVisibility(columns: ISelectOption[]): void {
        let columnIndex;
        if (!this.columns || !columns) return;
        this.columns.forEach((column) => (column.isVisible = false));
        columns.forEach((column) => {
            columnIndex = this.columns.findIndex(
                (x) => JSON.stringify(x.name) === column?.id
            );
            if (columnIndex > -1) {
                this.columns[columnIndex].isVisible = true;
            }
        });
    }
}
