import { ISelectOption } from './i-select-option';
import { TaskUser } from '../models/task-user';

export class TaskListFilterOptions {
    public providers: ISelectOption[];
    public users: TaskUser[];
    public filterUsers: ISelectOption[];
    public suppliers: ISelectOption[];
    public zipList: ISelectOption[];
    public timeSlotList: ISelectOption[];
}
