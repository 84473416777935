export enum AppEnvironment {
    Local,
    AtLocal,
    HuMaster,
    HuStaging,
    HuProduction,
    HuProductionB2B,
    AtMaster,
    AtStaging,
    AtProduction,
    ProviderTest,
    Intranet,
    AtIntranet,
    MobilDevStaging,
}
