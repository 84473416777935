import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { skip, takeUntil } from 'rxjs/operators';
import { TasksService } from 'dm-src/app/modules/tasks/tasks.service';
import { Order } from 'dm-src/models/order';
import { IOrdersService, ORDERS_SERVICE_IMPL } from 'shared';
import { environment } from 'dm-src/environments/environment';
import { OrdersService } from '../../orders/orders.service';

@Component({
    selector: 'app-order-details-modal',
    templateUrl: './order-details-modal.component.html',
    styleUrls: ['./order-details-modal.component.scss'],
})
export class OrderDetailsModalComponent implements OnInit, OnDestroy {
    public order: Order;
    public orderID: number;
    private _destroy$: ReplaySubject<boolean>;
    @ViewChild('orderDetailsModal', { static: false })
    private _orderDetailsModal: TemplateRef<any>;
    public billPhotoURL: string;

    constructor(
        public ordersService: OrdersService,
        private _tasksService: TasksService,
        @Inject(ORDERS_SERVICE_IMPL) private _ordersService: IOrdersService,
        private _modalService: NgbModal
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit() {
        this._tasksService.orderID
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((orderID) => {
                this.orderID = orderID;
                this.getOrder();
            });

        this._tasksService.isOrderDetailsModalShown
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isShown) => {
                if (isShown) {
                    this._modalService.open(this._orderDetailsModal, {
                        windowClass: 'modal-holder',
                        size: 'xl',
                        centered: true,
                    });
                } else {
                    this._modalService.dismissAll();
                }
            });

        this.ordersService.selectedOrder
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((order) => {
                this.order = order;
                this.orderID = order.orderID;
            });
    }

    private getOrder(): void {
        this._ordersService.getOrder(this.orderID).subscribe((response) => {
            if (response.status === 200) {
                this.order = response.body;
                if (this.order.billPhotoFilename !== null) {
                    this.billPhotoURL = `${environment.deliveryManagerMediaUrl}${environment.billPhotoFolder}/${this.order.billPhotoFilename}`;
                } else {
                    this.billPhotoURL = null;
                }
            }
        });
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
        this.ordersService.resetSelectedOrder();
    }
}
