<ng-template #createOrderItemModal let-modal>
    <div class="modal-header modal-fullscreen">
        <h4 class="modal-title" id="modal-basic-title">
            {{ 'collecting.new-order-item' | translate }}
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            {{ 'collecting.new-order-item-modal-text' | translate }}
        </p>
        <form [formGroup]="createOrderItemForm">
            <div class="form-group">
                <label class="control-label" for="quantity">{{
                    'collecting.product-name' | translate
                }}</label>
                <input
                    type="text"
                    id="name"
                    formControlName="name"
                    class="form-control"
                    placeholder="{{ 'collecting.product-name' | translate }}"
                    required
                    autofocus
                    [ngClass]="{ 'is-invalid': isSubmitted && fields.name.errors }"
                />
                <div
                    *ngIf="isSubmitted && fields.quantity.errors"
                    class="invalid-feedback"
                >
                    <div *ngIf="fields.quantity.errors.required">
                        {{ 'collecting.product-name' | translate }}
                        {{ 'common.is-required' | translate }}
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-6">
                    <label for="quantity">{{ 'collecting.quantity' | translate }}</label>
                    <input
                        type="number"
                        step=".001"
                        id="quantity"
                        formControlName="quantity"
                        class="form-control"
                        placeholder="{{ 'collecting.quantity' | translate }}"
                        required
                        autofocus
                        [ngClass]="{
                            'is-invalid': isSubmitted && fields.quantity.errors
                        }"
                    />
                    <div
                        *ngIf="isSubmitted && fields.quantity.errors"
                        class="invalid-feedback"
                    >
                        <div *ngIf="fields.quantity.errors.required">
                            {{ 'collecting.quantity' | translate }}
                            {{ 'common.is-required' | translate }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <label for="quantity">{{ 'collecting.unit' | translate }}</label>
                    <ng-select
                        id="unit"
                        [ngClass]="{ 'is-invalid': isSubmitted && fields.unit.errors }"
                        formControlName="unit"
                        placeholder="{{ 'collecting.unit' | translate }}"
                        required
                    >
                        <ng-option
                            *ngFor="let unitType of unitTypes; let idx = index"
                            [value]="unitType"
                        >
                            {{ 'collecting.' + unitTypeLabels[idx] | translate }}
                        </ng-option>
                    </ng-select>
                    <div
                        *ngIf="isSubmitted && fields.unit.errors"
                        class="invalid-feedback"
                    >
                        <div *ngIf="fields.unit.errors.required">
                            {{ 'collecting.unit' | translate }}
                            {{ 'common.is-required' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="unit-price">{{ 'collecting.unit-price' | translate }}</label>
                <div class="input-group">
                    <input
                        type="number"
                        id="unit-price"
                        formControlName="unitPrice"
                        class="form-control"
                        placeholder="{{ 'collecting.unit-price' | translate }}"
                        required
                        autofocus
                        [ngClass]="{
                            'is-invalid': isSubmitted && fields.unitPrice.errors
                        }"
                    />
                    <div class="input-group-append">
                        <span class="input-group-text">{{
                            'common.currency' | translate
                        }}</span>
                    </div>
                    <div
                        *ngIf="isSubmitted && fields.unitPrice.errors"
                        class="invalid-feedback"
                    >
                        <div *ngIf="fields.unitPrice.errors.required">
                            {{ 'collecting.unit-price' | translate }}
                            {{ 'common.is-required' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="onSubmit()" class="btn btn-primary">
            {{ 'collecting.save-item' | translate }}
        </button>
    </div>
</ng-template>
