<button
    class="btn btn-success"
    (click)="showProductIdentifyModal()"
    [disabled]="states.isCollectingForbidden"
    *ngIf="hasBarcodeReader"
>
    <i class="fa fa-search"></i>{{ 'collecting.identify' | translate }}
</button>
<button
    *ngIf="canEditManually"
    [disabled]="states.isCollectingForbidden"
    class="btn btn-primary"
    (click)="edit()"
>
    <i class="fa fa-edit"></i>{{ 'common.edit' | translate }}
</button>
<button
    class="btn btn-warning"
    [disabled]="states.isCollectingForbidden"
    (click)="cancel()"
>
    <i class="fa fa-undo"></i>{{ 'common.cancel' | translate }}
</button>
<button
    class="btn btn-danger"
    [disabled]="states.isCollectingForbidden"
    (click)="itemNotFound()"
>
    <i class="fa fa-exclamation-triangle"></i>{{ 'collecting.not-found' | translate }}
</button>
