import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { QRReader } from './qr.reader';
import { Router } from '@angular/router';

@Component({
    selector: 'lib-qr-reader',
    templateUrl: 'qr-reader.component.html',
    styleUrls: ['qr-reader.component.scss'],
})
export class QRReaderComponent implements OnInit, OnDestroy {
    @Output() public onQRScanned = new EventEmitter<string>();
    public _window: any = window;
    private _scannerElement: any;
    private _qrReader: QRReader;
    private _isScanning = true;
    private _shutterSound: HTMLAudioElement = new Audio();

    constructor(public router: Router) {}

    ngOnInit() {
        this._shutterSound.src = '/assets/audio/shutter.mp3';
        this._shutterSound.volume = 0.5;
        this._window.iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
        this._window.isMediaStreamAPISupported =
            navigator &&
            navigator.mediaDevices &&
            'enumerateDevices' in navigator.mediaDevices;
        this._window.noCameraPermission = false;

        this._scannerElement = document.querySelector('.custom-scanner');
        this._window.appOverlay = document.querySelector('.app__overlay');

        this._qrReader = new QRReader();

        this._window.scroll(0, 0);

        // Initializing qr scanner
        // window.addEventListener('load', event => {

        // Set camera overlay size
        setTimeout(() => {
            this.setCameraOverlay();
            if (this._window.isMediaStreamAPISupported) {
                this.scan();
            }
        }, 0);

        // To support other browsers who dont have mediaStreamAPI
        // selectFromPhoto();
    }

    ngOnDestroy() {
        if (this._qrReader) {
            this._qrReader.destroy();
            this._qrReader = null;
        }
    }

    private setCameraOverlay(): void {
        this._window.appOverlay.style.borderStyle = 'solid';
    }

    private createFrame(): void {
        const frame = document.createElement('img');
        frame.src = '';
        frame.id = 'frame';
    }

    private scan(forSelectedPhotos = false): void {
        if (this._window.isMediaStreamAPISupported && !this._window.noCameraPermission) {
            this._scannerElement.style.display = 'block';
        }

        this._qrReader.scan((scannedData) => {
            if (!this._isScanning) {
                return;
            }

            this._isScanning = false;

            try {
                this._shutterSound.play();
            } catch {}

            setTimeout((x) => {
                this.onQRScanned.emit(scannedData);
                setTimeout(() => (this._isScanning = true), 2000);
            }, 200);
        }, forSelectedPhotos);
    }
}
