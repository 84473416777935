import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IChangePassword } from '../../../dtos/i-change-password';
import {
    ACCOUNTS_SERVICE_IMPL,
    IAccountsService,
} from '../../../services/accounts/iaccounts.service';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../../../services/authentication/iauthentication.service';
import { MustMatch } from '../../../validators/must-match.validator';
import { FlashMessageService } from '../../flash-message/flash-message.service';

@Component({
    selector: 'lib-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    public changePasswordForm: UntypedFormGroup;
    public isSubmitted: boolean;
    public showSuccess: boolean;

    private _token: string;
    private _email: string;

    public get fields() {
        return this.changePasswordForm.controls;
    }

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountsService: IAccountsService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _formBuilder: UntypedFormBuilder,
        private _route: ActivatedRoute,
        private _flashMessageService: FlashMessageService
    ) {}

    ngOnInit(): void {
        this.changePasswordForm = this._formBuilder.group(
            {
                password: ['', Validators.required],
                confirmPassword: ['', Validators.required],
            },
            {
                validator: MustMatch('password', 'confirmPassword'),
            }
        );

        this._token = decodeURIComponent(this._route.snapshot.queryParams['token']);
        this._email = this._route.snapshot.queryParams['email'];
    }

    onSubmitChangePassword(): void {
        this.isSubmitted = true;
        this.showSuccess = false;

        if (this.changePasswordForm.invalid) {
            return;
        }

        const changePassDto: IChangePassword = {
            password: this.fields.password.value,
            token: this._token,
            email: this._email,
        };

        this._accountsService
            .resetPassword(changePassDto)
            .pipe(
                takeUntil(this.destroyed$),
                switchMap((response) => this._authService.logout())
            )
            .subscribe(
                (res) => {
                    this.showSuccess = true;
                    this._authService.logout();
                },
                (error) => {
                    this._flashMessageService.showStoredMessage();
                }
            );
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
