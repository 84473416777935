import { EnvironmentName } from '../types/environment-name';
import { ModuleID } from '../types/module-id';
import { CustomLogger } from '../types/custom-logger';

export class InfoLogDto {
    public environmentName: EnvironmentName;
    public moduleID: ModuleID;
    public isEmailNotification: boolean;
    public message: string;
    public description: string;
    public additionalData: { [id: string]: object };
    public emailContacts: string[];
    public customLog: string;
    public customLogger: CustomLogger;
}
