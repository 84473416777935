import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-state-badge',
    templateUrl: './state-badge.component.html',
    styleUrls: ['./state-badge.component.scss'],
})
export class StateBadgeComponent implements OnInit {
    @Input() state: string;
    public label: string;
    public classes = 'badge ';

    ngOnInit() {
        if (this.state !== null) {
            if (this.state.includes('ToDo')) {
                this.classes += 'badge-primary';
            } else if (this.state.includes('InProgress')) {
                this.classes += 'badge-warning';
            } else if (this.state.includes('Done')) {
                this.classes += 'badge-success';
            } else if (this.state.includes('Failed')) {
                this.classes += 'badge-danger';
            } else if (this.state.includes('NotAccepted')) {
                this.classes += 'badge-info';
            } else if (this.state.includes('WaitingFor')) {
                this.classes += 'badge-dark';
            } else if (this.state.includes('InReturn')) {
                this.classes += 'badge-warning';
            } else if (this.state.includes('InRefund')) {
                this.classes += 'badge-info';
            } else if (this.state.includes('Returned')) {
                this.classes += 'badge-success';
            } else if (this.state.includes('Declined')) {
                this.classes += 'badge-danger';
            } else {
                this.classes += 'badge-secondary';
            }
        }
        this.label = this.state;
    }
}
