import { Component, Input } from '@angular/core';
import { CollectingTaskListItem } from 'shared';
import { environment } from 'dm-src/environments/environment';
import { PaymentType } from 'shared';

@Component({
    selector: '[collecting-task-item]',
    templateUrl: './collecting-task-item.component.html',
    styleUrls: ['./collecting-task-item.component.scss'],
})
export class CollectingTaskItemComponent {
    @Input() task: CollectingTaskListItem;
    public isCollapsed = true;
    public dateFormat: string;

    public PaymentType = PaymentType;

    constructor() {
        this.dateFormat = environment.deliveryDateFormat;
    }
}
