<lib-modal
    [title]="'collecting.' + modalName + '-title' | translate"
    [componentID]="modalName"
>
    <p [innerHTML]="'collecting.' + modalName + '-text' | translate : translateArgs"></p>
    <a
        *ngIf="!supportCallButton; else callSupport"
        class="btn btn-block btn-warning"
        (click)="onOkClick()"
    >
        OK
    </a>
    <ng-template #callSupport>
        <app-call-support-btn (click)="onSupportCallClick()"> </app-call-support-btn>
    </ng-template>
</lib-modal>
