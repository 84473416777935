import { Component, OnInit, Inject } from '@angular/core';
import { DailyTourPlanDTO } from '../../../dtos/daily-tour-plan';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TourPlannerConfiguration } from '../../../dtos/tour-planner-configuration';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessageService } from 'shared';
import {
    TOURPLANNER_SERVICE_IMPL,
    ITourPlannerService,
} from '../../../services/tourplanner/itourplanner.service';
import {
    TourPlannerDailyPlan,
    TourPlannerActivity,
    TourPlannerTour,
    TourPlannerStop,
} from '../../../dtos/tour-planner-daily-plan';
import {
    ISupplierRegionsService,
    SUPPLIER_REGIONS_SERVICE_IMPL,
} from '../../../services/suppliers/isupplier-regions-service';
import { SupplierRegion } from '../../../models/supplier-region';

@Component({
    selector: 'app-tour-planner',
    templateUrl: './tour-planner.component.html',
    styleUrls: ['./tour-planner.component.scss'],
})
export class TourPlannerComponent implements OnInit {
    public supplierRegions: SupplierRegion[];

    public isLoading = false;
    public selectedDelivererID;
    public justCorrigationNeededOrders = false;

    dailyTour: DailyTourPlanDTO;
    savedTourPlannerDailyPlan: TourPlannerDailyPlan;
    tours: TourPlannerTour[];

    public parameterForm: UntypedFormGroup;
    tourPlannerConfiguration: TourPlannerConfiguration;
    referenceDate: string;
    panelOpenState = false;

    constructor(
        @Inject(TOURPLANNER_SERVICE_IMPL)
        private _tourPlannerService: ITourPlannerService,
        private _formBuilder: UntypedFormBuilder,
        private _flashMessageService: FlashMessageService,
        @Inject(SUPPLIER_REGIONS_SERVICE_IMPL)
        private _supplierRegionsService: ISupplierRegionsService
    ) {}

    ngOnInit() {
        this._supplierRegionsService.getSupplierRegionsData().subscribe((response) => {
            if (response.status !== 200) {
                this._flashMessageService.showStoredMessage();
            } else {
                this.supplierRegions = response.body.supplierRegions;
            }
        });

        //this.referenceDate = new Date("2020.12.23.").toISOString().slice(0, 16);
        this.referenceDate = new Date().toISOString().slice(0, 16);
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.referenceDate = tomorrow.toISOString().slice(0, 16);

        this.parameterForm = this._formBuilder.group({
            supplierRegionID: ['', Validators.required],
            referenceDate: [this.referenceDate, Validators.required],
            isAutomaticPlanned: [false, Validators.required],
            planBeforeStoreOpening: [1, Validators.required],
            planPeriod: [3, Validators.required],
            periodEndEarlier: [15, Validators.required],
            pickupTimeForDelivery: [15, Validators.required],
            shiftStartMinusMinutes: [0, Validators.required],
            shiftEndAdditionalMinutes: [0, Validators.required],
            shiftBreakAdditionalMinutes: [15, [Validators.required]],
            deliveryDurationOnAddress: [15, Validators.required],
            pickingDurationFactor: [1.0, Validators.required],
            pickingStartMinusMinutes: [-100, Validators.required],
            isPickingTime: [true, Validators.required],
            distanceCost: [100, Validators.required],
            timeCost: [50, Validators.required],
            fixedCost: [0, Validators.required],
            trafficConfiguration: ['automatic', Validators.required],
            waitingTimeReduceConfiguration: [true, Validators.required],
            waitingTimeBufferTimeConfiguration: [15, Validators.required],
            capacity: [2, Validators.required],
            isAsync: [true, Validators.required],
            isJustReservedPayments: [true, Validators.required],
            orderNumber: [120, Validators.required],
        });
    }

    getSavedDailyPlan() {
        this.savedTourPlannerDailyPlan = null;
        this.tourPlannerConfiguration = this.parameterForm.value;
        this.isLoading = true;
        this._tourPlannerService
            .getSavedDailyPlan(this.tourPlannerConfiguration)
            .pipe()
            .subscribe(
                (savedTourPlannaerDailyPlan) => {
                    if (!savedTourPlannaerDailyPlan) {
                        this._flashMessageService.showMessage(
                            'Hiányzó mentett útvonal',
                            'Az adott napra és régióra mégnincs előzetesen mentett útvonal. HERE.COM tervezés futtatandó!'
                        );
                    } else {
                        this.savedTourPlannerDailyPlan = savedTourPlannaerDailyPlan;
                        this.sortDailyPlanByArrival();
                        this.isLoading = false;
                    }
                },
                () => {
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    startTourPlanning() {
        this.savedTourPlannerDailyPlan = null;
        this.tourPlannerConfiguration = this.parameterForm.value;
        this.isLoading = true;
        this._tourPlannerService
            .planDailyPlan(this.tourPlannerConfiguration)
            .pipe()
            .subscribe(
                (savedTourPlannaerDailyPlan) => {
                    if (!savedTourPlannaerDailyPlan) {
                        this._flashMessageService.showMessage(
                            'Hiányzó mentett útvonal',
                            'Az adott napra és régióra mégnincs előzetesen mentett útvonal. HERE.COM tervezés futtatandó!'
                        );
                    } else {
                        this.savedTourPlannerDailyPlan = savedTourPlannaerDailyPlan;
                        this.sortDailyPlanByArrival();
                        this.isLoading = false;
                    }
                },
                () => {
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    corrigatesavedTourPlan() {
        this.savedTourPlannerDailyPlan = null;
        this.tourPlannerConfiguration = this.parameterForm.value;
        this.isLoading = true;
        this._tourPlannerService
            .corrigateSavedDailyPlan(this.tourPlannerConfiguration)
            .pipe()
            .subscribe(
                (savedTourPlannaerDailyPlan) => {
                    if (!savedTourPlannaerDailyPlan) {
                        this._flashMessageService.showMessage(
                            'Hiányzó mentett útvonal',
                            'Az adott napra és régióra mégnincs előzetesen mentett útvonal. HERE.COM tervezés futtatandó!'
                        );
                    } else {
                        this.savedTourPlannerDailyPlan = savedTourPlannaerDailyPlan;
                        this.sortDailyPlanByArrival();
                        this.isLoading = false;
                    }
                },
                () => {
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    acceptPlanning() {
        this.tourPlannerConfiguration = this.parameterForm.value;
        this._tourPlannerService
            .acceptSavedDailyPlan(this.tourPlannerConfiguration)
            .pipe()
            .subscribe(
                (result) => {
                    this._flashMessageService.showMessage(result, ``);
                },
                () => {
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    saveSupplierRegionTourPlannerParameters() {
        this.tourPlannerConfiguration = this.parameterForm.value;
        this._tourPlannerService
            .saveSupplierRegionTourPlannerParameters(this.tourPlannerConfiguration)
            .pipe()
            .subscribe(
                (result) => {
                    this._flashMessageService.showMessage(result, ``);
                },
                () => {
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    getSupplierRegionTourPlannerParameters() {
        this.tourPlannerConfiguration = this.parameterForm.value;
        this._tourPlannerService
            .getSupplierRegionTourPlannerParameters(this.tourPlannerConfiguration)
            .pipe()
            .subscribe(
                (result) => {
                    //this._flashMessageService.showMessage(result, ``);

                    this.parameterForm = this._formBuilder.group({
                        supplierRegionID: [result.supplierRegionID, Validators.required],
                        referenceDate: [this.referenceDate, Validators.required],
                        isAutomaticPlanned: [
                            result.isAutomaticPlanned,
                            Validators.required,
                        ],
                        planBeforeStoreOpening: [
                            result.planBeforeStoreOpening,
                            Validators.required,
                        ],
                        planPeriod: [result.planPeriod, Validators.required],
                        periodEndEarlier: [result.periodEndEarlier, Validators.required],
                        pickupTimeForDelivery: [
                            result.pickupTimeForDelivery,
                            Validators.required,
                        ],
                        shiftStartMinusMinutes: [
                            result.shiftStartMinusMinutes,
                            Validators.required,
                        ],
                        shiftEndAdditionalMinutes: [
                            result.shiftEndAdditionalMinutes,
                            Validators.required,
                        ],
                        shiftBreakAdditionalMinutes: [
                            result.shiftBreakAdditionalMinutes,
                            [Validators.required],
                        ],
                        deliveryDurationOnAddress: [
                            result.deliveryDurationOnAddress,
                            Validators.required,
                        ],
                        pickingDurationFactor: [
                            result.pickingDurationFactor,
                            Validators.required,
                        ],
                        pickingStartMinusMinutes: [
                            result.pickingStartMinusMinutes,
                            Validators.required,
                        ],
                        isPickingTime: [result.isPickingTime, Validators.required],
                        distanceCost: [result.distanceCost, Validators.required],
                        timeCost: [result.timeCost, Validators.required],
                        fixedCost: [result.fixedCost, Validators.required],
                        trafficConfiguration: [
                            result.trafficConfiguration,
                            Validators.required,
                        ],
                        waitingTimeReduceConfiguration: [
                            result.waitingTimeReduceConfiguration,
                            Validators.required,
                        ],
                        waitingTimeBufferTimeConfiguration: [
                            result.waitingTimeBufferTimeConfiguration,
                            Validators.required,
                        ],
                        capacity: [result.capacity, Validators.required],
                        isAsync: [result.isAsync, Validators.required],
                        isJustReservedPayments: [
                            result.isJustReservedPayments,
                            Validators.required,
                        ],
                        orderNumber: [result.orderNumber, Validators.required],
                    });
                },
                () => {
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    filterDelivererOnMap(delivererID: string) {
        if (this.selectedDelivererID == delivererID) {
            this.selectedDelivererID = null;
        } else {
            this.selectedDelivererID = delivererID;
        }
        this.gotoTopMap();
    }

    switchCorrigationFilter() {
        if (this.justCorrigationNeededOrders) {
            this.justCorrigationNeededOrders = false;
        } else {
            this.justCorrigationNeededOrders = true;
        }
    }

    public getReadableDateTime(dateTime: string) {
        return dateTime.replace('T', ' ').split('+')[0];
    }

    public hasCorrigationNeededInStopList(tourPlannerStopList: TourPlannerStop[]) {
        if (!this.justCorrigationNeededOrders) return true;
        let a;
        tourPlannerStopList.forEach(
            (y) => (a = y.tourPlannerActivityList.find((x) => x.isCorrigationNeeed))
        );

        if (a) {
            return true;
        } else {
            return false;
        }
    }

    public hasCorrigationNeeded(tourPlannerActivityList: TourPlannerActivity[]) {
        if (!this.justCorrigationNeededOrders) return true;
        var result = tourPlannerActivityList.find((x) => x.isCorrigationNeeed);
        if (result) {
            return true;
        } else {
            return false;
        }
    }

    gotoTopMap() {
        let el = document.getElementById('map');
        el.scrollTop = el.scrollHeight;
    }

    private sortDailyPlanByArrival(): void {
        this.savedTourPlannerDailyPlan.tourPlannerTourList.forEach((x) =>
            x.tourPlannerStopList.sort((a, b) => {
                return new Date(a.arrival).getTime() - new Date(b.arrival).getTime();
            })
        );
    }
}
