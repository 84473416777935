import { Component, OnInit } from '@angular/core';
import { SupplierRegionsService } from 'dm-src/app/modules/supplier-regions/supplier-regions.service';
import { ISelectOption } from 'shared';
import { TranslateService } from '@ngx-translate/core';
import { ISupplierRegionOption } from 'dm-src/types/i-supplier-region-option';
import { ModalService } from 'shared';
import { ColorClass } from 'shared';
import { FlashMessageService } from 'shared';

@Component({
    selector: 'app-supplier-regions',
    templateUrl: './supplier-regions.component.html',
    styleUrls: ['./supplier-regions.component.scss'],
})
export class SupplierRegionsComponent implements OnInit {
    public regionsSettings = {};
    public zipCodesSettings = {};
    public supplierRegions: ISupplierRegionOption[];
    public availableZipCodes: ISelectOption[];
    public ColorClass = ColorClass;
    public regionsModel: ISelectOption[];
    public zipCodesModel: ISelectOption[];
    public isSaveInProgress = false;
    public isDeleteInProgress = false;
    public isSelectedRegionSaved = true;
    public _lat: number;
    public _long: number;

    constructor(
        private _translateService: TranslateService,
        private _flashMessageService: FlashMessageService,
        public supplierRegionsService: SupplierRegionsService,
        public modalService: ModalService
    ) {
        this.zipCodesModel = [];
        this.regionsModel = [];
    }

    ngOnInit(): void {
        this.supplierRegionsService.getSupplierRegionsData();
        this.supplierRegionsService.selectedZipCodes.subscribe(
            (selectedZipCodes) => (this.zipCodesModel = selectedZipCodes)
        );

        this.supplierRegionsService.supplierRegionOptions.subscribe(
            (regions) => (this.supplierRegions = regions)
        );

        this.supplierRegionsService.availableZipCodes.subscribe(
            (availableZipCodes) => (this.availableZipCodes = availableZipCodes)
        );

        this.regionsSettings = this.getRegionsSettings();
        this.zipCodesSettings = this.getZipCodesSettings();
    }

    public onRegionSelect(supplierRegion: ISupplierRegionOption): void {
        this.isSelectedRegionSaved = supplierRegion.id !== undefined;
        this.supplierRegionsService.changeSelectedRegion(supplierRegion.id);
        this._lat = supplierRegion.lat;
        this._long = supplierRegion.long;
    }

    public onZipCodesSelect(zipCode: ISelectOption) {
        this.supplierRegionsService.assignZipCode(zipCode);
    }

    public onZipCodeUnassignment(zipCodeID: string) {
        this.supplierRegionsService.unassignZipCode(zipCodeID);
    }

    public onRegionCreated(newRegionName: string) {
        this.supplierRegionsService.addNewSupplierRegion(newRegionName);
    }

    public onRegionsSave() {
        this.isSaveInProgress = true;
        this.supplierRegionsService.saveRegions().subscribe((response) => {
            this.isSaveInProgress = false;
            this.isSelectedRegionSaved = true;
            if (response.status !== 200) {
                this._flashMessageService.showStoredMessage();
            } else if (this.regionsModel[0].id === undefined) {
                this.regionsModel[0].id = response.body.find(
                    (x) => x.regionName === this.regionsModel[0].label
                ).supplierRegionID;
            }
        });
    }

    public onRegionDeleteConfirmed() {
        this.modalService.setModalVisibility(false, 'delete-region-confirmation');
        this.isDeleteInProgress = true;
        this.supplierRegionsService
            .deleteRegion(this.regionsModel[0].id)
            .subscribe((response) => {
                this.isDeleteInProgress = false;
                if (response.status !== 200) {
                    this._flashMessageService.showStoredMessage();
                } else {
                    this.regionsModel = [];
                }
            });
    }

    private getRegionsSettings(): object {
        return {
            enableSearchFilter: true,
            addNewItemOnFilter: true,
            singleSelection: true,
            labelKey: 'label',
            primaryKey: 'id',
            searchBy: ['label'],
            text: this._translateService.instant('supplier-regions.choose-region'),
            searchPlaceholderText: this._translateService.instant('common.search'),
            noDataLabel: this._translateService.instant(
                'supplier-regions.no-region-found'
            ),
        };
    }

    private getZipCodesSettings(): object {
        return {
            singleSelection: false,
            enableSearchFilter: true,
            labelKey: 'label',
            primaryKey: 'id',
            searchBy: ['label'],
            lazyLoading: true,
            badgeShowLimit: 0,
            enableCheckAll: false,
            text: this._translateService.instant('supplier-regions.assign-zip-codes'),
            searchPlaceholderText: this._translateService.instant('common.search'),
            noDataLabel: this._translateService.instant(
                'supplier-regions.no-region-selected'
            ),
        };
    }

    public onChangeLatLon() {
        this.supplierRegionsService.assignLatLong(this._lat, this._long);
    }
}
