<div class="container position-relative" *appHasRoles="['administrator', 'support']">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-6">
            <div class="card">
                <div class="card-header bg-secondary">Order delivery details</div>
                <div class="card-body order-delivery-details">
                    <h6>Other delivery data:</h6>
                    <i>Under development....</i>
                    <hr />
                    <ng-container *ngIf="selectedOrder">
                        <h6>Delivery time informations:</h6>
                        <p>
                            <span>Estimated Delivery End:</span><br />
                            <span>Original: </span>
                            <strong>{{
                                selectedOrder.estimatedDeliveryEnd
                                    | amDateFormat : deliveryDateFormat
                            }}</strong>
                        </p>
                        <div class="row">
                            <div class="col-8">
                                <input
                                    class="form-control"
                                    type="datetime-local"
                                    [value]="estimatedDeliveryEndEditable"
                                    (change)="
                                        estimatedDeliveryEndEditable = $event.target.value
                                    "
                                />
                                &nbsp;
                            </div>
                            <div class="col-4">
                                <button
                                    class="btn btn-success"
                                    (click)="changeEstimatedDeliveryEnd()"
                                >
                                    Save
                                    <i class="fa fa-save"></i>
                                </button>
                            </div>
                        </div>
                        <p>
                            <span>Estimated Picking End:</span>
                            <strong>
                                {{
                                    selectedOrder.estimatedPickingEnd
                                        | amDateFormat : deliveryDateFormat
                                }}
                            </strong>
                        </p>
                        <p>
                            <span>Estimated Picking Start:</span>
                            <strong>
                                {{
                                    selectedOrder.estimatedPickingStart
                                        | amDateFormat : deliveryDateFormat
                                }}
                            </strong>
                        </p>
                        <p>
                            <span>Real Delivery End:</span>
                            <strong>
                                {{
                                    selectedOrder.realDeliveryEnd
                                        | amDateFormat : deliveryDateFormat
                                }}
                            </strong>
                        </p>
                        <p>
                            <span>Real Picking End:</span>
                            <strong>
                                {{
                                    selectedOrder.realPickingEnd
                                        | amDateFormat : deliveryDateFormat
                                }}
                            </strong>
                        </p>
                        <p>
                            <span>Real Picking Start:</span>
                            <strong>
                                {{
                                    selectedOrder.realPickingStart
                                        | amDateFormat : deliveryDateFormat
                                }}
                            </strong>
                        </p>
                    </ng-container>

                    <hr />
                    <ng-container *ngIf="selectedOrder">
                        <h6>Reserved Timeslot:</h6>
                        <strong>
                            {{ selectedOrder.deliveryDay }}
                            {{ selectedOrder.deliveryTimeSlot }}
                        </strong>
                        <br />
                        <span
                            *ngIf="selectedOrder.isSuperOrder"
                            class="text-danger font-italic"
                        >
                            It's currently not possible to change the timeslot of a multi
                            order.
                        </span>
                        <br />
                        <button
                            class="btn btn-danger w-100"
                            [disabled]="loading || selectedOrder.isSuperOrder"
                            (click)="changeTimeslot()"
                        >
                            <i class="fa fa-edit"></i>
                            Change timeslot
                        </button>
                    </ng-container>
                    <ng-container *ngIf="timeSlotList">
                        <br />
                        <br />
                        <h5>Available Timeslots:</h5>
                        <br />
                        <div class="d-inline">
                            <ng-container *ngFor="let timeSlot of timeSlotList">
                                <div
                                    class="timeslot-badge badge badge-success p-4 m-2"
                                    *ngIf="timeSlot.isAvailable"
                                    (click)="selectThisTimeslot(timeSlot)"
                                >
                                    {{ timeSlot.date }}
                                </div>
                                <div
                                    class="timeslot-badge badge badge-secondary p-4 m-2"
                                    *ngIf="!timeSlot.isAvailable"
                                    (click)="selectThisTimeslot(timeSlot)"
                                >
                                    {{ timeSlot.date }}
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="position-absolute w-100 spinner-container bg-white p-4" *ngIf="loading">
        <div class="d-flex justify-content-center w-00">
            <mat-spinner></mat-spinner>
        </div>
        <p class="w-100 text-center">Waiting...</p>
    </div>
</div>
