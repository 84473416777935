import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService, OrderItem } from 'shared';
import { ColorClass } from 'shared';
import { CollectingItemsService } from '../collecting-items.service';
import { CollectingStatesService } from '../collecting-states.service';
import { OfflineProductsService } from '../offline-products.service';
import { NativeBarcodeReaderService } from 'dm-src/services/native-barcode-reader/native-barcode-reader.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';

@Component({
    selector: 'app-collecting-modals',
    templateUrl: './collecting-modals.component.html',
    styleUrls: ['./collecting-modals.component.css'],
})
export class CollectingModalsComponent implements OnInit {
    public ColorClass = ColorClass;

    constructor(
        private _router: Router,
        public states: CollectingStatesService,
        public collectingItemsService: CollectingItemsService,
        public offlineProductsService: OfflineProductsService,
        public modalService: ModalService,
        private _nativeBarcodeReaderService: NativeBarcodeReaderService,
        private _isWrappingService: IsWrappingService
    ) {}

    ngOnInit(): void {}

    public onTaskFailSet(state: number): void {
        if (state === 200) {
            this._router.navigate(['my-tasks']);
        }
    }
}
