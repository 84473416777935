<div class="container">
    <div class="card card-container">
        <h1 class="card-title">{{ 'login.forgot-password' | translate }}</h1>
        <h3 class="card-title">{{ 'login.please-provide-email' | translate }}</h3>
        <div *ngIf="showSuccess" class="alert alert-success" role="alert">
            {{ 'userform-common.success-body' | translate }}
        </div>
        <form
            [formGroup]="forgotPasswordForm"
            (ngSubmit)="onSubmitForgotPassword()"
            class="form-forgotpassword"
        >
            <span id="reauth-email" class="reauth-email"></span>
            <input
                type="email"
                id="inputEmail"
                formControlName="email"
                class="form-control"
                placeholder="{{ 'common.email' | translate }}"
                required
                autofocus
                [ngClass]="{ 'is-invalid': isSubmitted && fields.email.errors }"
            />
            <div *ngIf="isSubmitted && fields.email.errors" class="invalid-feedback">
                <div *ngIf="fields.email.errors.required">
                    {{ 'login.email-required' | translate }}
                </div>
            </div>
            <button class="btn btn-lg btn-primary btn-block btn-signin" type="submit">
                {{ 'common.submit' | translate }}
            </button>
        </form>
    </div>
</div>
