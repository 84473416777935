import { AUTH_SERVICE_IMPL } from '../services/authentication/iauthentication.service';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FlashMessageService } from '../modules/flash-message/flash-message.service';
import { ColorClass } from '../types/color-class';

@Injectable()
export class HttpStatusInterceptor implements HttpInterceptor {
    private _flashMessageService: FlashMessageService;

    get flashMessageService(): FlashMessageService {
        if (this._flashMessageService === undefined) {
            this._flashMessageService = this._injector.get(FlashMessageService);
        }
        return this._flashMessageService;
    }
    constructor(private _injector: Injector, private _router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                const error = err.error || err.message || err.statusText;

                switch (err.status) {
                    case 401:
                        const isForcedPassChange =
                            Object.prototype.toString.call(err.error) ===
                                '[object String]' &&
                            err.error?.includes('employee-passchange-forced');
                        if (
                            err.error !== 'employee-logout-forced' &&
                            !isForcedPassChange
                        ) {
                            this.removeUserFromStorage();
                            this.showErrorMessage('common.unauthorized', error);
                            this._router.navigate(['/login']);
                        }
                        break;
                    case 403:
                        this._router.navigate(['/']);
                        this.showErrorMessage('common.access-forbidden', error?.message);
                        break;
                    default:
                        this.showErrorMessage('common.error', error?.message);
                        break;
                }

                return throwError(err);
            })
        );
    }

    private removeUserFromStorage(): void {
        setTimeout(() => {
            const authService = this._injector.get(AUTH_SERVICE_IMPL);
            authService.removeUserFromStorage();
        }, 0);
    }

    private showErrorMessage(title: string, error: string): void {
        this.flashMessageService.storeMessage(title, error, ColorClass.Danger);
    }
}
