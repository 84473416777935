import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-btn-action-container',
    templateUrl: './btn-action-container.component.html',
    styleUrls: ['./btn-action-container.component.scss'],
})
export class BtnActionContainerComponent {
    constructor() {}
}
