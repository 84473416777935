import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'quantity',
    templateUrl: './quantity.component.html',
    styleUrls: ['./quantity.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuantityComponent,
        },
    ],
})
export class QuantityComponent implements ControlValueAccessor {
    @Input() orderItem: any;
    onChange: (count: number) => void = (_: number) => {};

    quantity = 0;

    onAdd() {
        this.quantity = Number(this.quantity) + 1;
        this.onChange(this.quantity);
    }

    onRemove() {
        if (this.quantity > 0) {
            this.quantity--;
            this.onChange(this.quantity);
        }
    }

    writeValue(counter: number) {
        this.quantity = counter;
    }

    registerOnChange(onChange: (count: number) => void) {
        this.onChange = onChange;
    }

    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}
}
