import { IProviderShopService } from './iprovidershop-service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ProviderShop } from '../../models/provider-shop';

import { API_CLIENT_SERVICE_IMPL, IAPIClientService, ISelectOption } from 'shared';

@Injectable()
export class ProviderShopService implements IProviderShopService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getProviderShopOptions(): Observable<HttpResponse<ISelectOption[]>> {
        return this._apiClient.getWithResponse<ISelectOption[]>(
            `providershop/get-provider-shop-options`
        );
    }
}
