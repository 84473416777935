<form class="form-horizontal" [formGroup]="accountDataForm" (ngSubmit)="onSubmit()">
    <div class="form-group is-invalid">
        <label for="name" class="cols-sm-2 control-label">Your Name</label>
        <div class="cols-sm-10">
            <div class="input-group is-invalid">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fa fa-user" aria-hidden="true"></i>
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    formControlName="firstName"
                    id="first-name"
                    placeholder="Enter your First Name"
                />
            </div>
            <div *ngIf="isSubmitted && fields.firstName.errors" class="invalid-feedback">
                <div *ngIf="fields.firstName.errors.required">First name is required</div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="name" class="cols-sm-2 control-label">Your Last Name</label>
        <div class="cols-sm-10">
            <div class="input-group is-invalid">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fa fa-user" aria-hidden="true"></i>
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    formControlName="lastName"
                    id="name"
                    placeholder="Enter your Last Name"
                />
            </div>
            <div *ngIf="isSubmitted && fields.lastName.errors" class="invalid-feedback">
                <div *ngIf="fields.lastName.errors.required">Last name is required</div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="email" class="cols-sm-2 control-label">Your Email</label>
        <div class="cols-sm-10">
            <div class="input-group is-invalid">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                    </div>
                </div>
                <input
                    type="email"
                    class="form-control"
                    formControlName="email"
                    id="email"
                    placeholder="Enter your Email"
                />
            </div>
            <div *ngIf="isSubmitted && fields.email.errors" class="invalid-feedback">
                <div *ngIf="fields.email.errors.required">Email is required</div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="username" class="cols-sm-2 control-label">Username</label>
        <div class="cols-sm-10">
            <div class="input-group is-invalid">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fa fa-users" aria-hidden="true"></i>
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    formControlName="username"
                    id="username"
                    placeholder="Enter your Username"
                />
            </div>
        </div>
        <div *ngIf="isSubmitted && fields.username.errors" class="invalid-feedback">
            <div *ngIf="fields.username.errors.required">Username is required</div>
        </div>
    </div>
    <div class="form-group">
        <label for="password" class="cols-sm-2 control-label">Password</label>
        <div class="cols-sm-10">
            <div class="input-group is-invalid">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fa fa-lock fa-lg" aria-hidden="true"></i>
                    </div>
                </div>
                <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    id="password"
                    placeholder="Enter your Password"
                />
            </div>
            <small id="passwordHelp" class="form-text text-muted">{{
                'my-account.password-help' | translate
            }}</small>
        </div>
    </div>
    <div class="form-group">
        <label for="repassword" class="cols-sm-2 control-label">Confirm Password</label>
        <div class="cols-sm-10">
            <div class="input-group is-invalid">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fa fa-lock fa-lg" aria-hidden="true"></i>
                    </div>
                </div>
                <input
                    type="password"
                    class="form-control"
                    formControlName="repassword"
                    id="repassword"
                    placeholder="Confirm your Password"
                />
            </div>
            <div *ngIf="isSubmitted && fields.repassword.errors" class="invalid-feedback">
                <div *ngIf="fields.repassword.errors.required">
                    Password confirmation is required
                </div>
                <div *ngIf="fields.repassword.errors.mustMatch">
                    Password and password confirmation must be the same.
                </div>
            </div>
        </div>
    </div>
    <div class="form-group mt-2">
        <button type="submit" class="btn btn-primary btn-lg btn-block login-button">
            {{ 'common.save' | translate }}
        </button>
    </div>
</form>
