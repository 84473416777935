import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IForgotPassword } from '../../../dtos/i-forgot-password';
import {
    ACCOUNTS_SERVICE_IMPL,
    IAccountsService,
} from '../../../services/accounts/iaccounts.service';
import { FlashMessageService } from '../../flash-message/flash-message.service';

@Component({
    selector: 'lib-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    @Input() callerProject: string;

    public forgotPasswordForm: UntypedFormGroup;
    public isSubmitted: boolean;
    public showSuccess: boolean;

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountsService: IAccountsService,
        private _formBuilder: UntypedFormBuilder,
        private _flashMessageService: FlashMessageService
    ) {}

    public get fields() {
        return this.forgotPasswordForm.controls;
    }

    ngOnInit(): void {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', Validators.required],
        });
    }

    onSubmitForgotPassword(): void {
        this.isSubmitted = true;
        this.showSuccess = false;

        if (this.forgotPasswordForm.invalid) {
            return;
        }

        const forgotPassDto: IForgotPassword = {
            email: this.fields.email.value,
            clientURI: location.origin + '/login/changepassword',
            callerApplication: this.callerProject,
        };

        this._accountsService.forgotPassword(forgotPassDto).subscribe(
            (res) => {
                this.showSuccess = true;
            },
            (error) => {
                this._flashMessageService.showStoredMessage('forgot-password');
            }
        );
    }
}
