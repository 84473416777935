import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderOrdersComponent } from './provider-orders.component';
import { ListModule, FlashMessageModule, LibCommonModule } from 'shared';
import { ProviderOrderDetailsModalComponent } from './provider-order-details-modal/provider-order-details-modal.component';
import { PreloaderModule } from 'dm-src/app/modules/preloader/preloader.module';
import { NgbNavModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';

@NgModule({
    declarations: [ProviderOrdersComponent, ProviderOrderDetailsModalComponent],
    imports: [
        CommonModule,
        ListModule,
        PreloaderModule,
        NgbPaginationModule,
        AppSharedModule,
        ListModule,
        FlashMessageModule,
        LibCommonModule,
        NgbNavModule,
    ],
})
export class ProviderOrdersModule {}
