import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { User } from '../../../../../shared/src/lib/models/user';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from 'shared';
import { IAuthenticationService, AUTH_SERVICE_IMPL } from 'shared';
import { UserRole } from 'shared';
import { UsersService } from './users.service';

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit, OnDestroy {
    public users: User[];
    public selectedUser: User;
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    public canEditUserName = false;

    constructor(
        private _usersService: UsersService,
        public modalService: ModalService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {}

    ngOnInit() {
        const currentUser = this._authService.currentUser;

        this._usersService.users
            .pipe(takeUntil(this.destroyed$))
            .subscribe((users) => (this.users = users));

        this._usersService.selectedUser
            .pipe(takeUntil(this.destroyed$))
            .subscribe((selectedUser) => (this.selectedUser = selectedUser));

        this.canEditUserName = currentUser.hasRoles([
            UserRole.Administrator,
            UserRole.Supporter,
        ]);
    }

    public loadInactivateCofirmModal(user: User) {
        this._usersService.setSelectedUser(user);
        this.modalService.setModalVisibility(true, 'inactivate-user');
    }

    public inactivateUser(): void {
        this._usersService.inactivateUser(this.selectedUser.userID);
        const index = this.users.indexOf(this.selectedUser);
        this.users.splice(index, 1);
        this.modalService.setModalVisibility(false, 'inactivate-user');
    }

    public loadUserNameEditModal(user: User) {
        this._usersService.setSelectedUser(user);
        this.modalService.setModalVisibility(true, 'edit-username');
    }

    public saveUserName() {
        this._usersService.editUserName(
            this.selectedUser.userID,
            this.selectedUser.username
        );
        this.modalService.setModalVisibility(false, 'edit-username');
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
