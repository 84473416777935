import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { AccountSupplierDataService } from 'dm-src/app/modules/my-account/account-supplier-data/account-supplier-data.service';
import { ColorClass } from 'shared';
import { skip, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-supplier-address-approval-modal',
    templateUrl: './supplier-address-approval-modal.component.html',
    styleUrls: ['./supplier-address-approval-modal.component.scss'],
})
export class SupplierAddressApprovalModalComponent implements OnInit, OnDestroy {
    private _destroy$: ReplaySubject<boolean>;
    public ColorClass = ColorClass;

    @Output() onAddressApprove = new EventEmitter<void>();
    @Output() onCancel = new EventEmitter<void>();

    @ViewChild('supplierAddressApprovalModal', { static: false })
    private _collectApprovalModal: TemplateRef<any>;

    constructor(
        private _modalService: NgbModal,
        public supplierDataService: AccountSupplierDataService
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit(): void {
        this.supplierDataService.isAddressApprovalModalVisible
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isVisible) => {
                if (isVisible.valueOf()) {
                    this._modalService.open(this._collectApprovalModal, {
                        windowClass: 'modal-holder',
                        centered: true,
                        size: 'sm',
                    });
                } else {
                    this._modalService.dismissAll();
                }
            });
    }

    public approve(): void {
        this.onAddressApprove.emit();
        this.supplierDataService.setAddressApprovalModalVisible(false);
    }

    public closeModal() {
        this.supplierDataService.setAddressApprovalModalVisible(false);
        this.onCancel.emit();
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
    }
}
