<div class="card bg-danger text-white">
    <div class="card-header">Multi order info</div>
    <div class="card-body order-details">
        <div class="pb-3">Super order code: {{ superOrderCode }}</div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Order Code</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let orderCode of orderCodeList; let i = index"
                    [attr.data-index]="i + 1"
                >
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ orderCode }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
