<ng-template #taskStateModal let-modal>
    <div class="modal-header modal-fullscreen">
        <h4 class="modal-title" id="modal-basic-title">Set task state</h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-12">
            <label>Task state</label>
            <ng-select [(ngModel)]="selectedTaskState" (change)="setState()">
                <ng-option
                    *ngFor="let taskState of availableTaskStates"
                    [value]="taskState"
                >
                    {{ DeliveryTaskState[taskState] | humancase }}
                </ng-option>
            </ng-select>
        </div>
    </div>
</ng-template>
