import { Component, Input } from '@angular/core';
import { TasksService } from '../tasks.service';
import { Modal } from 'dm-src/types/modal';
import { TaskListItem } from 'shared';

@Component({
    selector: 'app-user-chooser',
    templateUrl: './user-chooser.component.html',
    styleUrls: ['./user-chooser.component.scss'],
})
export class UserChooserComponent {
    @Input() username: string;
    @Input() task: TaskListItem;

    constructor(private _tasksService: TasksService) {}

    public showUsers() {
        this._tasksService.selectTask(this.task);
        this._tasksService.setVisibleModal(Modal.AssignTaskToUser);
    }
}
