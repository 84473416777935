import { Component, Input, OnInit } from '@angular/core';
import { TaskGroup } from 'dm-src/types/task-group';

@Component({
    selector: '[task-group]',
    templateUrl: './task-group.component.html',
    styleUrls: ['./task-group.component.scss'],
})
export class TaskGroupComponent implements OnInit {
    @Input() public taskGroup: TaskGroup;
    @Input() public isCollapsed = true;
    @Input() public hasFrame = true;
    public dateFormat: string;

    constructor() {}

    ngOnInit(): void {}
}
