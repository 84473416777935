import { NgModule } from '@angular/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CommonModule } from '@angular/common';
import { CollectingComponent } from './collecting.component';
import { OrderItemComponent } from './order-items-container/order-item/order-item.component';
import { OrderItemsContainerComponent } from './order-items-container/order-items-container.component';
import { OrderItemDetailsComponent } from './order-items-container/order-item/order-item-details/order-item-details.component';
import { ItemsToCollectButtonsComponent } from './order-items-container/order-item/items-to-collect-buttons/items-to-collect-buttons.component';
import { ReplacedItemsButtonsComponent } from './order-items-container/order-item/replaced-items-buttons/replaced-items-buttons.component';
import { CollectedItemsButtonsComponent } from './order-items-container/order-item/collected-items-buttons/collected-items-buttons.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhotoButtonComponent } from './buttons/photo-button/photo-button.component';
import { CollectApprovalModalComponent } from './collecting-modals/collect-approval-modal/collect-approval-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReplacementEditorModalComponent } from './collecting-modals/replacement-editor-modal/replacement-editor-modal.component';
import { ItemsNotFoundButtonsComponent } from './order-items-container/order-item/items-not-found-buttons/items-not-found-buttons.component';
import { BillApprovalModalComponent } from './collecting-modals/bill-approval-modal/bill-approval-modal.component';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { CreateItemModalComponent } from './collecting-modals/create-item-modal/create-item-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CollectingDoneFailedModalComponent } from './collecting-modals/collecting-done-failed-modal/collecting-done-failed-modal.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { BarcodeReaderModule, ModalModule, SharedModule } from 'shared';
import { RouterModule } from '@angular/router';
import { CallSupportBtnComponent } from './buttons/call-support-btn/call-support-btn.component';
import { CheckoutWarningModalComponent } from './collecting-modals/checkout-warning-modal/checkout-warning-modal.component';
import { CollectingModalsComponent } from './collecting-modals/collecting-modals.component';
import { ActionsToolbarComponent } from './actions-toolbar/actions-toolbar.component';
import { BarcodeReaderModalComponent } from './collecting-modals/barcode-reader-modal/barcode-reader-modal.component';
import { InvalidOfflineProductModalComponent } from './collecting-modals/invalid-offline-product-modal/invalid-offline-product-modal.component';
import { UnidentifiedItemModalComponent } from './collecting-modals/unidentified-item-modal/unidentified-item-modal.component';
import { ManualSkuCheckModalComponent } from './collecting-modals/manual-sku-check-modal/manual-sku-check-modal.component';
import { ProductIdentifyModalComponent } from './collecting-modals/product-identify-modal/product-identify-modal.component';
import { BtnProgressModule } from 'shared';
import { CollectingButtonsComponent } from './collecting-buttons/collecting-buttons.component';
import { MarkupCollectingButtonsComponent } from './markup-collecting-buttons/markup-collecting-buttons.component';
import { CheckoutQrModalComponent } from './collecting-modals/checkout-qr-modal/checkout-qr-modal.component';
import { FoundProductChooserModalComponent } from './collecting-modals/found-product-chooser-modal/found-product-chooser-modal.component';
import { FoundOfflineProductComponent } from './collecting-modals/found-product-chooser-modal/found-offline-product/found-offline-product.component';
import { CustomerCardContainerComponent } from './customer-card-container/customer-card-container.component';
import { CustomerCardComponent } from './customer-card-container/customer-card/customer-card.component';
import { BtnCloseModule } from 'shared';
import { QuantityModule } from 'shared';

@NgModule({
    declarations: [
        CollectingComponent,
        OrderItemComponent,
        OrderItemsContainerComponent,
        OrderItemDetailsComponent,
        ItemsToCollectButtonsComponent,
        ReplacedItemsButtonsComponent,
        CollectedItemsButtonsComponent,
        PhotoButtonComponent,
        CollectApprovalModalComponent,
        ReplacementEditorModalComponent,
        ItemsNotFoundButtonsComponent,
        BillApprovalModalComponent,
        CreateItemModalComponent,
        CollectingDoneFailedModalComponent,
        CallSupportBtnComponent,
        CheckoutWarningModalComponent,
        CollectingModalsComponent,
        ActionsToolbarComponent,
        BarcodeReaderModalComponent,
        InvalidOfflineProductModalComponent,
        UnidentifiedItemModalComponent,
        ManualSkuCheckModalComponent,
        ProductIdentifyModalComponent,
        CollectingButtonsComponent,
        MarkupCollectingButtonsComponent,
        CheckoutQrModalComponent,
        FoundProductChooserModalComponent,
        FoundOfflineProductComponent,
        CustomerCardContainerComponent,
        CustomerCardComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        CollapseModule.forRoot(),
        ReactiveFormsModule,
        AppSharedModule,
        FormsModule,
        NgSelectModule,
        NgbAlertModule,
        TranslateModule,
        ModalModule,
        RouterModule,
        BtnProgressModule,
        SharedModule,
        BtnCloseModule,
        QuantityModule,
    ],
})
export class CollectingModule {}
