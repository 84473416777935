<td [ngClass]="{ collapsed: !hasSelectedTasks }"></td>
<td [ngClass]="{ collapsed: !hasSelectedTasks }"></td>
<td [ngClass]="{ collapsed: !hasSelectedTasks }"></td>
<td [ngClass]="{ collapsed: !hasSelectedTasks }"></td>
<td [ngClass]="{ collapsed: !hasSelectedTasks }"></td>
<td [ngClass]="{ collapsed: !hasSelectedTasks }"></td>
<td [ngClass]="{ collapsed: !hasSelectedTasks }">
    <a
        class="badge badge-primary"
        (click)="tasksService.setVisibleModal(Modal.AssignTaskToUser)"
        >{{ 'tasks.manage-assignments' | translate }}</a
    >
</td>
