import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    public message: string;
    private _componentID = 'main';
    private _isModalVisible: BehaviorSubject<boolean>;

    public get isModalVisible(): Observable<boolean> {
        return this._isModalVisible.asObservable();
    }

    public get componentID(): string {
        return this._componentID;
    }

    constructor() {
        this._isModalVisible = new BehaviorSubject<boolean>(false);
    }

    public setModalVisibility(isVisible: boolean, componentID: string = null): void {
        if (componentID !== null) {
            this._componentID = componentID;
        }
        this._isModalVisible.next(isVisible);
    }

    public setModalMessage(message: string): void {
        this.message = message;
    }
}
