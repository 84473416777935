import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { UserSetting } from '../../models/user-setting';
export const USER_SETTINGS_IMPL = new InjectionToken<IUserSettingsService>(
    'userSettingsServiceImpl'
);

export interface IUserSettingsService {
    getUserSettings(
        userID: string,
        section: string,
        key: string
    ): Observable<HttpResponse<UserSetting[]>>;
    setUserSettings(userSetting: UserSetting): Observable<HttpResponse<void>>;
}
