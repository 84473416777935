import { AppSettings } from '../../appsettings';

export const realRound = (num: number, decimalNumber = 2) => {
    const mul = Math.pow(10, decimalNumber);
    return Math.round((num + Number.EPSILON) * mul) / mul;
};

export const priceRound = (num: number) => {
    const decimalNumber = parseInt(AppSettings.get('currencyRoundDecimalNumber'));
    return realRound(num, decimalNumber);
};
