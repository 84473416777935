<div class="card text-white bg-success">
    <div class="card-header" (click)="isCollapsed = !isCollapsed">
        <span [innerHtml]="'my-tasks.refunding-products' | translate"></span>,
        <span
            ><strong>{{ task.providerName }}</strong></span
        >
    </div>
    <div class="card-body text-center" [collapse]="isCollapsed" [isAnimated]="true">
        <h5 class="card-title">{{ task.customerName }}</h5>
        <table class="table table-striped table-responsive table-borderless text-white">
            <tbody>
                <tr>
                    <th scope="row">
                        {{ 'my-tasks.total-refunding-price' | translate }}:
                    </th>
                    <td>
                        {{ task.totalRefundPrice }}
                        {{ 'common.currency' | translate }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ 'common.provider-shop' | translate }}:</th>
                    <td>
                        {{ task.providerShop }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ 'common.payment-type' | translate }}:</th>
                    <td>
                        {{
                            'payment-type.' + PaymentType[task.paymentType].toLowerCase()
                                | translate
                        }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ 'common.phone' | translate }}:</th>
                    <td>
                        {{ task.customerPhone }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ 'common.notes' | translate }}:</th>
                    <td>
                        {{ task.notes }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" [collapse]="isCollapsed" [isAnimated]="true">
        <app-refunding-task-button [task]="task"></app-refunding-task-button>
    </div>
</div>
