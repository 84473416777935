import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout.component';
import { SharedModule } from 'shared';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
    declarations: [
        LoginComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
    ],
    imports: [CommonModule, SharedModule, SharedModule],
    exports: [LoginComponent],
})
export class LoginModule {}
