<div class="container">
    <div class="row justify-content-center">
        <div class="col-12" [ngClass]="{ 'col-lg-6': !isEditMode }">
            <div class="card">
                <div class="card-header bg-secondary">Order details</div>
                <div class="card-body order-details" *ngIf="orderDetails | async">
                    <div class="d-flex flex-row">
                        {{ 'my-tasks.bag' | translate }}:&nbsp;
                        <div *ngIf="(orderDetails | async).usePaperBag; else multiUseBag">
                            {{ 'my-tasks.paper-bag' | translate }}
                        </div>
                        <ng-template #multiUseBag>
                            <td *ngIf="(orderDetails | async).usePaperBag === false">
                                {{ 'my-tasks.multiuse-bag' | translate }}
                            </td>
                        </ng-template>
                    </div>
                    <div class="d-flex flex-row">
                        {{ 'common.payment-type' | translate }}:&nbsp;
                        <div>
                            {{
                                'payment-type.' +
                                    PaymentType[
                                        (orderDetails | async).paymentType
                                    ].toLowerCase() | translate
                            }}
                        </div>
                    </div>
                    <div class="d-flex flex-row">
                        {{ 'collecting.consultation-type' | translate }}:&nbsp;
                        <div>
                            {{ (orderDetails | async).consultationType | translate }}
                        </div>
                    </div>
                    <br />
                    <app-task-details-item
                        *ngFor="let taskInfo of (orderDetails | async).deliveryTasks"
                        [taskInfo]="taskInfo"
                    >
                    </app-task-details-item>
                    <app-customer-notes
                        [notes]="(orderDetails | async).orderNotes"
                    ></app-customer-notes>
                    <div class="w-100 my-1">
                        <app-support-comment
                            [notes]="(orderDetails | async).supportComment"
                        ></app-support-comment>
                    </div>
                    <div *ngIf="(orderDetails | async).isSuperOrder" class="w-100 my-1">
                        <app-multi-order-info
                            [superOrderCode]="(orderDetails | async).superOrderCode"
                            [orderCodeList]="(orderDetails | async).orderCodeList"
                        ></app-multi-order-info>
                    </div>
                </div>
                <div class="card-footer" *ngIf="areDeliveryTaskButtonsVisible">
                    <div class="row text-center">
                        <lib-btn-progress
                            class="col-lg-6 mt-1 mb-1"
                            [inProgress]="collectingTaskCreationInProgress"
                            *ngIf="!hasCollectingTask"
                            (onClick)="createCollectingTask()"
                            [btnClass]="'btn btn-block btn-sm btn-info'"
                            >{{
                                'orders.create-collecting-task' | translate
                            }}</lib-btn-progress
                        >
                        <br />
                        <lib-btn-progress
                            class="col-lg-6 mt-1 mb-1"
                            [inProgress]="containerPlacingTaskCreationInProgress"
                            *ngIf="!hasContainerPlacingTask"
                            (onClick)="createContainerPlacingTask()"
                            [btnClass]="'btn btn-block btn-sm btn-info'"
                            >{{
                                'orders.create-container-placing-task' | translate
                            }}</lib-btn-progress
                        >
                        <br />
                        <lib-btn-progress
                            class="col-lg-6 mt-1 mb-1"
                            [inProgress]="deliveringTaskCreationInProgress"
                            *ngIf="!hasDeliveringTask"
                            (onClick)="createDeliveringTask()"
                            [btnClass]="'btn btn-block btn-sm btn-warning'"
                            >{{
                                'orders.create-delivering-task' | translate
                            }}</lib-btn-progress
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
