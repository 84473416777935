import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TasksService } from './tasks.service';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
    ISelectOption,
    ListService,
} from 'shared';
import { environment } from 'dm-src/environments/environment';
import { ProgressLineService, BaseList, FilterEvent } from 'shared';
import { columns } from './tasks.table.columns';

@Component({
    selector: 'app-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent extends BaseList implements OnInit, AfterViewInit, OnDestroy {
    public dateFormat: string;
    public isReady: boolean;
    public areColumnsReady: boolean;
    public visibleColumnIndexes: number[];
    public selectedColumnKeys: string;

    constructor(
        public tasksService: TasksService,
        public listService: ListService,
        private _progressLineService: ProgressLineService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {
        super(columns);
        this.isReady = false;
        this.dateFormat = environment.deliveryDateFormat;
    }

    ngAfterViewInit(): void {
        this.isReady = true;
        this._progressLineService.hideProgressLine();
    }

    ngOnInit(): void {
        this.tasksService.initTasksList();
        this.tasksService.filterOptions.subscribe(
            (filterOptions) => {
                if (filterOptions) {
                    this.setColumnProperty(
                        ['UserID'],
                        'filterOptions',
                        filterOptions.filterUsers
                    );
                    this.setColumnProperty(
                        ['Order.ProviderID'],
                        'filterOptions',
                        filterOptions.providers
                    );

                    this.setColumnProperty(
                        ['Order.DeliveryAddress.PostalCode'],
                        'filterOptions',
                        filterOptions.zipList
                    );

                    this.setColumnProperty(
                        ['timeslotLength'],
                        'filterOptions',
                        filterOptions.timeSlotList
                    );
                    this.setColumnProperty(
                        ['Order.TimeSlotStartHour'],
                        'filterOptions',
                        filterOptions.timeSlotList
                    );
                    this.setColumnProperty(
                        ['Order.TimeSlotEndHour'],
                        'filterOptions',
                        filterOptions.timeSlotList
                    );

                    this.setUserSelectedColumns();
                }
                this.areColumnsReady = true;
                this.setVisibleColumnIndexes();
            },
            (error) => console.log(error),
            () => {
                this.areColumnsReady = true;
                this.setVisibleColumnIndexes();
            }
        );
    }

    public checkAllTask(input: HTMLInputElement): void {
        this.tasksService.setTaskCollection(input.checked);
    }

    public setColumnsVisibility(selectedColumns: ISelectOption[]): void {
        if (!selectedColumns) return;
        super.setColumnsVisibility(selectedColumns);
        const selectedColumnKeys = this.getSelectedColumnKeys(selectedColumns);
        if (this.selectedColumnKeys !== selectedColumnKeys) {
            this.selectedColumnKeys = selectedColumnKeys;
            this.setVisibleColumnIndexes();
        }
    }

    private getSelectedColumnKeys(selectedColumns: ISelectOption[]): string {
        if (!selectedColumns) return;
        return JSON.stringify(selectedColumns.map((column) => column.id));
    }

    private setUserSelectedColumns(): void {
        this.selectedColumnKeys = this._authService.currentUser.getUserSettingValue(
            'tasks',
            'visibleColumns'
        );
    }

    private setVisibleColumnIndexes(): void {
        this.visibleColumnIndexes = this.columns
            .filter((x) => x.isVisible)
            .map((column) => this.columns.indexOf(column));
    }

    handleRefresh() {
        this.listService.onPageChange();
    }

    expiredCollecting() {
        this.listService.resetList();
        const filter = [
            {
                filterOperator: 0,
                logicalOperator: 0,
                append: false,
                column: 'expiredCollecting',
                value: 1,
            },
        ] as unknown as FilterEvent[];
        this.listService.onColumnFilter(filter);
    }

    expiredDelivering() {
        this.listService.resetList();
        const filter = [
            {
                filterOperator: 0,
                logicalOperator: 0,
                append: false,
                column: 'expiredDelivering',
                value: 1,
            },
        ] as unknown as FilterEvent[];
        this.listService.onColumnFilter(filter);
    }

    ngOnDestroy() {
        this.tasksService._filterOptions.next(null);
        this.tasksService.getFilterOptions();
    }
}
