import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    EventEmitter,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { skip, takeUntil } from 'rxjs/operators';
import { ModalService } from './modal.service';
import { ColorClass } from '../../types/color-class';

@Component({
    selector: 'lib-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit, OnDestroy {
    private _destroy$: ReplaySubject<boolean>;
    @Input() public title: string;
    @Input() public componentID = 'main';
    @Input() public bgColorClass = ColorClass.Danger;
    @Input() public colorClass = ColorClass.White;
    @Input() public hasCloseIcon = true;
    @Output() public onClose = new EventEmitter<void>();

    @ViewChild('modal', { static: false })
    private _modal: TemplateRef<any>;

    constructor(protected _ngbModal: NgbModal, public modalService: ModalService) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    public setComponentID(componentID: string) {
        this.componentID = componentID;
    }

    ngOnInit(): void {
        this.modalService.isModalVisible
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isVisible) => {
                if (this.componentID !== this.modalService.componentID) {
                    return;
                }
                if (isVisible.valueOf()) {
                    this._ngbModal.open(this._modal, {
                        windowClass: 'modal-holder',
                        centered: true,
                        size: 'sm',
                    });
                } else {
                    this._ngbModal.dismissAll();
                    this.onClose.emit();
                }
            });
    }

    public closeModal(): void {
        this.modalService.setModalVisibility(false, this.componentID);
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
    }
}
