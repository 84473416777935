<div class="permission-container text-center">
    <img src="/assets/images/Logo_slogen.svg" alt="logo" class="logo" />
    <br />
    <img src="/assets/images/map.svg" alt="map" class="map" />
    <h4 class="text-white my-2">{{ 'common.app-use-your-position' | translate }}</h4>
    <p class="text-white px-2 mt-4">
        {{ 'common.app-use-your-position-description01' | translate }}.
    </p>
    <p class="text-white px-2 mt-4">
        {{ 'common.app-use-your-position-description02' | translate }}
    </p>
    <div class="d-flex justify-content-between px-2 w-100 position-absolute">
        <button (click)="handleBack()">
            {{ 'common.app-use-your-position-decline' | translate }}
        </button>
        <span></span>
        <button (click)="handleAccept()">
            {{ 'common.app-use-your-position-accept' | translate }}
        </button>
    </div>
</div>
