import { Address } from './address';

export class CustomerContactData {
    public orderID: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public deliveryAddress: Address;
}
