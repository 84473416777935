import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierControlComponent } from './supplier-control.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSelectModule } from 'ngx-select-ex';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [SupplierControlComponent],
    imports: [CommonModule, TranslateModule, NgxSelectModule, FormsModule],
})
export class SupplierControlModule {}
