import { ISelectOption } from '../types/i-select-option';
import { StringHelper } from './string.helper';

export class EnumHelper {
    public static enumToOptions<TEnum>(
        enumObj: TEnum,
        toHumanCase = true
    ): ISelectOption[] {
        const options = Object.keys(enumObj)
            .filter((key) => isNaN(Number(key)))
            .map((key) => {
                return {
                    id: enumObj[key],
                    label: toHumanCase ? StringHelper.convertToHumanCase(key) : key,
                };
            });
        return options;
    }
}
