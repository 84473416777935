import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAPIClientService } from './iapi-client.service';
import { Injectable } from '@angular/core';
import { AppSettings } from '../../appsettings';

@Injectable()
export class APIClientService implements IAPIClientService {
    constructor(private _httpClient: HttpClient) {}

    public get<T>(endpoint: string, options?: object): Observable<T> {
        options = { ...options, withCredentials: true };
        return this._httpClient.get<T>(this.getAPIUrl(endpoint), options);
    }

    public getWithResponse<T>(
        endpoint: string,
        headers?: HttpHeaders,
        params?: HttpParams
    ): Observable<HttpResponse<T>> {
        return this._httpClient.get<T>(this.getAPIUrl(endpoint), {
            observe: 'response',
            withCredentials: true,
            headers,
            params,
        });
    }

    public getBlobWithResponse(endpoint: string): Observable<HttpResponse<Blob>> {
        return this._httpClient.get(this.getAPIUrl(endpoint), {
            observe: 'response',
            responseType: 'blob',
            withCredentials: true,
        });
    }

    public postWithResponse<T>(
        endpoint: string,
        requestBody: object | string,
        headers?: HttpHeaders
    ): Observable<HttpResponse<T>> {
        return this._httpClient.post<T>(this.getAPIUrl(endpoint), requestBody, {
            observe: 'response',
            withCredentials: true,
            headers,
        });
    }

    public putWithResponse<T>(
        endpoint: string,
        requestBody: object | string,
        headers?: HttpHeaders
    ): Observable<HttpResponse<T>> {
        return this._httpClient.put<T>(this.getAPIUrl(endpoint), requestBody, {
            observe: 'response',
            withCredentials: true,
            headers,
        });
    }

    public deleteWithResponse<T>(
        endpoint: string,
        headers?: HttpHeaders
    ): Observable<HttpResponse<T>> {
        return this._httpClient.delete<T>(this.getAPIUrl(endpoint), {
            observe: 'response',
            withCredentials: true,
            headers,
        });
    }

    public patchWithResponse<T>(
        endpoint: string,
        requestBody: object | string,
        headers?: HttpHeaders
    ): Observable<HttpResponse<T>> {
        return this._httpClient.patch<T>(this.getAPIUrl(endpoint), requestBody, {
            observe: 'response',
            withCredentials: true,
            headers,
        });
    }

    private getAPIUrl(endpoint: string): string {
        return `${AppSettings.get('backendBaseURL')}/${endpoint}`;
    }
}
