<div class="fullheight xc">
    <div class="container-login">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <h1>{{ 'login.provider-login' | translate }}</h1>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-list>
                            <ion-item>
                                <ion-icon name="person" class="icon-login"></ion-icon>
                                <ion-input
                                    formControlName="email"
                                    type="text"
                                    autofocus
                                    required
                                    [placeholder]="'common.email' | translate"
                                    id="new-input"
                                    name="new-input"
                                    autocomplete="new-input"
                                >
                                </ion-input>
                            </ion-item>

                            <span
                                *ngIf="isSubmitted && fields.email.errors"
                                class="field-error ion-padding"
                            >
                                <span *ngIf="fields.email.errors.required">
                                    {{ 'userform-common.email-is-required' | translate }}
                                </span>
                            </span>

                            <br />
                            <ion-item>
                                <ion-icon
                                    name="lock-closed-outline"
                                    class="icon-login"
                                ></ion-icon>

                                <ion-input
                                    name="password"
                                    type="password"
                                    autocomplete="{{ passwordName }}"
                                    formControlName="password"
                                    required
                                    [placeholder]="'common.password' | translate"
                                    id="{{ passwordName }}"
                                    [name]="passwordName"
                                ></ion-input>
                            </ion-item>

                            <span
                                *ngIf="isSubmitted && fields.password.errors"
                                class="field-error ion-padding"
                            >
                                <span *ngIf="fields.password.errors.required">
                                    {{ 'userform-common.enter-password' | translate }}
                                </span>
                            </span>
                        </ion-list>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-button
                            type="submit"
                            expand="block"
                            [disabled]="loading | async"
                        >
                            {{ 'common.login' | translate }}
                        </ion-button>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-label>
                            <a
                                [routerLink]="['/login/forgotpassword']"
                                class="forgot-password"
                            >
                                {{ 'login.forgot-password' | translate }}
                            </a>
                        </ion-label>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </form>
    </div>
</div>
