import { Component, OnInit } from '@angular/core';
import { InstantDeliveryWorktime } from '../../../models/instant-delivery-worktime';
import { IInstantDeliveryOption } from '../../../types/i-instant-delivery-option';
import { InstantDeliveryService } from './instant-delivery.service';
import { InstantDeliveryArea } from '../../../models/instant-delivery-area';

@Component({
    selector: 'app-instant-delivery',
    templateUrl: './instant-delivery.component.html',
    styleUrls: ['./instant-delivery.component.scss'],
})
export class InstantDeliveryComponent implements OnInit {
    worktime: InstantDeliveryWorktime;
    areas: string[];
    allAreas: InstantDeliveryArea[];

    constructor(private instantDeliveryService: InstantDeliveryService) {}

    selectOptions: IInstantDeliveryOption[] = [
        { id: '1', label: '00:00', hours: 0 },
        { id: '2', label: '01:00', hours: 1 },
        { id: '3', label: '02:00', hours: 2 },
        { id: '4', label: '03:00', hours: 3 },
        { id: '5', label: '04:00', hours: 4 },
        { id: '6', label: '05:00', hours: 5 },
        { id: '7', label: '06:00', hours: 6 },
        { id: '8', label: '07:00', hours: 7 },
        { id: '9', label: '08:00', hours: 8 },
        { id: '10', label: '09:00', hours: 9 },
        { id: '11', label: '10:00', hours: 10 },
        { id: '12', label: '11:00', hours: 11 },
        { id: '13', label: '12:00', hours: 12 },
        { id: '14', label: '13:00', hours: 13 },
        { id: '15', label: '14:00', hours: 14 },
        { id: '16', label: '15:00', hours: 15 },
        { id: '17', label: '16:00', hours: 16 },
        { id: '18', label: '17:00', hours: 17 },
        { id: '19', label: '18:00', hours: 18 },
        { id: '20', label: '19:00', hours: 19 },
        { id: '21', label: '20:00', hours: 20 },
        { id: '22', label: '21:00', hours: 21 },
        { id: '23', label: '22:00', hours: 22 },
        { id: '24', label: '23:00', hours: 23 },
    ];

    worktimeEndSelectOptions: IInstantDeliveryOption[];

    selectedFrom: number;
    selectedTo: number;
    selectedArea: string;

    ngOnInit(): void {
        this.instantDeliveryService.getCurrentUsersInstantWorktime();
        this.instantDeliveryService.getInstantDeliveryAreas();
        this.instantDeliveryService.getAllAreas();
        this.instantDeliveryService.instantSupplierWorktime.subscribe((worktime) => {
            this.worktime = worktime;
        });
        this.instantDeliveryService.instantDeliveryAreas.subscribe((areas) => {
            this.areas = areas;
        });
        this.instantDeliveryService.allInstantDeliveryAreas.subscribe((areas) => {
            this.allAreas = areas;
        });
        this.worktimeEndSelectOptions = this.selectOptions;
    }

    selectFrom(selected: string): void {
        let selectedOption = this.selectOptions.find((option) => option.id === selected);
        this.selectedFrom = selectedOption.hours;

        let currentTime = new Date();
        this.worktimeEndSelectOptions = this.selectOptions.filter(
            (i) =>
                Number(i.hours) > Number(this.selectedFrom) &&
                Number(i.hours) > currentTime.getHours()
        );
    }

    changeStatus(areaid: string) {
        this.instantDeliveryService.changeInstantDeliveryAreaStatus(areaid);
    }

    selectTo(selected: string): void {
        let selectedOption = this.selectOptions.find((option) => option.id === selected);
        this.selectedTo = selectedOption.hours;
    }

    selectArea(selected: string): void {
        this.selectedArea = selected;
    }

    submitWorktime(): void {
        if (this.selectedFrom != null && this.selectedTo != null && this.selectedArea) {
            let fromDate = new Date();
            fromDate.setMinutes(0);
            fromDate.setSeconds(0);
            fromDate.setMilliseconds(0);
            fromDate.setHours(this.selectedFrom);

            let toDate = new Date();
            toDate.setMinutes(0);
            toDate.setSeconds(0);
            toDate.setMilliseconds(0);
            toDate.setHours(this.selectedTo);

            this.instantDeliveryService.submitWorktime(
                fromDate,
                toDate,
                this.selectedArea
            );
        }
    }

    cancelWorktime(): void {
        this.instantDeliveryService.cancelWorktime();
    }
}
