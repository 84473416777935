import { Component, Input, OnInit } from '@angular/core';
import { OrderItem } from 'shared';
import { OrderReturnItemState } from 'shared';
import { OrderItemListService } from '../order-item-list.service';
import { OrderReturnControlService } from 'shared';
import { ColorClass } from 'shared';
import {
    FlashMessageService,
    OrderItemDepositProduct,
    OrderReplacementItemDepositProduct,
} from 'shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: '[order-return-control]',
    templateUrl: './order-return-control.component.html',
    styleUrls: ['./order-return-control.component.scss'],
})
export class OrderReturnControlComponent implements OnInit {
    @Input() orderItem: OrderItem;
    @Input() orderItemDepositProduct: OrderItemDepositProduct = null;
    @Input() orderReplacementItemDepositProduct: OrderReplacementItemDepositProduct =
        null;
    @Input() isReturnInProgress = false;
    public orderItemList: OrderItem[];
    public maxRefundableQunatity: number;
    public returningQuantity: number;
    public OrderReturnItemState = OrderReturnItemState;
    public ColorClass = ColorClass;

    constructor(
        public orderItemListService: OrderItemListService,
        public orderReturnControlService: OrderReturnControlService,
        private _flashMessageService: FlashMessageService,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.maxRefundableQunatity =
            this.orderReturnControlService.getMaxRefundableQunatity(
                this.orderItem,
                this.orderItemDepositProduct,
                this.orderReplacementItemDepositProduct
            );
        this.orderItemListService.orderItems.subscribe((orderItemList) => {
            this.orderItemList = orderItemList;
        });
    }

    public setOrderReturnItemList(
        orderItemID,
        orderItemDepositProductID,
        orderReplacementItemDepositProductID,
        eventTargetValue
    ): void {
        this.returningQuantity = eventTargetValue;

        try {
            this.orderReturnControlService.setOrderReturnItemList(
                this.orderItemList,
                orderItemID,
                eventTargetValue,
                orderItemDepositProductID,
                orderReplacementItemDepositProductID
            );
        } catch (error) {
            console.log(error);
            if (error instanceof RangeError) {
                this._flashMessageService.showMessage(
                    'common.error',
                    error.message,
                    5000,
                    ColorClass.Danger,
                    'order-details-modal'
                );

                if (
                    this._translateService.instant(error.message) ===
                    this._translateService.instant(
                        'order-return.is-bulk-violation-message'
                    )
                ) {
                    this.returningQuantity = 0;
                } else {
                    this.returningQuantity = this.maxRefundableQunatity;
                }

                this.returningQuantity = this.maxRefundableQunatity;
                this.orderReturnControlService.setOrderReturnItemList(
                    this.orderItemList,
                    orderItemID,
                    this.returningQuantity,
                    orderItemDepositProductID,
                    orderReplacementItemDepositProductID
                );
            }
        }
    }
}
