import { Component, Inject, OnInit } from '@angular/core';
import { DateRange } from 'shared';
import {
    ISupplierResourcesService,
    SUPPLIER_RESOURCES_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isupplier-resources-service';
import { SupplierWorktimeSettings } from 'dm-src/dtos/supplier-worktime-settings';
import moment from 'moment';
import { environment } from 'dm-src/environments/environment';
import { SupplierResourcesService } from 'dm-src/app/modules/supplier-resources/supplier-resources.service';

@Component({
    selector: 'app-worktime-settings',
    templateUrl: './worktime-settings.component.html',
    styleUrls: ['./worktime-settings.component.scss'],
})
export class WorktimeSettingsComponent implements OnInit {
    public initialDateRange: Date[];
    public initialValue: string;
    public suppierWorktimeSettings: SupplierWorktimeSettings;

    constructor(
        @Inject(SUPPLIER_RESOURCES_SERVICE_IMPL)
        private _supplierResourcesAPIService: ISupplierResourcesService,
        private _supplierResourcesService: SupplierResourcesService
    ) {
        this.suppierWorktimeSettings = new SupplierWorktimeSettings();
        this.initialDateRange = [];
    }

    ngOnInit(): void {
        this._supplierResourcesAPIService.getWorktimeSettings().subscribe((response) => {
            if (response.status === 200) {
                this.suppierWorktimeSettings = response.body;
                const startDate = new Date(response.body.worktimeRegistrationStart);
                const endDate = new Date(response.body.worktimeRegistrationEnd);
                this.initialDateRange.push(startDate);
                this.initialDateRange.push(endDate);
                this.initialValue =
                    moment(startDate).format(environment.worktimeRegistrationDateFormat) +
                    ' - ' +
                    moment(endDate).format(environment.worktimeRegistrationDateFormat);
            }
        });
    }

    public onRegistrationButtonClicked(isEnabled: boolean) {
        this.suppierWorktimeSettings.isWorktimeRegistrationEnabled = isEnabled;
        this.setWorktimeSettings();
    }

    public onDateRangeSelected(dates: DateRange) {
        if (
            dates.dateFrom === null ||
            dates.dateFrom === undefined ||
            dates.dateTo === undefined
        ) {
            return;
        }

        dates.dateFrom += ' 00:00:00';
        dates.dateTo += ' 23:59:59';

        this.suppierWorktimeSettings.worktimeRegistrationStart = dates.dateFrom;
        this.suppierWorktimeSettings.worktimeRegistrationEnd = dates.dateTo;

        this.setWorktimeSettings();
    }

    public onSupplierRegionChanged(supplierRegionID: string) {
        this._supplierResourcesService.changeSupplierRegion(supplierRegionID);
    }

    private setWorktimeSettings(): void {
        this._supplierResourcesAPIService
            .setWorktimeSettings(this.suppierWorktimeSettings)
            .subscribe();
    }
}
