import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { TaskFailedModalService } from 'dm-src/app/modules/shared/task-failed-modal/task-failed-modal.service';
import { ModalService, SendBirdChatChannel } from 'shared';
import { environment } from 'dm-src/environments/environment';
import { ColorClass } from 'shared';
import { CollectingStatesService } from './collecting-states.service';
import { CollectingActionsService } from './collecting-actions.service';
import {
    COLLECTING_SERVICE_IMPL,
    ICollectingService,
} from 'dm-src/services/collecting/icollecting.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';

@Component({
    selector: 'app-collecting',
    templateUrl: './collecting.component.html',
    styleUrls: ['./collecting.component.scss'],
})
export class CollectingComponent implements OnInit {
    public orderID: Number;
    public supportPhone: string;
    public ColorClass = ColorClass;
    private destroy$: ReplaySubject<boolean>;
    customerChat = false;
    customerChatInfo?: SendBirdChatChannel;

    constructor(
        private _route: ActivatedRoute,
        public collectingItemsService: CollectingItemsService,
        public actions: CollectingActionsService,
        public states: CollectingStatesService,
        public taskFailedModalService: TaskFailedModalService,
        public modalService: ModalService,
        @Inject(COLLECTING_SERVICE_IMPL)
        private _collectingService: ICollectingService,
        private _IosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {
        this.destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit() {
        let orderID: number;
        let taskID: string;
        this.supportPhone = environment.supportPhone;

        try {
            orderID = parseInt(this._route.snapshot.paramMap.get('{orderId}'));
            taskID = this._route.snapshot.paramMap.get('{taskId}');

            this.collectingItemsService.establishRealTimeConnections(orderID.toString());
        } finally {
            this.collectingItemsService.states.orderID = orderID;
            this.collectingItemsService.getOrderItems(taskID, orderID);
            this.taskFailedModalService.setOrderID(orderID);

            this._collectingService.getOrderItems(taskID, orderID).subscribe((order) => {
                if (order.body?.sendBirdChatChannel?.channelUrl) {
                    this.customerChat = true;
                    this.customerChatInfo = order?.body?.sendBirdChatChannel;
                }
            });
        }
    }

    customerName() {
        var countryCode = location.origin.includes('.at') ? 'at' : 'hu';

        return countryCode === 'at'
            ? this.states.customerFirstName
            : this.states.customerLastName;
    }

    ngOnDestroy() {
        this.collectingItemsService.closeConnection();
    }

    openChat(): void {
        this._IosAndAndroidNatvieFunctionsService.openSendbird(this.customerChatInfo);
    }
}
