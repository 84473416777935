import { Component, Inject, Input } from '@angular/core';
import { UserSetting } from '../../../models/user-setting';
import {
    IUserSettingsService,
    USER_SETTINGS_IMPL,
} from '../../../services/user-settings/iuser-settings.service';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../../../services/authentication/iauthentication.service';

@Component({
    selector: 'lib-set-user-setting-btn',
    templateUrl: './set-user-setting-btn.component.html',
    styleUrls: ['./set-user-setting-btn.component.scss'],
})
export class SetUserSettingBtnComponent {
    @Input() public btnClass = 'btn';
    @Input() public section: string;
    @Input() public key: string;
    @Input() public value: string;

    constructor(
        @Inject(USER_SETTINGS_IMPL)
        private _userSettingsService: IUserSettingsService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {}

    public onClick(): void {
        const userSetting = new UserSetting({
            section: this.section,
            key: this.key,
            value: this.value,
        });
        this._userSettingsService.setUserSettings(userSetting).subscribe((response) => {
            if (response.status === 200) {
                const currentUser = this._authService.currentUser;
                currentUser.setUserSetting(userSetting);
                this._authService.updateCurrentUser(currentUser);
            }
        });
    }
}
