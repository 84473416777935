import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-quick-info-footer',
    templateUrl: './quick-info-footer.component.html',
    styleUrls: ['./quick-info-footer.component.scss'],
})
export class QuickInfoFooterComponent implements OnInit {
    @Input() public data: any;

    constructor() {}

    ngOnInit(): void {}
}
