export class FileHelper {
    public static createImageFromBlobAsync(inputFile: Blob): Promise<any> {
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException('Problem parsing input file.'));
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };

            if (inputFile) {
                temporaryFileReader.readAsDataURL(inputFile);
            }
        });
    }
}
