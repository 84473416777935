import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { IOrdersService, ORDERS_SERVICE_IMPL } from 'shared';
import { OrdersService } from 'shared';
import { AccountsService, ACCOUNTS_SERVICE_IMPL, IAccountsService } from 'shared';

@Component({
    selector: 'app-task.edit',
    templateUrl: './task.edit.component.html',
    styleUrls: ['./task.edit.component.scss'],
    providers: [
        {
            provide: ACCOUNTS_SERVICE_IMPL,
            useClass: AccountsService,
        },
        {
            provide: ORDERS_SERVICE_IMPL,
            useClass: OrdersService,
        },
    ],
})
export class TaskEditComponent implements OnInit {
    public taskForm: UntypedFormGroup;
    public isSubmitted: boolean;
    public isLoading: boolean;
    public errorMessage: string;
    public isCreated: boolean;

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountService: IAccountsService,
        @Inject(ORDERS_SERVICE_IMPL) private _ordersService: IOrdersService,
        private _formBuilder: UntypedFormBuilder,
        private _route: ActivatedRoute,
        private _router: Router
    ) {}

    ngOnInit() {
        this.isCreated = false;
        this.taskForm = this._formBuilder.group({
            userId: ['', Validators.required],
            orderId: ['', Validators.required],
            name: ['', Validators.required],
            note: ['', [Validators.required, Validators.email]],
        });
    }

    public get fields() {
        return this.taskForm.controls;
    }

    onSubmit() {
        this.isSubmitted = true;

        if (this.taskForm.invalid) {
            return;
        }

        this.isLoading = true;

        this._accountService
            .registerUser(this.taskForm.value)
            .pipe(first())
            .subscribe(
                (success) => {
                    this.isCreated = true;
                },
                (error: HttpErrorResponse) => {
                    this.isLoading = false;
                    this.errorMessage = error.error;
                }
            );
    }
}
