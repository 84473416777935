export enum ModuleID {
    // Backend

    BACKEND,
    SHOPSERVICES,
    ADMIN,

    // Client

    ROKSH_DESKTOP,
    ROKSH_MOBILE,
    DM,
    PM,

    // Test

    TEST,

    // None of them

    NONE_OF_THEM,
}
