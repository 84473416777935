import { Component, OnInit } from '@angular/core';
import { Supplier } from 'dm-src/models/supplier';
import { SupplierControlService } from './supplier-control.service';

@Component({
    selector: 'app-supplier-control',
    templateUrl: './supplier-control.component.html',
    styleUrls: ['./supplier-control.component.scss'],
})
export class SupplierControlComponent implements OnInit {
    public supplierList: Supplier[];

    constructor(private _supplierControlService: SupplierControlService) {}

    ngOnInit() {
        this._supplierControlService.GetAllControlDocuments().subscribe((suppliers) => {
            if (suppliers && suppliers.body) {
                this.supplierList = suppliers.body;
            }
        });
    }
}
