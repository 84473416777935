import { Component, Input } from '@angular/core';
import { CollectingTaskListItem } from 'shared';
import { DeliveringTaskListItem } from 'shared';
import { TaskGroupItem } from 'dm-src/types/task-group-item';
import { TaskGroupItemType } from 'dm-src/types/task-group-item-type';
import { ProviderShopDeliveryType } from 'projects/shared/src/lib/types/provider-shop-delivery-type';
import { ignoreElements } from 'rxjs/operators';

@Component({
    selector: 'app-task-group-item',
    templateUrl: './task-group-item.component.html',
    styleUrls: ['./task-group-item.component.scss'],
})
export class TaskGroupItemComponent {
    public TaskGroupItemType = TaskGroupItemType;
    public ProviderShopDeliveryType = ProviderShopDeliveryType;
    @Input() public taskGroupItem: TaskGroupItem;

    constructor() {}

    ngOnInit(): void {}
    public get IsContainer(): boolean {
        if (this.taskGroupItem.taskType === TaskGroupItemType.DeliveringTask) {
            let deliveryTask = this.taskGroupItem.task as DeliveringTaskListItem;
            if (
                deliveryTask.deliveryType ===
                ProviderShopDeliveryType.SeparatedPickingAndDelivery
            ) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    public get IsExtraDelivery(): boolean {
        if (this.taskGroupItem.taskType === TaskGroupItemType.DeliveringTask) {
            let deliveryTask = this.taskGroupItem.task as DeliveringTaskListItem;
            return deliveryTask.isExtraDelivery;
        }
        return false;
    }

    public isCollectingTask(
        candidate: CollectingTaskListItem | DeliveringTaskListItem
    ): boolean {
        return candidate instanceof CollectingTaskListItem;
    }
}
