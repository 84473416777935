import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

export const API_CLIENT_SERVICE_IMPL = new InjectionToken<IAPIClientService>(
    'apiClientServiceImpl'
);

export interface IAPIClientService {
    get<T>(endpoint: string, options?: object): Observable<T>;
    getWithResponse<T>(
        endpoint: string,
        headers?: HttpHeaders,
        params?: HttpParams
    ): Observable<HttpResponse<T>>;
    getBlobWithResponse(endpoint: string): Observable<HttpResponse<Blob>>;
    postWithResponse<T>(
        endpoint: string,
        requestBody?: object | string | number,
        headers?: HttpHeaders
    ): Observable<HttpResponse<T>>;
    putWithResponse<T>(
        endpoint: string,
        requestBody: object | string | number,
        headers?: HttpHeaders
    ): Observable<HttpResponse<T>>;
    deleteWithResponse<T>(
        endpoint: string,
        headers?: HttpHeaders
    ): Observable<HttpResponse<T>>;
    patchWithResponse<T>(
        endpoint: string,
        requestBody: object | string,
        headers?: HttpHeaders
    ): Observable<HttpResponse<T>>;
}
