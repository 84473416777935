<app-native-take-photo-result *ngIf="isWrapping"></app-native-take-photo-result>
<div class="card mb-3">
    <div class="card-header {{ classes }}" id="{{ name }}-header">
        <h5 class="mb-0 d-block">
            <a
                class="btn btn-link btn-block text-white"
                (click)="isCollapsed = !isCollapsed"
                [attr.aria-expanded]="!isCollapsed"
                data-toggle="collapse"
                attr.data-target="#{{ name }}-body"
                attr.aria-controls="{{ name }}-body"
            >
                <i class="fa {{ icon }}"></i>{{ title }}
                <span class="float-right">{{ (orderItems | async)?.length }} </span>
            </a>
        </h5>
    </div>
    <div id="{{ name }}-body" [collapse]="isCollapsed" [isAnimated]="true">
        <div class="card-body">
            <div class="row">
                <div
                    *ngFor="let orderItem of orderItems | async"
                    app-order-item
                    [orderItem]="orderItem"
                    [orderRemovedItemReasons]="orderRemovedItemReasons | async"
                    class="col-12 col-sm-6 col-md-4"
                ></div>
            </div>
        </div>
    </div>
    <div *ngIf="currentTotal" id="{{ name }}-footer" class="bg-secondary p-2 text-center">
        {{ 'collecting.total' | translate }}:
        <strong>{{ currentTotal | async }} </strong>
        {{ 'common.currency' | translate }}

        <div *ngIf="(sumDeposit | async) && (sumDeposit | async) > 0">
            {{ 'common.sum-deposit-title' | translate }}: +
            <strong>{{ sumDeposit | async }} {{ 'common.currency' | translate }}</strong>
        </div>
    </div>
</div>
