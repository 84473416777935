import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logout',
    template: '<lib-logout></lib-logout>',
})
export class LogoutComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
