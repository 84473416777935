import { Inject, Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITasksService } from './itasks.service';
import { AssignTaskToUser } from '../../dtos/assign-task-to-user';
import { TaskList } from '../../models/task-list';
import { SetDeliveryTaskState } from '../../dtos/set-delivery-task-state';
import { DeliveryTaskState } from '../../types/delivery-task-state';
import { CollectingDoneResponse } from '../../dtos/collecting-done-response';
import { TaskPageQuery } from '../../dtos/task-page-query';
import { TaskListPage } from '../../models/task-list-page';
import { TaskListFilterOptions } from '../../types/task-list-filter-options';
import { AssignDeliveringTask } from '../../dtos/assign-delivering-task';
import { SetDeliveryTaskFailed } from '../../dtos/set-delivery-task-failed';
import { ICreateDeliveryTask } from '../../dtos/i-create-delivery-task';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';
import { LatLng } from '../../types/latlng';
import { SumUpFinalize } from '../../dtos/sum-up-finalize';
import { OrderPaymentHistory } from '../../models/order-payment-history';
import { CashOnDeliveryFinalize } from '../../dtos/cash-on-delivery-finalize';

@Injectable()
export class TasksService implements ITasksService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getTasks(requestParams: TaskPageQuery): Observable<TaskListPage> {
        let httpParams = new HttpParams();
        for (const [key, value] of Object.entries(requestParams)) {
            httpParams = httpParams.append(key, value);
        }

        return this._apiClient.get<TaskListPage>('tasks/', {
            params: httpParams,
        });
    }

    public getReturnTasks(userID: string): Observable<TaskList> {
        return this._apiClient.get<TaskList>('tasks/return-task-list/' + userID);
    }

    public getMyTasks(userId: string): Observable<TaskList> {
        return this._apiClient.get<TaskList>('tasks/' + userId);
    }

    public assignToUser(requestBody: AssignTaskToUser) {
        return this._apiClient.postWithResponse<void>('tasks/assign', requestBody);
    }

    public assignDeliveringTask(
        requestBody: AssignDeliveringTask
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'tasks/assign-delivering-task',
            requestBody
        );
    }

    public unassignTasks(taskIDs: string[]): Observable<HttpResponse<void>> {
        const requestBody = JSON.stringify(taskIDs);
        return this._apiClient.postWithResponse<void>(
            'tasks/unassign',
            requestBody,
            new HttpHeaders({
                'Content-Type': 'application/json',
            })
        );
    }

    public acceptCollectingTask(taskID: string): Observable<HttpResponse<void>> {
        return this.setTaskState(taskID, DeliveryTaskState.ToDo);
    }

    public checkIfTheUserIsTheSame(taskID: string): Observable<boolean> {
        return this._apiClient.get<boolean>(
            `tasks/check-if-the-user-is-the-same/${taskID}`
        );
    }

    public rejectCollectingTask(taskID: string): Observable<HttpResponse<void>> {
        return this.setTaskState(taskID, DeliveryTaskState.NotAccepted);
    }

    public startCollectingTask(taskID: string): Observable<HttpResponse<void>> {
        const requestBody = new SetDeliveryTaskState();
        requestBody.taskID = taskID;

        return this._apiClient.postWithResponse<void>(
            'tasks/collecting/start',
            requestBody
        );
    }

    public failDeliveryTask(
        requestBody: SetDeliveryTaskFailed
    ): Observable<HttpResponse<void>> {
        return this.setTaskState(
            requestBody.taskID,
            DeliveryTaskState.Failed,
            requestBody.reason
        );
    }

    public acceptDeliveringTask(taskID: string): Observable<HttpResponse<void>> {
        return this.setTaskState(taskID, DeliveryTaskState.ToDo);
    }

    public startDeliveringTask(taskID: string): Observable<HttpResponse<void>> {
        const requestBody = new SetDeliveryTaskState();
        requestBody.taskID = taskID;

        return this._apiClient.postWithResponse<void>(
            'tasks/delivering/start',
            requestBody
        );
    }

    public setDeliveringTaskFailed(taskID: string): Observable<HttpResponse<void>> {
        return this.setTaskState(taskID, DeliveryTaskState.Failed);
    }

    public rejectDeliveringTask(taskID: string): Observable<HttpResponse<void>> {
        return this.setTaskState(taskID, DeliveryTaskState.NotAccepted);
    }

    public setDeliveringTaskDone(
        taskID: string,
        orderID: number,
        lat: number,
        long: number
    ): Observable<HttpResponse<CollectingDoneResponse>> {
        const requestBody = new SetDeliveryTaskState();
        requestBody.taskID = taskID;
        requestBody.orderID = orderID;
        requestBody.lat = lat;
        requestBody.long = long;

        return this._apiClient.postWithResponse<CollectingDoneResponse>(
            'tasks/delivering/done',
            requestBody
        );
    }

    public getFilterOptions(): Observable<TaskListFilterOptions> {
        return this._apiClient.get<TaskListFilterOptions>(`tasks/filteroptions`);
    }

    public retryCollectingTask(taskID: string): Observable<HttpResponse<void>> {
        return this.setTaskState(taskID, DeliveryTaskState.InProgress);
    }

    public getNextTasksAuto(
        userId: string,
        supplierPosition: LatLng
    ): Observable<string[]> {
        return this._apiClient.get<string[]>(
            `tasks/get-next-task/${userId}/${supplierPosition.lat}/${supplierPosition.lng}`
        );
    }

    public setTaskState(
        taskID: string,
        taskState: DeliveryTaskState,
        notes: string = null
    ): Observable<HttpResponse<void>> {
        const requestBody = new SetDeliveryTaskState();
        requestBody.taskID = taskID;
        requestBody.newTaskState = taskState;
        requestBody.notes = notes;
        return this._apiClient.postWithResponse<void>('tasks/state/set', requestBody);
    }

    public createCollectingTask(orderID: number): Observable<HttpResponse<void>> {
        const requestBody: ICreateDeliveryTask = {
            orderID,
        };

        return this._apiClient.postWithResponse<void>(
            `tasks/create-collecting-task`,
            requestBody
        );
    }

    public createContainerPlacingTask(orderID: number): Observable<HttpResponse<void>> {
        const requestBody: ICreateDeliveryTask = {
            orderID,
        };

        return this._apiClient.postWithResponse<void>(
            `tasks/create-container-placing-task`,
            requestBody
        );
    }
    createDeliveringTask(orderID: number): Observable<HttpResponse<void>> {
        const requestBody: ICreateDeliveryTask = {
            orderID,
        };

        return this._apiClient.postWithResponse<void>(
            `tasks/create-delivering-task`,
            requestBody
        );
    }

    public isPaidOnDelivery(deliveryTaskID: string): Observable<boolean> {
        return this._apiClient.get<boolean>(
            `payondelivery/is-paid-on-delivery/${deliveryTaskID}`
        );
    }
    public isSuccessfulPayment(deliveryTaskID: string): Observable<boolean> {
        return this._apiClient.get<boolean>(
            `payondelivery/is-successful-payment/${deliveryTaskID}`
        );
    }
    public finalize(finalizeInfo: SumUpFinalize): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            `payondelivery/finalize`,
            finalizeInfo
        );
    }

    public cashOnDeliveryFinalize(
        finalizeInfo: CashOnDeliveryFinalize
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            `payondelivery/cash-on-delivery-finalize`,
            finalizeInfo
        );
    }

    public createOrderPaymentHistory(
        orderPaymentHistory: OrderPaymentHistory
    ): Observable<HttpResponse<OrderPaymentHistory>> {
        return this._apiClient.postWithResponse<OrderPaymentHistory>(
            `payondelivery/create-order-payment-history`,
            orderPaymentHistory
        );
    }

    public getOrderPaymentHistory(
        deliveryTaskID: string
    ): Observable<OrderPaymentHistory> {
        return this._apiClient.get<OrderPaymentHistory>(
            `payondelivery/get-order-payment-history/${deliveryTaskID}`
        );
    }
}
