import { PageQuery } from './page-query';
import { FilterItem } from './filter-item';

export class FilteredPageQuery extends PageQuery {
    public orderBy: string[];
    public orderDescending = true;
    public filters: FilterItem[] = new Array<FilterItem>();
}

export interface FilteredPageQuery {
    getFilter(propertyName: string, createIfNotExists: boolean): FilterItem;
    deleteFilter(propertyName: string);
}

FilteredPageQuery.prototype.getFilter = function (
    propertyName: string,
    createIfNotExists: boolean = true
): FilterItem {
    let f = this.filters.filter((x) => x.propertyName == propertyName)[0];
    if (!f && createIfNotExists) {
        f = new FilterItem();
        f.propertyName = propertyName;
        this.filters.push(f);
    }
    return f;
};

FilteredPageQuery.prototype.deleteFilter = function (propertyName: string) {
    this.filters = this.filters.filter((x) => x.propertyName != propertyName);
};
