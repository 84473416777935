import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { QuantityComponent } from './quantity.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [QuantityComponent],
    exports: [QuantityComponent],
    imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule],
})
export class QuantityModule {}
