export class PriceData {
    public get isInvalid() {
        return this.isExceedingPercentageThreshold && this.isExceedingAmountThreshold;
    }
    public difference: number;
    public thresholdPercentage: number;
    public thresholdAmount: number;
    public isExceedingPercentageThreshold: boolean;
    public isExceedingAmountThreshold: boolean;
}
