import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ACCOUNTS_SERVICE_IMPL, IAccountsService } from 'shared';

@Component({
    selector: 'app-confirm-registration',
    templateUrl: './confirm-registration.component.html',
    styleUrls: ['./confirm-registration.component.scss'],
})
export class ConfirmRegistrationComponent implements OnInit, OnDestroy {
    public errorMessage: string;
    public isConfirmed = false;
    private destroy$: ReplaySubject<boolean>;

    constructor(
        private route: ActivatedRoute,
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountsService: IAccountsService
    ) {
        this.destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit() {
        this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) =>
            this._accountsService
                .confirmRegistration(
                    decodeURIComponent(params['id']),
                    decodeURIComponent(params['token'])
                )
                .subscribe(
                    (response) => (this.isConfirmed = response.status === 200),
                    (error: HttpErrorResponse) => (this.errorMessage = error.error)
                )
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
