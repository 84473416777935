import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sumup-callback-input',
    templateUrl: './sumup-callback-input.component.html',
    styleUrls: ['./sumup-callback-input.component.css'],
})
export class SumupCallbackInputComponent implements OnInit {
    public input = '';

    constructor() {}

    ngOnInit(): void {}
}
