<button
    *ngIf="states.isCheckoutButtonVisible && checkPreferenceSettingsStates"
    (click)="showQRModal()"
    class="btn btn-block btn-success task-action-btn w-75 mx-auto my-3"
    [disabled]="states.isButtonClickForbidden || !states.allowCheckout"
>
    <span
        ><i class="fa fa-check"></i>
        {{ displayButton }}
    </span>
</button>

<div *ngIf="states.existPreferenceSettings">
    <div *ngIf="states.showPhoneButton && !states.customerCalled">
        <p
            class="btn btn-block btn-success task-action-btn w-75 mx-auto my-3"
            (click)="callDone()"
        >
            <a
                href="tel:{{ states.customerPhone }}"
                [style.display]="'none'"
                #wrapperHelper
            ></a>
            <span
                ><i class="fa fa-phone"></i>
                {{ 'collecting.call-customer' | translate }}</span
            >
        </p>
    </div>
    <div>
        <button
            *ngIf="states.onlineConsultation && !states.sendApprovalRequest"
            (click)="approvalRequest()"
            class="btn btn-block btn-success task-action-btn w-75 mx-auto my-3"
            [disabled]="states.isButtonClickForbidden"
        >
            <span
                ><i class="fa fa-reply"></i>
                {{ 'collecting.request-approval-from-the-customer' | translate }}
            </span>
        </button>
    </div>
</div>
