<form class="form-horizontal" [formGroup]="supplierDataForm" (ngSubmit)="onSubmit()">
    <div class="form-group row">
        <div *ngIf="canEditSuppliers" class="col-md-6 col-sm-12">
            <label for="supplier" class="control-label">{{
                'my-account.selected-supplier' | translate
            }}</label>
            <app-supplier-chooser
                id="supplier"
                (onSelect)="onSupplierSelected($event)"
            ></app-supplier-chooser>
        </div>
        <div
            [ngClass]="{
                'col-md-3': canEditSuppliers,
                'col-md-6': !canEditSuppliers,
                'col-sm-12': true
            }"
        >
            <label for="phone" class="control-label">{{
                'common.phone-number' | translate
            }}</label>
            <div class="input-group is-invalid">
                <input
                    type="text"
                    class="form-control"
                    formControlName="phone"
                    id="phone"
                    placeholder="{{ 'common.enter-phone-number' | translate }}"
                />
            </div>
            <div *ngIf="isSubmitted && fields.phone.errors" class="invalid-feedback">
                <div *ngIf="fields.phone.errors.required">
                    {{ 'common.phone-number-required' | translate }}
                </div>
            </div>
        </div>
        <div
            [ngClass]="{
                'col-md-3': canEditSuppliers,
                'col-md-6': !canEditSuppliers,
                'col-sm-12': true
            }"
        >
            <label for="vehicle-type" class="control-label">{{
                'common.vehicle-type' | translate
            }}</label>
            <div class="is-invalid">
                <select
                    size="1"
                    id="vehicle-type"
                    class="form-control"
                    formControlName="vehicleTypeID"
                >
                    <option [ngValue]="null" disabled>
                        {{ 'common.select-vehicle-type' | translate }}
                    </option>
                    <option
                        *ngFor="let vehicleType of vehicleTypes"
                        [ngValue]="vehicleType.id"
                    >
                        {{ vehicleType.label }}
                    </option>
                </select>
            </div>
            <div
                *ngIf="isSubmitted && fields.vehicleTypeID.errors"
                class="invalid-feedback"
            >
                <div *ngIf="fields.vehicleTypeID.errors.required">
                    {{ 'common.vehicle-type-required' | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="canEditSuppliers">
        <div *ngIf="canEditSuppliers; else efficiencySpacer" class="col-md-6 col-sm-12">
            <label for="efficiency" class="control-label">
                {{ 'my-account.efficiency' | translate }}
            </label>
            <div class="input-group is-invalid">
                <input
                    type="number"
                    min="0"
                    max="100"
                    class="form-control"
                    formControlName="efficiency"
                    id="efficiency"
                    placeholder="{{ 'my-account.efficiency' | translate }}"
                />
            </div>
            <div *ngIf="isSubmitted && fields.efficiency.errors" class="invalid-feedback">
                <div *ngIf="fields.efficiency.errors.required">
                    {{ 'my-account.efficiency-required' | translate }}
                </div>
                <div *ngIf="fields.efficiency.errors.min || fields.efficiency.errors.max">
                    {{ 'my-account.efficiency-range' | translate }}
                </div>
            </div>
        </div>

        <ng-template #efficiencySpacer>
            <div class="col-md-6 col-sm-12"></div>
        </ng-template>

        <div class="col-md-6 col-sm-12 mt-md-2">
            <label for="card-number" class="control-label">
                {{ 'my-account.card-number' | translate }}
            </label>
            <div class="input-group is-invalid">
                <input
                    type="text"
                    class="form-control"
                    formControlName="cardNumber"
                    id="card-number"
                    placeholder="{{ 'my-account.enter-card-number' | translate }}"
                />
            </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-md-2">
            <label for="supplier-region" class="control-label">
                {{ 'supplier-regions.supplier-region' | translate }}
            </label>
            <div class="is-invalid">
                <app-supplier-region-chooser
                    (onSelect)="setSupplierRegion($event)"
                    [selectedRegionID]="getSelectedRegionID()"
                    id="supplier-region"
                >
                </app-supplier-region-chooser>
            </div>
            <div
                *ngIf="isSubmitted && fields.supplierRegionID.errors"
                class="invalid-feedback"
            >
                <div *ngIf="fields.supplierRegionID.errors.required">
                    {{ 'my-account.supplier-region-required' | translate }}
                </div>
            </div>
        </div>

        <div class="col-md-6 col-sm-12 mt-md-2">
            <label for="supplier-startprovidershop" class="control-label">
                {{ 'supplier-hotspot.start-position' | translate }}
            </label>
            <div class="is-invalid">
                <providershop-chooser
                    (onSelect)="setStartProviderShopID($event)"
                    [selectedProviderShopID]="getStartProviderShopID()"
                    id="supplier-startprovidershop"
                >
                </providershop-chooser>
            </div>
            <div
                *ngIf="isSubmitted && fields.startProviderShopID.errors"
                class="invalid-feedback"
            >
                <div *ngIf="fields.startProviderShopID.errors.required">
                    {{ 'supplier-hotspot.start-position-required' | translate }}
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12"></div>
        <!--<div
            *ngIf="canResetSupplierAccounts; else spacer"
            class="col-md-6 col-sm-12 mt-md-2"
        >
            <label for="supplier-region" class="control-label">
                {{ 'my-account.reset-supplier-account' | translate }}
            </label>
            <button
                type="button"
                class="btn btn-block btn-danger"
                (click)="confirmAccountReset()"
            >
                <i class="fa fa-warning"></i>
                {{ 'common.reset' | translate }}
            </button>
        </div>
        <ng-template #spacer>
            <div class="col-md-6 col-sm-12"></div>
        </ng-template>-->
    </div>
    <div class="form-group row">
        <div class="col-md-6 col-sm-12 mt-md-2 p-3">
            <div class="row border m-0 p-0">
                <div class="col-12 px-2 pt-3 m-0">
                    <img
                        src="/assets/images/sumup.svg"
                        width="80"
                        height="auto"
                        alt="icon"
                        class="mb-2"
                    />
                    <div class="input-group is-invalid mb-2">
                        <input
                            type="text"
                            class="form-control"
                            [readOnly]="true"
                            formControlName="sumUpApiUserName"
                            placeholder="{{
                                'my-account.enter-sum-up-api-username' | translate
                            }}"
                        />
                    </div>
                    <div class="input-group is-invalid my-2 d-flex justify-content-end">
                        <button
                            class="btn btn-light border"
                            type="button"
                            (click)="setSumUpModalModalVisibility(true)"
                        >
                            <i class="fa fa-gear"></i
                            >{{ 'my-account.sum-up-btn' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="col-md-6 col-sm-12 mt-md-2 p-3"
            *ngIf="canResetSupplierAccounts; else spacer"
        >
            <div class="row border m-0 p-0">
                <div class="col-12 px-2 pt-3 m-0">
                    <img
                        src="/assets/images/revolut.png"
                        width="80"
                        height="auto"
                        alt="icon"
                        class="mb-2"
                    />
                    <div class="input-group is-invalid mb-2">
                        <input
                            type="text"
                            class="form-control"
                            formControlName="accountID"
                            [readonly]="true"
                            placeholder="{{ 'my-account.enter-account-id' | translate }}"
                        />
                    </div>
                    <div
                        class="input-group is-invalid my-2 d-flex justify-content-between"
                    >
                        <button
                            class="btn btn-light border"
                            type="button"
                            (click)="confirmSetAccountBalance()"
                            [disabled]="!supplierIsExists"
                        >
                            <i class="fa fa-gear"></i
                            >{{ 'my-account.sum-up-btn' | translate }}
                        </button>
                        <button
                            class="btn btn-danger"
                            type="button"
                            (click)="confirmAccountReset()"
                            [disabled]="!supplierIsExists"
                        >
                            <i class="fa fa-warning"></i>{{ 'common.reset' | translate }}
                        </button>
                    </div>
                    <div
                        *ngIf="isSubmitted && fields.accountID.errors"
                        class="invalid-feedback"
                    >
                        <div *ngIf="fields.accountID.errors.required">
                            {{ 'my-account.account-id-required' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-sm-12 mt-md-2 p-3" *ngIf="showApplicationSettings">
            <div class="row border m-0 p-0">
                <div class="col-12 px-2 pt-3 m-0">
                    <p>Show Application Settings</p>
                    <div class="input-group is-invalid my-2 d-flex justify-content-end">
                        <button
                            class="btn btn-light border"
                            type="button"
                            (click)="showSettings()"
                        >
                            <i class="fa fa-gear"></i> Settings
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #spacer>
            <div class="col-md-6 col-sm-12"></div>
        </ng-template>
    </div>
    <div class="form-group row mt-4">
        <div class="col-sm-12 col-md-6">
            <button
                type="submit"
                class="btn btn-primary btn-block login-button"
                [disabled]="isRequestInProgress"
            >
                <i *ngIf="!isRequestInProgress" class="fa fa-save"></i>
                <span *ngIf="!isRequestInProgress">
                    {{ 'common.save' | translate }}
                </span>
                <span
                    *ngIf="isRequestInProgress"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
                <span *ngIf="isRequestInProgress">
                    {{ 'common.working' | translate }}...
                </span>
            </button>
        </div>
    </div>

    <div *ngIf="isThisTesterUser" class="col-md-6 col-sm-12 mt-md-2 p-2">
        <hr />
        <label for="supplier-region" class="control-label">
            <strong> {{ 'users.delete-account' | translate }}</strong>
        </label>
        <button
            type="button"
            class="btn btn-block btn-danger"
            (click)="inactivateMyUser()"
        >
            <i class="fa fa-remove"></i>

            {{ 'common.delete' | translate }}
        </button>
    </div>
</form>
<app-supplier-address-approval-modal
    (onAddressApprove)="storeSupplierData()"
></app-supplier-address-approval-modal>
<lib-modal
    [title]="'my-account.confirm-reset' | translate"
    [componentID]="'confirm-supplier-account-reset'"
>
    <p>{{ 'my-account.confirm-reset-supplier-account' | translate }}</p>
    <div class="btn-toolbar justify-content-between">
        <div class="btn-group btn-group-sm">
            <button
                [disabled]="isAccountResetInProgress"
                (click)="onAccountResetConfirmed()"
                class="btn btn-danger"
            >
                <i class="fa fa-warning"></i>{{ 'common.reset' | translate }}
            </button>
            <button
                (click)="setAccountResetModalVisibility(false)"
                class="btn btn-warning"
            >
                <i class="fa fa-remove"></i>{{ 'common.cancel' | translate }}
            </button>
        </div>
    </div>
</lib-modal>

<lib-modal
    [title]="'my-account.revolut-settings' | translate"
    [componentID]="'supplier-revolut-account'"
>
    <div class="container-fluid">
        <form>
            <div *ngIf="isRevolutSaveError" class="alert alert-danger">
                <strong>{{ revolutErrorText | translate }}</strong>
            </div>
            <label for="revolut-account-id" class="col-sm-3 col-form-label">
                {{ 'my-account.account-id' | translate }}
            </label>
            <div>
                <input
                    type="text"
                    class="form-control"
                    id="revolut-account-id"
                    [(ngModel)]="currentRevolutAccountId"
                    name="revolut-account-id"
                    placeholder="{{ 'my-account.enter-account-id' | translate }}"
                />
            </div>

            <label for="revolut-account-balance" class="col-sm-3 col-form-label">
                {{ 'my-account.balance' | translate }}
            </label>
            <div>
                <div class="input-group">
                    <input
                        type="number"
                        class="form-control"
                        id="revolut-account-balance"
                        name="revolut-account-balance"
                        [(ngModel)]="currentRevolutBalance"
                        min="0"
                        max="100000"
                    />
                    <div class="input-group-append">
                        <span class="input-group-text">{{
                            'common.currency' | translate
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row mt-3 justify-content-between">
                <div class="col-sm-6">
                    <button
                        (click)="setRevolutAccountBalance()"
                        class="btn btn-warning btn-block"
                    >
                        <i class="fa fa-save"></i>{{ 'common.save' | translate }}
                    </button>
                </div>
                <div class="col-sm-6">
                    <button
                        (click)="setAccountResetModalVisibility(false)"
                        class="btn btn-warning btn-block"
                    >
                        <i class="fa fa-remove"></i>{{ 'common.cancel' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</lib-modal>

<lib-modal
    [bgColorClass]="sumUpBGModalColorClass"
    [colorClass]="sumUpModalColorClass"
    title="SumUp fiók beállítások"
    [componentID]="'sumup-modal'"
>
    <form [formGroup]="sumUpSettingDataForm" (ngSubmit)="onSubmitSumupSettingDataForm()">
        <img
            src="/assets/images/sumup.svg"
            width="100"
            height="auto"
            alt="icon"
            class="mb-2"
        />
        <hr />
        <label class="control-label" for="sum-up-api-username">sumup e-mail</label>
        <div class="input-group is-invalid">
            <input
                [readOnly]="true"
                type="text"
                class="form-control"
                formControlName="sumUpApiUserName"
                id="sum-up-api-username"
                placeholder="{{ 'my-account.enter-sum-up-api-username' | translate }}"
            />
        </div>
        <br />
        <label class="control-label" for="sum-up-api-password"
            ><strong class="text-danger">*</strong>
            {{ 'my-account.enter-sum-up-api-password' | translate }}</label
        >
        <div class="input-group is-invalid">
            <input
                type="password"
                class="form-control col-12"
                [required]="true"
                formControlName="sumUpApiPassword"
                id="sum-up-api-password"
                placeholder="{{ 'my-account.enter-sum-up-api-password' | translate }}"
            />
        </div>
        <div
            *ngIf="isSubmittedSumUp && sumUpfields.sumUpApiPassword?.errors"
            class="invalid-feedback"
        >
            <div *ngIf="sumUpfields.sumUpApiPassword.errors?.required">
                {{ 'common.enter-sum-up-api-password-required' | translate }}
            </div>
            <div *ngIf="sumUpfields.sumUpApiPassword.errors?.notvalid">
                {{ 'common.enter-sum-up-api-password-notvalid' | translate }}
            </div>
        </div>
        <br />
        <label class="control-label" for="sum-up-api-password-confirm"
            ><strong class="text-danger">*</strong>
            {{ 'my-account.enter-sum-up-api-password-confirm' | translate }}</label
        >
        <div class="input-group is-invalid">
            <input
                type="password"
                class="form-control col-12"
                formControlName="sumUpApiPasswordConfirm"
                id="sum-up-api-password-confirm"
                [required]="true"
                placeholder="{{
                    'my-account.enter-sum-up-api-password-confirm' | translate
                }}"
            />
        </div>
        <div
            *ngIf="isSubmittedSumUp && sumUpfields.sumUpApiPasswordConfirm?.errors"
            class="invalid-feedback"
        >
            <div *ngIf="sumUpfields.sumUpApiPasswordConfirm.errors?.nomatch">
                {{ 'common.enter-sum-up-api-password-confirm-nomatch' | translate }}
            </div>
        </div>
        <br />
        <div class="input-group is-invalid my-2 d-flex justify-content-end">
            <button
                class="btn btn-light border"
                [formGroup]="sumUpSettingDataForm"
                type="submit"
            >
                {{ 'my-account.sum-up-btn-save' | translate }}
            </button>
        </div>
    </form>
</lib-modal>
