import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeliveryTaskType } from 'shared';

@Injectable({
    providedIn: 'root',
})
export class TaskFailedModalService {
    public orderID: number;
    public taskID: string;
    public failedTaskType: DeliveryTaskType;
    private _isFailModalVisible: BehaviorSubject<boolean>;

    get isFailModalVisible() {
        return this._isFailModalVisible.asObservable();
    }

    constructor() {
        this._isFailModalVisible = new BehaviorSubject<boolean>(false);
    }

    public setFailModalVisible(isVisible: boolean) {
        this._isFailModalVisible.next(isVisible);
    }

    public setOrderID(orderID: number) {
        this.failedTaskType = DeliveryTaskType.Collecting;
        this.orderID = orderID;
    }

    public setTaskID(taskID: string) {
        this.failedTaskType = DeliveryTaskType.Delivering;
        this.taskID = taskID;
    }
}
