import { Inject, Injectable } from '@angular/core';
import { FinancialInformation } from '../../../dtos/financial-information';
import { Observable } from 'rxjs';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from 'shared';

@Injectable({
    providedIn: 'root',
})
export class DailyReviewService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getFinancialInformation(userID: string): Observable<FinancialInformation> {
        return this._apiClient.get<FinancialInformation>(
            `daily-review/get-financial-information/${userID}`
        );
    }
}
