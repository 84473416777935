<li class="list-group-item">
    <div class="container">
        <div class="row">
            <span class="badge badge-secondary" *ngIf="label !== undefined">{{
                label
            }}</span>
        </div>
        <div class="row flex">
            <div class="col-md-3">
                <img [src]="orderReplacementItem.itemThumbnailSrc" />
            </div>
            <div class="col-md-9">
                <span>
                    <div class="row">
                        <div class="col center">
                            <span class="text-small">{{
                                orderReplacementItem.itemName
                            }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>
                                <strong>{{ 'common.sku' | translate }}:</strong>
                                {{ orderReplacementItem.sku || '-' }}
                            </label>
                            <label>
                                <strong>{{ 'common.price' | translate }}:</strong>
                                {{ orderReplacementItem.price | priceround }}
                                {{ 'common.currency' | translate }}
                            </label>
                        </div>
                        <div class="col">
                            <app-order-item-state-view
                                [orderItemState]="orderItemState"
                            ></app-order-item-state-view>
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col align-self-start">
                <p>
                    <strong>{{ 'collecting.quantity' | translate }}</strong>
                    {{ orderReplacementItem.quantity | decimal }}
                </p>
            </div>
            <div class="col align-self-end">
                <p>
                    <strong>{{ 'collecting.collected-quantity' | translate }}</strong>
                    {{ orderReplacementItem.collectedQuantity | decimal }}
                </p>
            </div>
        </div>
    </div>
</li>
