import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'dm-src/environments/environment';
import { MyTasksService } from 'dm-src/app/modules/my-tasks/my-tasks.service';
import {
    ColorClass,
    DeliveringTaskListItem,
    DeliveryTaskState,
    FlashMessageService,
    IProviderShopBoxService,
    PROVIDER_SHOPBOX_SERVICE_IMPL,
    TASKS_SERVICE_IMPL,
    ITasksService,
    SetBoxesStatusDto,
    BoxState,
    PaymentType,
} from 'shared';
import { forkJoin } from 'rxjs';
import { NativeBarcodeReaderService } from 'dm-src/services/native-barcode-reader/native-barcode-reader.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { ProviderShopBoxStatus } from 'projects/shared/src/lib/models/provider-shop-box-status';
import { DeliveringTaskButtonsComponent } from '../delivering-task-item/delivering-task-buttons/delivering-task-buttons.component';

@Component({
    selector: '[delivery-container-placing-task-item]',
    templateUrl: './delivery-container-placing-task-item.component.html',
    styleUrls: ['./delivery-container-placing-task-item.component.scss'],
})
export class DeliveryContainerPlacingTaskItemComponent implements OnInit, OnChanges {
    @Input() task: DeliveringTaskListItem;

    @ViewChild(DeliveringTaskButtonsComponent)
    child: DeliveringTaskButtonsComponent;

    public isCollapsed = true;
    public dateFormat: string;
    public isScannerVisible = false;
    public isFinishedScannning = false;
    public PaymentType = PaymentType;

    public get hasTotalPriceDiscount(): boolean {
        return this.task.hasTotalPriceDiscount;
    }

    public get hasRokshDiscount(): boolean {
        return this.task.hasRokshDiscount;
    }

    public get hasTotalPriceOrRokshDiscount(): boolean {
        return this.task.hasTotalPriceDiscount || this.task.hasRokshDiscount;
    }

    constructor(
        private messageService: FlashMessageService,
        @Inject(TASKS_SERVICE_IMPL) private tasksService: ITasksService,
        @Inject(PROVIDER_SHOPBOX_SERVICE_IMPL)
        private service: IProviderShopBoxService,
        private translateService: TranslateService,
        private myTasksService: MyTasksService,
        private _flashMessageService: FlashMessageService,
        private nativeBarcodeReaderService: NativeBarcodeReaderService,
        private isWrappingService: IsWrappingService,
        private iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {
        this.dateFormat = environment.deliveryDateFormat;
    }

    ngOnInit(): void {
        this.GetIsScannedBoxes();

        if (this.task.state === DeliveryTaskState[DeliveryTaskState.InProgress]) {
            this.isFinishedScannning = true;
        }

        if (window.ReactNativeWebView) {
            console.log('barcode reader subscribed on placing-task');
            this.iosAndAndroidNatvieFunctionsService
                .getCurrentPostMessage()
                .subscribe((response) => {
                    if (response.functionName === 'BARCODE_READING_START') {
                        console.log('barcode resutl on placing-task:', response);
                        this.onQRCodeFound(response?.result);
                    }
                });
        }
    }

    private GetIsScannedBoxes() {
        if (this.task.providerShopBoxList) {
            this.task.providerShopBoxList
                .filter(
                    (b) =>
                        b.boxStatus.statusName === BoxState[BoxState.in_container] ||
                        b.boxStatus.statusName === BoxState[BoxState.empty]
                )
                .forEach((x) => {
                    x['isScanned'] = false;
                });

            this.task.providerShopBoxList
                .filter(
                    (b) =>
                        b.boxStatus.statusName === BoxState[BoxState.under_upload] ||
                        b.boxStatus.statusName === BoxState[BoxState.delivering]
                )
                .forEach((x) => {
                    x['isScanned'] = true;
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.task.providerShopBoxList) {
            this.task.providerShopBoxList.forEach((x) => {
                x['isScanned'] = false;
            });
        }
    }

    public openQRModal(): void {
        if (window.ReactNativeWebView) {
            this.iosAndAndroidNatvieFunctionsService.openBarcodeReader();
        } else if (this.isWrappingService.isWrapping()) {
            this.iosAndAndroidNatvieFunctionsService.openBarcodeReader(
                0,
                this.task.deliveryTaskID
            );
        } else {
            this.isScannerVisible = true;
        }
    }

    public taskStart(): void {
        const dto: SetBoxesStatusDto = {
            orderId: this.task.orderID,
            boxTexts: this.task.providerShopBoxList.map((x) => x.boxText),
            deliveryTaskId: this.task.deliveryTaskID,
        };

        forkJoin([
            this.translateService.get('common.error'),
            this.translateService.get('error.all-boxes-pick-up'),
        ]).subscribe(([title, text]) => {
            var isNotAllScanned = this.task.providerShopBoxList.some(
                (b) => !b['isScanned']
            );

            if (!isNotAllScanned) {
                this.service.SetBoxesStatusDelivering(dto).subscribe(
                    (resp) => {
                        if (resp.status === 200) {
                            this.isFinishedScannning = true;
                            this.tasksService
                                .setTaskState(
                                    this.task.deliveryTaskID,
                                    DeliveryTaskState.InProgress
                                )
                                .subscribe();
                            this.task.state =
                                DeliveryTaskState[DeliveryTaskState.InProgress];
                        }
                    },
                    (_) => this._flashMessageService.showStoredMessage()
                );
            } else {
                this.messageService.showMessage(title, text, 10000, ColorClass.Danger);
            }
        });
    }

    public onQRCodeFound(scannedCode: string): void {
        forkJoin([
            this.translateService.get('common.error'),
            this.translateService.get('error.qr-not-found-in-db'),
        ]).subscribe(([title, text]) => {
            this.service.SetBoxStatusUnderUpload(scannedCode).subscribe(
                (providerShopBox) => {
                    let boxBody = providerShopBox.body;
                    if (boxBody?.boxText !== scannedCode) {
                        this.messageService.showMessage(
                            title,
                            text,
                            10000,
                            ColorClass.Danger
                        );
                    } else {
                        let box = this.task.providerShopBoxList.find(
                            (b) => b.boxText === scannedCode
                        );
                        box.boxStatus.statusName = BoxState[BoxState.under_upload];

                        this.GetIsScannedBoxes();
                    }
                },
                (error) => {
                    if (this.isWrappingService.isWrapping()) {
                        this.translateService
                            .get(error?.error)
                            .subscribe((res: string) => {
                                this.iosAndAndroidNatvieFunctionsService.showDialog(res);
                            });
                    }
                }
            );

            this.isScannerVisible = false;
        });
    }

    public rejectAssignment() {
        const dto: SetBoxesStatusDto = {
            orderId: this.task.orderID,
            boxTexts: this.task.providerShopBoxList.map((x) => x.boxText),
            deliveryTaskId: this.task.deliveryTaskID,
        };

        this.tasksService
            .rejectDeliveringTask(this.task.deliveryTaskID)
            .subscribe((response) => {
                if (response.status === 200) {
                    this.service.SetBoxesStatusEmpty(dto).subscribe((_) => {
                        this.myTasksService.removeTask(this.task.deliveryTaskID);
                        this.myTasksService.getNextTask();
                    });
                }
            });
    }

    handleCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
        this.child?.handleCollapse(this.isCollapsed);

        if (this.isWrappingService.isWrapping()) {
            this.nativeBarcodeReaderService.getBarcode().subscribe((response) => {
                if (response?.identifier === this.task.deliveryTaskID) {
                    this.onQRCodeFound(response.barcode);
                }
            });
        } else if (window.ReactNativeWebView) {
            this.iosAndAndroidNatvieFunctionsService.openBarcodeReader();
        }
    }
}
