<ng-template #billApprovalModal let-modal>
    <div class="modal-header modal-fullscreen">
        <h4 class="modal-title" id="modal-basic-title">Validate Total Price</h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ngb-alert
            [type]="ColorClass.Danger"
            *ngIf="calculatedPrice.isInvalid; else validator"
        >
            <div>
                A vásárolt termékek árának végösszege
                {{ calculatedPrice.difference }} forinttal meghaladja a maximális
                összeghatárt, valamint a maximális összeghatár
                {{ calculatedPrice.thresholdPercentage }}%-át.
            </div>
            <div>
                <a
                    class="btn btn-warning"
                    href="tel:{{ supportPhone }}"
                    (click)="closeModal()"
                >
                    <i class="fa fa-phone"></i>Call Support
                </a>
            </div>
        </ngb-alert>
        <ng-template #validator>
            <p>
                Please read <strong>total price</strong> from the bill and type it to the
                input field.
            </p>
            <form [formGroup]="billApprovalForm">
                <div class="form-group">
                    <label for="total-price">Total price</label>
                    <input
                        type="number"
                        step="1"
                        id="total-price"
                        formControlName="totalPrice"
                        class="form-control"
                        placeholder="Total Price"
                        required
                        autofocus
                        [ngClass]="{
                            'is-invalid': isSubmitted && fields.totalPrice.errors
                        }"
                    />
                    <div
                        *ngIf="isSubmitted && fields.totalPrice.errors"
                        class="invalid-feedback"
                    >
                        <div *ngIf="fields.totalPrice.errors.required">
                            Végösszeg megadása kötelező
                        </div>
                        <div *ngIf="fields.totalPrice.errors.totalPrice">
                            <div *ngIf="validatedPrice.isInvalid">
                                A megadott végösszeg
                                {{ validatedPrice.difference }} forinttal és több mint
                                {{ validatedPrice.thresholdPercentage }}%-kal meghaladja a
                                vásárlásnál megadott termékek árának összegét!
                            </div>
                            <strong> Kérjük ellenőrizd az összeget! </strong>
                        </div>
                    </div>
                </div>
            </form>
        </ng-template>
    </div>
    <div class="modal-footer" *ngIf="!calculatedPrice.isInvalid">
        <button
            type="button"
            (click)="onSubmit()"
            class="btn btn-primary"
            [disabled]="isRequestInProgress"
        >
            <span *ngIf="!isRequestInProgress">Validate</span>
            <span
                *ngIf="isRequestInProgress"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            ></span>
            <span *ngIf="isRequestInProgress"> Working...</span>
        </button>
    </div>
</ng-template>
