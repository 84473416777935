import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarcodeReaderComponent, BarcodeReaderState } from './barcode-reader.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [BarcodeReaderComponent],
    imports: [CommonModule, FormsModule],
    exports: [BarcodeReaderComponent],
})
export class BarcodeReaderModule {}
