<div class="float-left">
    <div class="form-check">
        <input
            class="form-check-input"
            type="radio"
            name="{{ 'booleanValue' + elementID }}"
            id="{{ 'booleanValueTrue' + elementID }}"
            [checked]="value"
            (change)="onFilterChange(true)"
        />
        <label class="form-check-label" for="{{ 'booleanValueTrue' + elementID }}">
            <i class="fa fa-check-circle"></i>
        </label>
    </div>
    <div class="form-check">
        <input
            class="form-check-input"
            type="radio"
            name="{{ 'booleanValue' + elementID }}"
            id="{{ 'booleanValueFalse' + elementID }}"
            [checked]="value !== null && !value"
            (change)="onFilterChange(false)"
        />
        <label class="form-check-label" for="{{ 'booleanValueFalse' + elementID }}">
            <i class="fa fa-ban"></i>
        </label>
    </div>
</div>
<div class="float-right">
    <i class="fa fa-remove" (click)="onFilterClear()"></i>
</div>
