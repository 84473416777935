import { Component, OnInit } from '@angular/core';
import { ListService } from 'shared';
import { ProviderOrdersService } from 'dm-src/app/modules/provider-orders/provider-orders.service';
import { environment } from 'dm-src/environments/environment';
import { ISelectOption } from 'shared';
import { TableColumnFilterType } from 'shared';
import { QueryOperator } from 'shared';
import { ProviderOrder } from 'dm-src/models/provider-order';
import { OrderState } from 'dm-src/types/order-state';
import { LogicalOperator } from 'shared';
import { FormHelper } from 'shared';
import { PaymentState } from 'shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-provider-orders',
    templateUrl: './provider-orders.component.html',
    styleUrls: ['./provider-orders.component.scss'],
})
export class ProviderOrdersComponent implements OnInit {
    public QueryOperator = QueryOperator;
    public LogicalOperator = LogicalOperator;
    public orderStateOptions: ISelectOption[];
    public paymentStateOptions: ISelectOption[];
    public FilterType = TableColumnFilterType;
    public dateFormat: string;

    constructor(
        public providerOrdersService: ProviderOrdersService,
        public listService: ListService,
        private _translateService: TranslateService
    ) {
        this.orderStateOptions = FormHelper.createSelectOptions(OrderState);
        this.dateFormat = environment.deliveryDateFormat;
        this.paymentStateOptions = this.createPaymentOptions();
    }

    public openOrderDetailsModal(order: ProviderOrder) {
        this.providerOrdersService.selectedOrder = order;
        this.providerOrdersService.setOrderDetailsModalVisibility(true);
    }

    ngOnInit() {
        this.providerOrdersService.initOrdersList();
    }

    private createPaymentOptions(): ISelectOption[] {
        const options = FormHelper.createTranslatedSelectOptions(
            PaymentState,
            this._translateService,
            'payment-state'
        );
        return options.map((option) => {
            option.id = this.createPaymentOptionValue(
                parseInt(option.id) as PaymentState
            );
            return option;
        });
    }

    private createPaymentOptionValue(paymentState: PaymentState): string {
        let optionValue;

        switch (paymentState) {
            case PaymentState.Refunded:
                optionValue = 'REFUND';
                break;
            case PaymentState.Failed:
                optionValue = 'FAIL FAILED';
                break;
            case PaymentState.Expired:
                optionValue = 'EXPIRED TIMEOUT';
                break;
            case PaymentState.Canceled:
                optionValue = 'CANCEL CANCELED';
                break;
            case PaymentState.NotStarted:
                optionValue = 'INPROGRESS INIT INVALID';
                break;
            case PaymentState.Reserved:
                optionValue = 'RESERVED';
                break;
            case PaymentState.Succeeded:
                optionValue = 'SUCCESS FINISHED SUCCEEDED';
                break;
        }

        return optionValue;
    }
}
