import { ISupplierRegionsService } from 'dm-src/services/suppliers/isupplier-regions-service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplierRegionsData } from 'dm-src/models/supplier-regions-data';
import { SupplierRegion } from 'dm-src/models/supplier-region';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService, ISelectOption } from 'shared';

@Injectable()
export class SupplierRegionsService implements ISupplierRegionsService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getSupplierRegionsData(): Observable<HttpResponse<SupplierRegionsData>> {
        return this._apiClient.getWithResponse<SupplierRegionsData>(`supplierRegions`);
    }

    public getRegionFilterOptions(): Observable<HttpResponse<ISelectOption[]>> {
        return this._apiClient.getWithResponse<ISelectOption[]>(
            `supplierRegions/filterOptions`
        );
    }

    public saveSupplierRegionsData(
        supplierRegions: SupplierRegion[]
    ): Observable<HttpResponse<SupplierRegion[]>> {
        return this._apiClient.putWithResponse<SupplierRegion[]>(
            `supplierRegions`,
            supplierRegions
        );
    }

    public deleteSupplierRegion(
        supplierRegionID: string
    ): Observable<HttpResponse<void>> {
        return this._apiClient.deleteWithResponse<void>(
            `supplierRegions/${supplierRegionID}`
        );
    }
}
