<div class="container">
    <app-preloader *ngIf="isLoading"></app-preloader>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">Get delivering task</div>
                <div class="card-body">
                    <form
                        class="form-horizontal"
                        [formGroup]="orderCodeForm"
                        (ngSubmit)="onSubmit()"
                    >
                        <div class="form-group row">
                            <div class="col-sm-6 mb-2">
                                <input
                                    type="text"
                                    class="form-control"
                                    formControlName="orderCode"
                                    id="order-code"
                                    placeholder="Enter Order Code"
                                />
                            </div>
                            <div class="col-sm-6">
                                <button
                                    type="submit"
                                    [disabled]="!orderCodeForm.valid"
                                    class="btn btn-primary btn-block login-button"
                                >
                                    Get delivering task
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
