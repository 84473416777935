export const environment = {
    production: true,
    realtimeServerUrl: 'https://delivery',
    deliveryManagerApiBaseUrl: '',
    deliveryManagerMediaUrl: 'https://delivermanagermedia.blob.core.windows.net/',
    billPhotoFolder: 'billphotos',
    deliveryDateFormat: 'yyyy MMM dd. HH:mm',
    worktimeRegistrationDateFormat: 'YYYY. MM. DD.',
    columnFilterDateFormat: 'YYYY. MMM. DD. - YYYY. MMM. DD.',
    bugsnagApiKey: '9c504abc41e566129a6c652385847f1f',
    supportPhone: '0618088575',
    loggedEnvironments: ['master', 'staging', 'production'],
    validatedTotalPriceThresholdPercentage: 10,
    validatedTotalPriceThresholdAmount: 1000,
    calculatedTotalPriceThresholdPercentage: 200,
    calculatedTotalPriceThresholdAmount: 10000,
    googleTagManagerID: 'GTM-TCPHPJ8',
    googleMapsApiKey: 'AIzaSyBcIFCMxEax202tsFH5wJ-g51JpqNUdYhc',
    supplierWorktimeCalendarOffsetDays: 7,
    supplierWorktimeCalendarVisibleDays: 30,
    backendBaseURL: 'https://localhost:5009/api',
    appVersion: require('../../../../package.json').version,
    SENTRY_AUTH_TOKEN: 'bb2e6bec090b49fc82a671de0ffe32d76bc3e6e441bc433585a43477d4c06c29',
};
