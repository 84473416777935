import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { OrderReturnItem } from '../../models/order-return-item';
import { OrderReturnItemDto } from '../../dtos/order-return-item-dto';
import { StartOrderReturn } from '../../dtos/start-order-return';
export const ORDER_RETURN_SERVICE_IMPL = new InjectionToken<IOrderReturnService>(
    'orderReturnServiceImpl'
);

export interface IOrderReturnService {
    startReturnOrderItems(
        startOrderReturn: StartOrderReturn
    ): Observable<HttpResponse<OrderReturnItem[]>>;

    returnOrderItems(
        orderID: number,
        orderReturnItemDtoList: OrderReturnItemDto[],
        comment: string,
        deliveryTaskID: string
    ): Observable<HttpResponse<OrderReturnItem[]>>;

    refundOrderItems(
        orderID: number,
        deliveryTaskID: string
    ): Observable<HttpResponse<OrderReturnItem[]>>;

    getReturnPhotoUrl(userID: string): Observable<string>;
}
