import jsQR from 'jsqr';

export class QRReader {
    public active: any = false;
    public webcam: any = null;
    public canvas: any = null;
    public target: any = null;
    public ctx: any = null;
    public appOverlay: any;

    public canvas2: any = null;
    public ctx2: any = null;

    public _window: any = window;
    resultClb: Function;
    mediaStream: any;
    //decoder: Worker;

    constructor() {
        var baseurl = '';
        var streaming = false;

        // Init Webcam + Canvas
        this.setPhotoSourceToScan();

        this.setCanvas();
        //this.decoder = new Worker(baseurl + 'decoder.js');

        if (this.isMediaStreamAPISupported()) {
            // Resize webcam according to input
            this.webcam.addEventListener(
                'play',
                (ev) => {
                    if (!streaming) {
                        this.setCanvasProperties();
                        streaming = true;
                    }
                },
                false
            );
        } else {
            this.setCanvasProperties();
        }

        if (this.isMediaStreamAPISupported()) {
            navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {
                    var device = devices.filter((device) => {
                        var deviceLabel = device.label.split(',')[1];
                        if (device.kind == 'videoinput') {
                            return device;
                        }
                    });

                    var constraints;
                    if (device.length > 1) {
                        constraints = {
                            video: {
                                //mandatory: {
                                //  sourceId: device[1].deviceId ? device[1].deviceId : null
                                //}
                            },
                            audio: false,
                        };

                        if (this.isMobile()) {
                            constraints.video.facingMode = 'environment';
                        }
                        this.startCapture(constraints);
                    } else if (device.length) {
                        constraints = {
                            video: {
                                // mandatory: {
                                //   sourceId: device[0].deviceId ? device[0].deviceId : null
                                //}
                            },
                            audio: false,
                        };

                        if (this.isMobile()) {
                            constraints.video.facingMode = 'environment';
                        }

                        this.startCapture(constraints);
                    } else {
                        this.startCapture({ video: true });
                    }
                })
                .catch((error) => {
                    this.showErrorMsg();
                    console.error('Error occurred : ', error);
                });
        }
    }

    isMobile(): boolean {
        /*
        if (this._window._iOS) return true;

        if (this._window.Ionic && this._window.Ionic.platforms) {
            if (this._window.Ionic.platforms.indexOf("android") >= 0
                || this._window.Ionic.platforms.indexOf("mobile") >= 0
                || this._window.Ionic.platforms.indexOf("mobileweb") >= 0

            ) {
                return true;
            }
        }

        return false;
        */
        return true;
    }

    setCanvas() {
        //this.canvas = document.createElement('canvas');
        this.canvas = document.getElementById('canv');
        this.ctx = this.canvas.getContext('2d');

        //this.canvas2 = document.getElementById("canv2");
        //this.ctx2 = this.canvas2.getContext('2d');
    }

    public isMediaStreamAPISupported(): boolean {
        return (
            navigator &&
            navigator.mediaDevices &&
            'enumerateDevices' in navigator.mediaDevices
        );
    }

    setPhotoSourceToScan(forSelectedPhotos: boolean = false) {
        if (!forSelectedPhotos && this.isMediaStreamAPISupported()) {
            this.webcam = document.querySelector('video');
        } else {
            this.webcam = document.querySelector('img');
        }
    }

    setCanvasProperties() {
        this.canvas.width = this.webcam.videoWidth;
        this.canvas.height = this.webcam.videoHeight; //  this._window.innerHeight;

        setTimeout((x) => this.setBlur(), 50);
    }

    setBlur() {
        this.appOverlay = document.querySelector('.app__overlay');
        var rect = this.appOverlay.getBoundingClientRect();

        let blLeft = document.querySelector('.bl_left') as any;
        blLeft.style.width = rect.left + 'px';
        blLeft.style.height = '100%';
        blLeft.style.left = '0px';
        blLeft.style.top = '0px';

        let blRight = document.querySelector('.bl_right') as any;
        blRight.style.left = rect.right + 'px';
        blRight.style.width = rect.left + 'px';
        blRight.style.height = '100%';
        blRight.style.top = '0px';

        let blTop = document.querySelector('.bl_top') as any;
        blTop.style.left = rect.left + 'px';
        blTop.style.top = '0px';
        blTop.style.height = rect.top + 'px';
        blTop.style.width = rect.right - rect.left + 'px';

        let blBottom = document.querySelector('.bl_bottom') as any;
        blBottom.style.left = rect.left + 'px';
        blBottom.style.top = rect.bottom + 'px';
        blBottom.style.height = window.screen.height - rect.bottom + 'px';
        blBottom.style.width = rect.right - rect.left + 'px';
    }

    startCapture(constraints) {
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                this.mediaStream = stream;
                this.webcam.srcObject = stream;
                this.webcam.setAttribute('playsinline', true);
                this.webcam.setAttribute('controls', true);
                setTimeout(() => {
                    document.querySelector('video').removeAttribute('controls');
                });
            })
            .catch((err) => {
                this.showErrorMsg();
            });
    }

    showErrorMsg() {
        this._window.noCameraPermission = true;
        let n: any = document.querySelector('.custom-scanner');
        n.style.display = 'none';
        alert('Unable to access the camera');
    }

    destroy() {
        this.active = false;
        let arr = this.mediaStream.getVideoTracks();
        for (let item of arr) {
            item.stop();
        }
        this.webcam.srcObject = null; //stopstreaming
    }

    /**
     * \brief QRReader Scan Action
     * Call this to start scanning for QR codes.
     *
     * \param A function(scan_result)
     */
    scan(callback: any, forSelectedPhotos: boolean = false) {
        let thisobj = this;

        this.resultClb = callback;

        this.active = true;
        this.setCanvas();

        //this.decoder.onmessage = onDecoderMessage;
        //   debugger;
        //setTimeout(() => {
        this.setPhotoSourceToScan(forSelectedPhotos);
        //});

        // Start QR-decoder

        /*
        var clb = function (event: any) {

            if (event && event.data.length > 0) {
                var qrid = event.data[0][2];
                //thisobj.active = false; -- majd a callback deaktiválja vagy nem 
                callback(qrid);
            }
            setTimeout(x => {
                thisobj.newDecoderFrame(clb)
            }, 0);
        }*/

        requestAnimationFrame(() => {
            this.tick();
        });
        //this.newDecoderFrame(clb);
    }

    tick() {
        if (this.webcam.readyState === this.webcam.HAVE_ENOUGH_DATA && this.active) {
            // let startTime = new Date();

            try {
                this.ctx.drawImage(
                    this.webcam,
                    0,
                    0,
                    this.canvas.width,
                    this.canvas.height
                );

                // set transparency value

                let w = Math.min(this.canvas.width, this.canvas.height) / 2;
                let left = (this.canvas.width - w) / 2;
                let top = (this.canvas.height - w) / 2;

                var imgData = this.ctx.getImageData(left, top, w, w);

                if (imgData.data) {
                    const code = jsQR(imgData.data, imgData.width, imgData.height, {
                        //inversionAttempts: "dontInvert",
                    });

                    if (code) {
                        //if (code.data.length > 0) {
                        //  var qrid = code.data[0][2];
                        //thisobj.active = false; -- majd a callback deaktiválja vagy nem
                        //this.resultClb(qrid);
                        //}
                        //else {
                        this.resultClb(code.data);
                        //}
                    }

                    //this.decoder.postMessage(imgData);
                }
            } catch (e) {
                // Try-Catch to circumvent Firefox Bug #879717
                //if (e.name == 'NS_ERROR_NOT_AVAILABLE') setTimeout(x => this.newDecoderFrame(clb), 0);
            }

            /*
            if (code) {
                drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
                drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
                drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
                drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
                outputMessage.hidden = true;
                outputData.parentElement.hidden = false;
                outputData.innerText = code.data;
            } else {
                outputMessage.hidden = false;
                outputData.parentElement.hidden = true;
            }*/

            // let endTime = new Date();

            // let diff = endTime.getTime() - startTime.getTime();
        }

        setTimeout((x) => {
            requestAnimationFrame(() => {
                this.tick();
            });
        }, 220);
    }
}
