<div *ngIf="photoLoaded" class="bill-thumbnail-container">
    <img [src]="billImgSrc" />
</div>
<div class="file-input" *ngIf="!photoLoaded">
    <input
        *ngIf="!isNewWrapper"
        type="file"
        accept="image/*"
        capture="environment"
        id="photo-input"
        onclick="this.value = null"
        (change)="onPhotoInputChanged($event)"
        [disabled]="disabled"
    />
    <label
        class="photo-btn btn btn-block btn-primary"
        for="photo-input"
        (click)="handleTakeAPhoto()"
    >
        <i class="fa fa-camera"></i>{{ 'collecting.take-a-bill-photo' | translate }}
    </label>
</div>
